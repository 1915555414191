/** @jsxImportSource @emotion/react */
import {
  EuiForm,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiImage,
  EuiSelect,
} from "@elastic/eui";
import {
  formReducer,
  handleFieldChange,
  handleFormReset,
  initFormState,
  setFormDataErrors,
} from "../formUtils/reducer";
import { useEffect, useReducer, useRef, useState } from "react";
import TextField from "../FormFields/TextField";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import InputFileField from "../FormFields/InputFileField";
import ArrayField from "../FormFields/ArrayField";
import FormActions from "../formUtils/FormActions";
import { getStaticServerFilePath, readJSONFile } from "../../../lib/utils";
import { validateFormData } from "../../../lib/fieldsValidator";
import { useCreateApiCall, useUpdateApiCall } from "../../../lib/hooks/api";
import apiClient, { handleApiError } from "../../../lib/apiClient";
import { CIMITERI_KEY } from "../../../lib/hooks/api/cimiteri";
import { useNavigate } from "react-router-dom";

export const DefaultTipologieLoculo = [
  "Fornetto",
  "Tomba",
  "Ossario",
  "Cappella Privata",
  "Cappella Pubblica",
];

export const DefaultTipologieLampada = ["Lampadina"];

const formDefaultState = {
  nome: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Nome",
    name: "nome",
    is_required: true,
    validators: [],
    transforms: ["trim"],
  },
  immagine: {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Cartina",
    name: "immagine",
    is_required: true,
    validators: [],
    transforms: [],
  },
  aree: {
    value: [],
    isInvalid: false,
    errors: [],
    label: "Aree",
    name: "aree",
    is_required: true,
    validators: [],
    transforms: [],
  },
  areeFile: {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Mapping Aree Cartina",
    name: "areeFile",
    is_required: false,
    validators: [],
    transforms: [],
  },
  tipologieLoculi: {
    value: DefaultTipologieLoculo,
    isInvalid: false,
    errors: [],
    label: "Tipologie Loculi",
    name: "tipologieLoculi",
    is_required: true,
    validators: [],
    transforms: [],
  },
  tipologieLampade: {
    value: DefaultTipologieLampada,
    isInvalid: false,
    errors: [],
    label: "Tipologie Lampada",
    name: "tipologieLampade",
    is_required: true,
    validators: [],
    transforms: [],
  },
};

function areeToSelectItems(isEdit, aree) {
  let options = [{ value: "", text: "Carica mapping aree" }];
  if (isEdit) {
    options = aree.map((area) => ({
      value: area.code,
      text: `${area.label}${
        area.coordinate
          ? ` - coordinate: ${area.coordinate.x}, ${area.coordinate.y}`
          : ""
      }`,
    }));
  }
  if (options.length === 0) {
    options = [{ value: "", text: "Carica mapping aree" }];
  }
  return options;
}

function CimiteroForm({ isEdit, cimiteroData }) {
  const { setMessage } = useGlobalProvider();
  const { currentComuneId } = useGlobalProvider();
  const navigate = useNavigate();
  const [formData, formDataDispatcher] = useReducer(
    formReducer(formDefaultState),
    initFormState({
      objData: cimiteroData,
      isEdit: isEdit,
      defaultState: formDefaultState,
    })
  );
  const immagineRef = useRef(null);
  const areeFileRef = useRef(null);
  const [resetFlag, setResetFlag] = useState(formData);
  const [areeLoaded, setAreeLoaded] = useState(
    areeToSelectItems(isEdit, cimiteroData?.aree)
  );
  const [areeToLoad, setAreeToLoad] = useState(
    isEdit ? cimiteroData?.aree : []
  );
  const [selectedAreeToLoad, setSelectedAreeToLoad] = useState(
    areeLoaded[0].value
  );
  const [cartinaPreview, setCartinaPreview] = useState(
    isEdit ? getStaticServerFilePath(cimiteroData.immagine) : null
  );
  const { mutateAsync: createCimitero } = useCreateApiCall(
    apiClient.cimitero.create,
    `${CIMITERI_KEY}-${currentComuneId}`,
    true
  );
  const { mutateAsync: updateCimitero } = useUpdateApiCall(
    apiClient.cimitero.update,
    `${CIMITERI_KEY}-${currentComuneId}`,
    true
  );

  useEffect(() => {
    if (formData.immagine.value) {
      if (typeof formData.immagine.value === "string") {
        setCartinaPreview(getStaticServerFilePath(formData.immagine.value));
      } else {
        setCartinaPreview(URL.createObjectURL(formData.immagine.value));
      }
    } else {
      setCartinaPreview(
        isEdit ? getStaticServerFilePath(cimiteroData.immagine) : null
      );
    }
  }, [formData.immagine.value]);

  useEffect(() => {
    const areeFile = formData.areeFile.value;
    if (areeFile) {
      setAreeToLoadFromFile(areeFile);
    } else {
      if (!isEdit) {
        setAreeLoaded([{ value: "", text: "Carica mapping aree" }]);
      }
      setAreeToLoad(formData.aree.value);
    }
  }, [formData.areeFile.value, formData.aree.value]);

  useEffect(() => {
    setAreeLoaded(areeToSelectItems(isEdit, cimiteroData?.aree));
  }, [resetFlag]);

  const setAreeToLoadFromFile = async (areeFile) => {
    const jsonAree = await readJSONFile(areeFile);
    const areePopulated = formData.aree.value.map((area) => {
      const filtered = jsonAree.aree.filter(
        (a) =>
          a.area.toString().toLowerCase() === area.code.toString().toLowerCase()
      );
      if (filtered.length === 1) {
        return {
          ...area,
          coordinate: {
            x: filtered[0].coordinates[0],
            y: filtered[0].coordinates[1],
          },
        };
      } else {
        return area;
      }
    });
    setAreeToLoad(areePopulated);
    setAreeLoaded(areeToSelectItems(true, areePopulated));
  };

  const handleReset = () => {
    handleFormReset(cimiteroData, isEdit, formDataDispatcher);
    if (immagineRef.current) {
      immagineRef.current.removeFiles();
    }
    if (areeFileRef.current) {
      areeFileRef.current.removeFiles();
    }
    setResetFlag(!resetFlag);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors, dataDto } = validateFormData(
      formData,
      isEdit,
      cimiteroData
    );
    if (!isValid) {
      setFormDataErrors(errors, formDataDispatcher);
      setMessage({
        title: "Errore! Alcuni campi non sono validi",
        color: "danger",
        iconType: "error",
        text: "Correggi i valori inseriti e riprova",
      });
    } else {
      dataDto.aree = areeToLoad;
      if (dataDto.areeFile) {
        delete dataDto.areeFile;
      }
      const dataDtoFormData = new FormData();
      for (const [key, value] of Object.entries(dataDto)) {
        if (
          key === "aree" ||
          key === "tipologieLoculi" ||
          key === "tipologieLampade"
        ) {
          dataDtoFormData.append(key, JSON.stringify(value));
        } else {
          dataDtoFormData.append(key, value);
        }
      }
      if (isEdit) {
        handleEditSubmit(dataDtoFormData, dataDto);
      } else {
        handleNewSubmit(dataDtoFormData, dataDto);
      }
    }
  };

  const handleNewSubmit = async (dataFormData, dataObject) => {
    try {
      await createCimitero([currentComuneId, dataFormData]);
      setMessage({
        title: `Cimitero ${dataObject.nome} creato con successo`,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
      navigate("/");
    } catch (err) {
      handleApiError(
        err,
        setMessage,
        "Errore durante la creazione del cimitero"
      );
    }
  };

  const handleEditSubmit = async (dataFormData, dataObject) => {
    try {
      await updateCimitero([currentComuneId, cimiteroData._id, dataFormData]);
      setMessage({
        title: `Cimitero ${dataObject.nome} modificato con successo`,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
      navigate("/");
    } catch (err) {
      handleApiError(
        err,
        setMessage,
        "Errore durante la modifica del cimitero"
      );
    }
  };

  return (
    <EuiPanel>
      <EuiForm component="form">
        <TextField
          field={formData.nome}
          fullWidth={true}
          handleOnChange={(e) =>
            handleFieldChange(
              "nome",
              e.target.value,
              formDataDispatcher,
              setMessage
            )
          }
        />
        <EuiFormRow fullWidth>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={true}>
              <InputFileField
                field={formData.immagine}
                fullWidth={true}
                handleOnChange={(file) =>
                  handleFieldChange(
                    "immagine",
                    file[0],
                    formDataDispatcher,
                    setMessage
                  )
                }
                accept={"image/*"}
                fieldRef={immagineRef}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {cartinaPreview ? (
                <EuiImage
                  size="m"
                  src={cartinaPreview}
                  alt="Cartina cimitero"
                />
              ) : null}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        <EuiFormRow fullWidth>
          <EuiFlexGroup alignItems="flexStart">
            <EuiFlexItem>
              <ArrayField
                addSpacer={false}
                resetFlag={resetFlag}
                field={formData.aree}
                itemField={"label"}
                fullWidth={true}
                handleOnChange={(items) =>
                  handleFieldChange(
                    "aree",
                    items,
                    formDataDispatcher,
                    setMessage
                  )
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                fullWidth
                label={"Mapping aree"}
                helpText={`Utilizza il campo ${formData.areeFile.label} per caricare le coordinate, le quali verranno aggiunte e legate all'area durante il salvataggio. Il nome dell'area nel file json deve combaciare con il nome dell'area caricata in questo campo.`}
              >
                <EuiSelect
                  options={areeLoaded}
                  value={selectedAreeToLoad}
                  onChange={(e) => setSelectedAreeToLoad(e.target.value)}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <InputFileField
                field={formData.areeFile}
                fullWidth={true}
                helpText={
                  "Carica file json con i mapping delle area sulla cartina"
                }
                handleOnChange={(file) =>
                  handleFieldChange(
                    "areeFile",
                    file[0],
                    formDataDispatcher,
                    setMessage
                  )
                }
                accept={".json"}
                fieldRef={areeFileRef}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        <EuiFormRow fullWidth>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem>
              <ArrayField
                resetFlag={resetFlag}
                field={formData.tipologieLoculi}
                fullWidth={true}
                handleOnChange={(items) =>
                  handleFieldChange(
                    "tipologieLoculi",
                    items,
                    formDataDispatcher,
                    setMessage
                  )
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ArrayField
                resetFlag={resetFlag}
                field={formData.tipologieLampade}
                fullWidth={true}
                handleOnChange={(items) =>
                  handleFieldChange(
                    "tipologieLampade",
                    items,
                    formDataDispatcher,
                    setMessage
                  )
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        <FormActions
          handleSubmit={(e) => handleSubmit(e)}
          handleReset={handleReset}
          showReset={true}
          showRequiredText={true}
        />
      </EuiForm>
    </EuiPanel>
  );
}

export default CimiteroForm;
