/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ErrorBanner from "./ErrorBanner";

function FullPageError({
  title = "Qualcosa è andato storto",
  body = "È stato riscontrato un errore. Prova a ricaricare la pagina, se l'errore persiste contattare l'assistenza",
}) {
  return (
    <div
      css={css`
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <ErrorBanner title={title} body={body} />
    </div>
  );
}

export default FullPageError;
