import moment from "moment";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { formReducer, initFormState } from "../../forms/formUtils/reducer";
import { useMemo, useReducer, useState } from "react";
import useAuth from "../../../lib/providers/authProvider";
import { isSuperAdmin, UserRole, UsersRolesOptions } from "../../../lib/auth";
import { useUsers } from "../../../lib/hooks/api/users";
import validator from "validator/es";
import Toolbar from "../Toolbar";

const DEFAULT_TYPES_VALUE = {
  string: "",
  select: [],
  dateRange: {},
};

function prepareSearchFieldsAndState(superAdmin) {
  let fields = [
    {
      field: "_id",
      label: "Id",
      type: "select",
      isSingleSelect: false,
      entitySearch: "id",
    },
    { field: "username", label: "Username", type: "string" },
    { field: "first_name", label: "Nome", type: "string" },
    { field: "last_name", label: "Cognome", type: "string" },
  ];
  if (superAdmin) {
    fields.push({
      field: "comune",
      label: "Comune",
      type: "select",
      isSingleSelect: false,
      entitySearch: "comune",
    });
  }
  fields = [
    ...fields,
    ...[
      {
        field: "roles",
        label: "Ruolo",
        type: "select",
        isSingleSelect: false,
        options: superAdmin
          ? UsersRolesOptions
          : UsersRolesOptions.filter(
              (role) => role.code !== UserRole.SuperAdmin
            ),
      },
      {
        field: "createdAt",
        label: "Creato il",
        type: "dateRange",
        props: {
          showTimeSelect: false,
          minDate: moment().subtract(10, "y"),
          maxDate: moment(),
        },
      },
      {
        field: "updatedAt",
        label: "Modificato il",
        type: "dateRange",
        props: {
          showTimeSelect: false,
          minDate: moment().subtract(10, "y"),
          maxDate: moment(),
        },
      },
    ],
  ];
  const searchFields = {
    groups: [
      {
        name: "",
        fields,
      },
    ],
  };

  const searchDefaultState = {};

  searchFields.groups.forEach((group) =>
    group.fields.forEach((field) => {
      searchDefaultState[field.field] = {
        value: DEFAULT_TYPES_VALUE[field.type],
        label: field.label,
        name: field.field,
        is_required: false,
      };
    })
  );

  return { searchFields, searchDefaultState };
}

function UsersToolbar({ tableQuery, setTableQuery }) {
  const { user } = useAuth();
  const { currentComuneId } = useGlobalProvider();
  const { searchFields, searchDefaultState } = useMemo(
    () => prepareSearchFieldsAndState(isSuperAdmin(user)),
    []
  );
  const [searchFormData, searchFormDataDispatcher] = useReducer(
    formReducer(searchDefaultState),
    initFormState({
      objData: null,
      isEdit: false,
      defaultState: searchDefaultState,
    })
  );
  const [searchIdQuery, setSearchIdQuery] = useState({});

  const { users, isFetching, isLoading } = useUsers(
    {
      page: 0,
      pageSize: 20,
      query: searchIdQuery,
      sort: {},
    },
    false
  );

  const handleIdSearchChange = (searchValue) => {
    setSearchIdQuery(
      validator.isMongoId(searchValue)
        ? {
            _id: searchValue,
          }
        : {}
    );
  };

  const handleSubmit = (processedQuery) => {
    setTableQuery(processedQuery);
  };

  const handleReset = () => {
    setTableQuery({});
  };

  return (
    <Toolbar
      fieldsColumns={4}
      searchFields={searchFields}
      entitiesName={"Gestori"}
      searchFormData={searchFormData}
      searchFormDataDispatcher={searchFormDataDispatcher}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      isIdLoading={isLoading || isFetching}
      handleIdSearchChange={handleIdSearchChange}
      iDOptions={users.data.map((userData) => ({
        code: userData._id,
        label: userData._id,
      }))}
    />
  );
}

export default UsersToolbar;
