import React, { useEffect, useState } from "react";
import { EuiButton, EuiComboBox, EuiSpacer, EuiText } from "@elastic/eui";
import { css } from "@emotion/react";
import { useComuni } from "../lib/hooks/api/comune";
import useGlobalProvider from "../lib/providers/globalProvider";
import useAuth from "../lib/providers/authProvider";
import { isSuperAdmin } from "../lib/auth";
import { useNavigate } from "react-router-dom";
import useNavigation from "../lib/providers/navigationProvider";

function ComuneSelector() {
  const { user } = useAuth();
  const { comuni, isLoading, isFetching } = useComuni(
    {
      page: 0,
      pageSize: 0,
      query: {},
      sort: { nome: 1 },
    },
    true
  );
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const {
    setCurrentComuneId,
    setGlobalWarning,
    loading,
    resetComune,
    setResetComune,
  } = useGlobalProvider();
  const { isDetailPage } = useNavigation();

  const comuniOptions = comuni.data.map((comune) => ({
    code: comune._id,
    label: comune.nome,
  }));

  useEffect(() => {
    if (!loading && !isLoading && !isFetching) {
      if (comuni.data.length > 0) {
        if (selected.length === 0) {
          handleSetComune([comuniOptions[0]]);
        }
        setGlobalWarning(null);
      } else {
        // No comuni
        let body;
        let actions;
        if (isSuperAdmin(user)) {
          body = (
            <EuiText>
              <p>Prima di procedere è necessario creare un comune.</p>
            </EuiText>
          );
          actions = (
            <EuiButton
              color={"warning"}
              fill
              size="s"
              onClick={() => navigate("/comuni")}
            >
              Inserisci un comune o importali da un csv
            </EuiButton>
          );
        } else {
          body = (
            <EuiText>
              <div>Prima di procedere è necessario creare un comune.</div>
              <EuiSpacer size="s" />
              <div>Chiedi all'admin del gestionale di inserirne</div>
            </EuiText>
          );
        }
        setGlobalWarning({ title: "Nessun comune presente", body, actions });
      }
    }
  }, [comuni, isLoading, isFetching, loading]);

  useEffect(() => {
    if (resetComune && comuni.data.length > 0) {
      handleSetComune([]);
    }
  }, [resetComune]);

  const handleSetComune = (comune) => {
    setSelected(comune);
    if (comune.length > 0) {
      setCurrentComuneId(comune[0].code);
    }
  };

  return (
    <EuiComboBox
      css={css`
        width: 160px;
      `}
      aria-label="Seleziona il comune"
      placeholder="Comune"
      singleSelection={{ asPlainText: true }}
      options={comuniOptions}
      selectedOptions={selected}
      onChange={handleSetComune}
      compressed
      isLoading={isLoading || isFetching}
      isClearable={false}
      isDisabled={isDetailPage}
    />
  );
}

export default ComuneSelector;
