/** @jsxImportSource @emotion/react */
import { EuiOverlayMask, EuiLoadingSpinner } from "@elastic/eui";
import { css } from "@emotion/react";

const LoadingComponent = () => (
  <div>
    <EuiLoadingSpinner size="xxl" />
  </div>
);

function FullPageLoader({ visible, isEmptyPage }) {
  if (visible) {
    return (
      <EuiOverlayMask
        headerZindexLocation="below"
        css={css`
          z-index: 9999 !important;
        `}
      >
        <LoadingComponent />
      </EuiOverlayMask>
    );
  }

  if (isEmptyPage) {
    return (
      <div
        css={css`
          background-color: rgba(0, 0, 0, 0.2);
          width: 100vw;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Inter", BlinkMacSystemFont, Helvetica, Arial, sans-serif;
        `}
      >
        <LoadingComponent />
      </div>
    );
  }

  return null;
}

export default FullPageLoader;
