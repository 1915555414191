import {
  EuiForm,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
} from "@elastic/eui";
import useGlobalProvider from "../../lib/providers/globalProvider";
import { useReducer } from "react";
import {
  formReducer,
  handleFieldChange,
  initFormState,
  setFormDataErrors,
} from "./formUtils/reducer";
import { validateFormData } from "../../lib/fieldsValidator";
import TextField from "./FormFields/TextField";
import FormActions from "./formUtils/FormActions";
import PasswordField from "./FormFields/PasswordField";
import apiClient, { doApiCall } from "../../lib/apiClient";
import useAuth from "../../lib/providers/authProvider";

const formDefaultState = {
  old_password: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Vecchia Password",
    name: "old_password",
    is_required: true,
    validators: [],
    transforms: [],
  },
  new_password: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Nuova Password",
    name: "new_password",
    is_required: true,
    validators: [],
    transforms: [],
  },
  confirm_password: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Conferma Password",
    name: "confirm_password",
    is_required: true,
    validators: [],
    transforms: [],
  },
};

function ChangePasswordForm() {
  const { user } = useAuth();
  const { setMessage, setLoading } = useGlobalProvider();
  const [formData, formDataDispatcher] = useReducer(
    formReducer(formDefaultState),
    initFormState({
      objData: null,
      isEdit: false,
      defaultState: formDefaultState,
    })
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { isValid, errors, dataDto } = validateFormData(
      formData,
      false,
      null
    );
    let equalToOldPassword = false;
    let confirmIsEqual = true;
    if (isValid) {
      if (formData.old_password.value === formData.new_password.value) {
        equalToOldPassword = true;
        errors["old_password"] = [
          "La vecchia password non può essere uguale alla nuova",
        ];
        errors["new_password"] = [
          "La vecchia password non può essere uguale alla nuova",
        ];
      } else {
        if (formData.new_password.value !== formData.confirm_password.value) {
          confirmIsEqual = false;
          errors["new_password"] = ["Le due password non corrispondono"];
          errors["confirm_password"] = ["Le due password non corrispondono"];
        }
      }
    }
    if (!isValid || equalToOldPassword || !confirmIsEqual) {
      setFormDataErrors(errors, formDataDispatcher);
      setMessage({
        title: "Errore! Alcuni campi non sono validi",
        color: "danger",
        iconType: "error",
        text: "Correggi i valori inseriti e riprova",
      });
    } else {
      await doApiCall(
        () =>
          apiClient.users.updatePassword(
            user._id,
            dataDto.old_password,
            dataDto.new_password
          ),
        "Password aggiornata con successo",
        "Errore durante l'aggiornamento della password",
        setLoading,
        setMessage,
        "user"
      );
    }
  };

  return (
    <EuiPanel>
      <EuiForm component="form">
        <PasswordField
          fullWidth={true}
          field={formData.old_password}
          handleOnChange={(e) =>
            handleFieldChange(
              "old_password",
              e.target.value,
              formDataDispatcher,
              setMessage
            )
          }
        />
        <EuiFormRow fullWidth>
          <EuiFlexGroup>
            <EuiFlexItem>
              <PasswordField
                fullWidth={true}
                field={formData.new_password}
                handleOnChange={(e) =>
                  handleFieldChange(
                    "new_password",
                    e.target.value,
                    formDataDispatcher,
                    setMessage
                  )
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <PasswordField
                fullWidth={true}
                field={formData.confirm_password}
                handleOnChange={(e) =>
                  handleFieldChange(
                    "confirm_password",
                    e.target.value,
                    formDataDispatcher,
                    setMessage
                  )
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        <FormActions
          handleSubmit={handleSubmit}
          showReset={false}
          showRequiredText={true}
        />
      </EuiForm>
    </EuiPanel>
  );
}

export default ChangePasswordForm;
