import { EuiFormRow, EuiDatePickerRange, EuiDatePicker } from "@elastic/eui";
import Label from "./Label";
import moment from "moment";
import { useEffect, useState } from "react";
import { dateToIso, isEmpty } from "../../../lib/utils";
import "moment/locale/it";

function DateRangeField({
  field,
  handleChange,
  minDate = moment().subtract(4, "y"),
  maxDate = moment(),
  showTimeSelect = false,
  fullWidth = false,
  locale = "it",
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const middleYear =
    minDate.year() + Math.floor((maxDate.year() - minDate.year()) / 2);

  const isInvalid =
    startDate !== null &&
    endDate !== null &&
    (startDate > endDate || startDate < minDate || endDate > maxDate);

  const handleChangeInternal = (date, isStart) => {
    const dateQuery = {};
    if (startDate) {
      dateQuery["$gte"] = dateToIso(startDate, showTimeSelect);
    }
    if (endDate) {
      dateQuery["$lte"] = dateToIso(endDate, showTimeSelect);
    }
    if (isStart) {
      if (date) {
        dateQuery["$gte"] = dateToIso(date, showTimeSelect);
      }
      setStartDate(date);
    } else {
      dateQuery["$lte"] = dateToIso(date, showTimeSelect);
      setEndDate(date);
    }
    handleChange(dateQuery);
  };

  useEffect(() => {
    if (isEmpty(field.value)) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [field]);

  return (
    <EuiFormRow
      label={<Label field={field} />}
      isInvalid={isInvalid}
      error={isInvalid ? ["Il range fornito è invalido"] : []}
      fullWidth={fullWidth}
    >
      <EuiDatePickerRange
        isInvalid={isInvalid}
        fullWidth={fullWidth}
        startDateControl={
          <EuiDatePicker
            selected={startDate}
            onChange={(date) => handleChangeInternal(date, true)}
            startDate={startDate ? startDate : minDate}
            endDate={endDate ? endDate : maxDate}
            minDate={minDate}
            maxDate={endDate ? endDate : maxDate}
            aria-label="Data inizio"
            placeholder="Data inizio"
            showTimeSelect={showTimeSelect}
            dateFormat={"DD/MM/YYYY"}
            locale={locale}
            yearDropdownItemNumber={middleYear}
          />
        }
        endDateControl={
          <EuiDatePicker
            selected={endDate}
            onChange={(date) => handleChangeInternal(date, false)}
            startDate={startDate}
            endDate={endDate ? endDate : maxDate}
            minDate={startDate ? startDate : minDate}
            maxDate={maxDate}
            aria-label="Data fine"
            placeholder="Data fine"
            showTimeSelect={showTimeSelect}
            dateFormat={"DD/MM/YYYY"}
            locale={locale}
            yearDropdownItemNumber={middleYear}
          />
        }
      />
    </EuiFormRow>
  );
}

export default DateRangeField;
