/** @jsxImportSource @emotion/react */
import InputFileField from "./InputFileField";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
  EuiToolTip,
  EuiText,
  EuiSpacer,
} from "@elastic/eui";
import { downloadFile } from "../../../lib/apiClient";
import { css } from "@emotion/react";

function InputWithControlFileField({
  field,
  handleOnChange,
  fieldRef,
  accept,
  helpText,
  showPopulated,
  setShowPopulated,
  downloadApiCall,
  filename,
  compressed = false,
  fullWidth = false,
  append = null,
  fileExistsLabel = "Gestisci file precedentemente caricato",
}) {
  const handleRemove = () => {
    if (fieldRef.current) {
      fieldRef.current.removeFiles();
    }
    setShowPopulated(false);
    handleOnChange([null]);
  };

  const inputField = (
    <InputFileField
      fieldRef={fieldRef}
      field={field}
      fullWidth={fullWidth}
      handleOnChange={handleOnChange}
      accept={accept}
      helpText={helpText}
      compressed={compressed}
      append={append}
    />
  );
  if (!showPopulated) {
    return inputField;
  }

  return (
    <>
      <EuiFlexGroup alignItems={"flexStart"} justifyContent={"spaceBetween"}>
        <EuiFlexItem grow={true}>
          <EuiText size={"s"}>
            <strong>{fileExistsLabel}</strong>
          </EuiText>
          <EuiSpacer size="xs" />
          <EuiText size="s">{filename}</EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <div>
            <EuiToolTip content="Scarica file">
              <EuiButtonIcon
                iconType="download"
                aria-label="download file"
                css={css`
                  margin-right: 8px;
                `}
                onClick={() =>
                  downloadFile(downloadApiCall, filename, null, "")
                }
              />
            </EuiToolTip>
            <EuiToolTip content="Rimuovi file">
              <EuiButtonIcon
                iconType="cross"
                aria-label="rimuovi file"
                onClick={handleRemove}
              />
            </EuiToolTip>
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export default InputWithControlFileField;
