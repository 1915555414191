import { EuiTabbedContent, EuiSpacer } from "@elastic/eui";
import { Fragment, useEffect } from "react";
import ProfileForm from "../components/forms/Profile.form";
import ChangePasswordForm from "../components/forms/ChangePassword.form";
import useGlobalProvider from "../lib/providers/globalProvider";
import useApp from "../lib/providers/appProvider";

const tabs = [
  {
    id: "profile_tab-user_data",
    name: "Dati Utente",
    content: (
      <Fragment>
        <EuiSpacer size="xl" />
        <ProfileForm />
      </Fragment>
    ),
  },
  {
    id: "profile_tab-change_password",
    name: "Cambia Password",
    content: (
      <Fragment>
        <EuiSpacer size="l" />
        <ChangePasswordForm />
      </Fragment>
    ),
  },
];

function ProfilePage() {
  const { setMessage } = useGlobalProvider();
  const { setManualPageTitle } = useApp();

  useEffect(() => {
    setManualPageTitle("Modifica profilo");
  }, []);

  const handleTabChange = (tab) => {
    setMessage(null);
  };

  return (
    <EuiTabbedContent
      tabs={tabs}
      initialSelectedTab={tabs[0]}
      autoFocus="selected"
      onTabClick={(tab) => handleTabChange(tab)}
    />
  );
}

export default ProfilePage;
