import moment from "moment/moment";
import { useReducer, useState } from "react";
import { formReducer, initFormState } from "../../forms/formUtils/reducer";
import Toolbar from "../Toolbar";
import { countriesCodeLabel } from "../../../lib/data/countries";
import validator from "validator/es";
import { useUtenti } from "../../../lib/hooks/api/utenti";
import useGlobalProvider from "../../../lib/providers/globalProvider";

const searchFields = {
  groups: [
    {
      name: "",
      fields: [
        {
          field: "_id",
          label: "Id",
          type: "select",
          isSingleSelect: false,
          entitySearch: "id",
        },
        { field: "nome", label: "Nome", type: "string" },
        { field: "cognome", label: "Cognome", type: "string" },
        { field: "codiceFiscale", label: "Codice Fiscale", type: "string" },
        { field: "email", label: "Email", type: "string" },
        { field: "telefono", label: "Telefono", type: "string" },
        { field: "luogoNascita", label: "Luogo Nascita", type: "string" },
        {
          field: "statoNascita",
          label: "Stato Nascita",
          type: "select",
          options: countriesCodeLabel,
          isSingleSelect: false,
        },
        { field: "indirizzo", label: "Indirizzo", type: "string" },
        { field: "citta", label: "Città", type: "string" },
        { field: "cap", label: "CAP", type: "string" },
        { field: "provincia", label: "Provincia", type: "string" },
        {
          field: "stato",
          label: "Stato",
          type: "select",
          options: countriesCodeLabel,
          isSingleSelect: false,
        },
        {
          field: "dataNascita",
          label: "Data Nascita",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(120, "y"),
            maxDate: moment().subtract(18, "y"),
          },
        },
        {
          field: "createdAt",
          label: "Creato il",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(10, "y"),
            maxDate: moment(),
          },
        },
        {
          field: "updatedAt",
          label: "Modificato il",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(10, "y"),
            maxDate: moment(),
          },
        },
      ],
    },
  ],
};

const searchDefaultState = {
  _id: {
    value: [],
    label: "Id",
    name: "_id",
    is_required: false,
  },
  nome: {
    value: "",
    label: "Nome",
    name: "nome",
    is_required: false,
  },
  cognome: {
    value: "",
    label: "Cognome",
    name: "cognome",
    is_required: false,
  },
  codiceFiscale: {
    value: "",
    label: "Codice Fiscale",
    name: "codiceFiscale",
    is_required: false,
  },
  email: {
    value: "",
    label: "Email",
    name: "email",
    is_required: false,
  },
  telefono: {
    value: "",
    label: "Telefono",
    name: "telefono",
    is_required: false,
  },
  dataNascita: {
    value: {},
    label: "Data Nascita",
    name: "dataNascita",
    is_required: false,
  },
  luogoNascita: {
    value: "",
    label: "Luogo Nascita",
    name: "luogoNascita",
    is_required: false,
  },
  statoNascita: {
    value: [],
    label: "Stato Nascita",
    name: "statoNascita",
    is_required: false,
  },
  indirizzo: {
    value: "",
    label: "Indirizzo",
    name: "indirizzo",
    is_required: false,
  },
  citta: {
    value: "",
    label: "Città",
    name: "citta",
    is_required: false,
  },
  cap: {
    value: "",
    label: "CAP",
    name: "cap",
    is_required: false,
  },
  provincia: {
    value: "",
    label: "Provincia",
    name: "provincia",
    is_required: false,
  },
  stato: {
    value: [],
    label: "Stato",
    name: "stato",
    is_required: false,
  },
  createdAt: {
    value: {},
    label: "Creato il",
    name: "createdAt",
    is_required: false,
  },
  updatedAt: {
    value: {},
    label: "Modificato il",
    name: "updatedAt",
    is_required: false,
  },
};

function UtentiToolbar({ tableQuery, setTableQuery }) {
  const [searchFormData, searchFormDataDispatcher] = useReducer(
    formReducer(searchDefaultState),
    initFormState({
      objData: null,
      isEdit: false,
      defaultState: searchDefaultState,
    })
  );
  const { currentComuneId } = useGlobalProvider();
  const [searchIdQuery, setSearchIdQuery] = useState({});

  const { utenti, isFetching, isLoading } = useUtenti(
    currentComuneId,
    {
      page: 0,
      pageSize: 20,
      query: searchIdQuery,
      sort: {},
    },
    false
  );

  const handleIdSearchChange = (searchValue) => {
    setSearchIdQuery(
      validator.isMongoId(searchValue)
        ? {
            _id: searchValue,
          }
        : {}
    );
  };

  const handleSubmit = (processedQuery) => {
    setTableQuery(processedQuery);
  };

  const handleReset = () => {
    setTableQuery({});
  };

  return (
    <Toolbar
      fieldsColumns={4}
      searchFields={searchFields}
      entitiesName={"Utenti"}
      searchFormData={searchFormData}
      searchFormDataDispatcher={searchFormDataDispatcher}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      isIdLoading={isLoading || isFetching}
      handleIdSearchChange={handleIdSearchChange}
      iDOptions={utenti.data.map((utente) => ({
        code: utente._id,
        label: utente._id,
      }))}
    />
  );
}

export default UtentiToolbar;
