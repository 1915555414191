import {
  EuiForm,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
} from "@elastic/eui";
import FormActions from "./formUtils/FormActions";
import {
  formReducer,
  handleFieldChange,
  handleFormReset,
  initFormState,
  setFormDataErrors,
} from "./formUtils/reducer";
import TextField from "./FormFields/TextField";
import useGlobalProvider from "../../lib/providers/globalProvider";
import { useReducer } from "react";
import useAuth from "../../lib/providers/authProvider";
import { validateFormData } from "../../lib/fieldsValidator";
import apiClient, { doApiCall } from "../../lib/apiClient";

const profileFormDefaultState = {
  username: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Username",
    name: "username",
    is_required: true,
    validators: [],
    transforms: ["trim", "lowerCase"],
  },
  first_name: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Nome",
    name: "first_name",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },

  last_name: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Cognome",
    name: "last_name",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
};

function ProfileForm() {
  const { user, updateUserData } = useAuth();
  const { setMessage, setLoading } = useGlobalProvider();
  const [formData, formDataDispatcher] = useReducer(
    formReducer(profileFormDefaultState),
    initFormState({
      objData: user,
      isEdit: true,
      defaultState: profileFormDefaultState,
    })
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { isValid, errors, dataDto } = validateFormData(formData, true, user);
    if (!isValid) {
      setFormDataErrors(errors, formDataDispatcher);
      setMessage({
        title: "Errore! Alcuni campi non sono validi",
        color: "danger",
        iconType: "error",
        text: "Correggi i valori inseriti e riprova",
      });
    } else {
      await doApiCall(
        () => apiClient.users.update(user._id, dataDto),
        "Profilo aggiornato con successo",
        "Errore durante l'aggiornamento del profilo",
        setLoading,
        setMessage,
        "user"
      );
      await updateUserData();
    }
  };

  const handleReset = () => {
    handleFormReset(user, true, formDataDispatcher);
  };

  return (
    <EuiPanel>
      <EuiForm component="form">
        <TextField
          fullWidth={true}
          field={formData.username}
          handleOnChange={(e) =>
            handleFieldChange(
              "username",
              e.target.value,
              formDataDispatcher,
              setMessage
            )
          }
        />
        <EuiFormRow fullWidth>
          <EuiFlexGroup>
            <EuiFlexItem>
              <TextField
                fullWidth={true}
                field={formData.first_name}
                handleOnChange={(e) =>
                  handleFieldChange(
                    "first_name",
                    e.target.value,
                    formDataDispatcher,
                    setMessage
                  )
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <TextField
                fullWidth={true}
                field={formData.last_name}
                handleOnChange={(e) =>
                  handleFieldChange(
                    "last_name",
                    e.target.value,
                    formDataDispatcher,
                    setMessage
                  )
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        <FormActions
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          showReset={true}
          showRequiredText={true}
        />
      </EuiForm>
    </EuiPanel>
  );
}

export default ProfileForm;
