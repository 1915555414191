import {
  formReducer,
  handleFieldChange,
  handleFormReset,
  initFormState,
  setFormDataErrors,
} from "./forms/formUtils/reducer";
import { useReducer, useState } from "react";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOutsideClickDetector,
  htmlIdGenerator,
} from "@elastic/eui";
import PasswordField from "./forms/FormFields/PasswordField";
import useGlobalProvider from "../lib/providers/globalProvider";
import { validateFormData } from "../lib/fieldsValidator";
import apiClient, { handleApiError } from "../lib/apiClient";
import ModalMessage from "./ModelMessage";
import { useUpdateApiCall } from "../lib/hooks/api";
import { USERS_KEY } from "../lib/hooks/api/users";

const formDefaultState = {
  new_password: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Nuova Password",
    name: "new_password",
    is_required: true,
    validators: [],
    transforms: [],
  },
  confirm_password: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Conferma Password",
    name: "confirm_password",
    is_required: true,
    validators: [],
    transforms: [],
  },
};

function ResetPasswordModal({ userData, showModal, setShowModal }) {
  const { setMessage, setLoading } = useGlobalProvider();
  const [modalMessage, setModalMessage] = useState(null);
  const [formData, formDataDispatcher] = useReducer(
    formReducer(formDefaultState),
    initFormState({
      objData: null,
      isEdit: false,
      defaultState: formDefaultState,
    })
  );
  const formId = htmlIdGenerator()();
  const { mutateAsync: resetPasswordMutate } = useUpdateApiCall(
    apiClient.users.resetPassword,
    `${USERS_KEY}}`,
    true,
    false
  );

  const closeModal = () => {
    setShowModal(false);
    handleFormReset(null, false, formDataDispatcher);
    setModalMessage(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { isValid, errors, dataDto } = validateFormData(
      formData,
      false,
      null
    );
    let confirmIsEqual = true;
    if (isValid) {
      if (formData.new_password.value !== formData.confirm_password.value) {
        confirmIsEqual = false;
        errors["new_password"] = ["Le due password non corrispondono"];
        errors["confirm_password"] = ["Le due password non corrispondono"];
      }
    }
    if (!isValid || !confirmIsEqual) {
      setFormDataErrors(errors, formDataDispatcher);
      setModalMessage({
        title: "Errore! Alcuni campi non sono validi",
        color: "danger",
        iconType: "error",
        text: "Correggi i valori inseriti e riprova",
      });
    } else {
      try {
        setLoading(true);
        await resetPasswordMutate([userData._id, dataDto.new_password]);
        setMessage({
          title: `Password del gestore ${userData.username} è stata azzerata con successo`,
          iconType: "document",
          color: "success",
          timeout: 8000,
        });
        closeModal();
      } catch (err) {
        handleApiError(
          err,
          setModalMessage,
          "Errore durante il reset della password del gestore"
        );
      } finally {
        setLoading(false);
      }
    }
  };

  if (!showModal || !userData) {
    return null;
  }

  return (
    <EuiOutsideClickDetector onOutsideClick={closeModal}>
      <EuiModal
        onClose={closeModal}
        initialFocus="[name=new_password]"
        style={{ width: 500 }}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            Azzera password gestore {userData.username}
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          {modalMessage ? (
            <ModalMessage setMessage={setModalMessage} message={modalMessage} />
          ) : null}
          <EuiForm id={formId} component="form">
            <PasswordField
              fullWidth={true}
              field={formData.new_password}
              handleOnChange={(e) =>
                handleFieldChange(
                  "new_password",
                  e.target.value,
                  formDataDispatcher,
                  setModalMessage
                )
              }
            />
            <PasswordField
              fullWidth={true}
              field={formData.confirm_password}
              handleOnChange={(e) =>
                handleFieldChange(
                  "confirm_password",
                  e.target.value,
                  formDataDispatcher,
                  setModalMessage
                )
              }
            />
          </EuiForm>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty size="s" onClick={closeModal}>
            Annulla
          </EuiButtonEmpty>
          <EuiButton
            size="s"
            type="submit"
            form={formId}
            fill
            onClick={handleSubmit}
          >
            Azzera
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOutsideClickDetector>
  );
}

export default ResetPasswordModal;
