import React, { createContext, useState, useContext, useEffect } from "react";
import useGlobalProvider from "./globalProvider";
import tokenStorage from "../tokenStorage";
import { useNavigate } from "react-router-dom";
import apiClient, { setAuthToken } from "../apiClient";
import jwt_decode from "jwt-decode";
import { logError } from "../ErrorService/errorLogger";

const initialState = {
  user: null,
};

const AuthContext = createContext({
  user: initialState.user,
});

export const AuthProvider = ({ children }) => {
  const { setFatalError, setLoading, setMessage, setCurrentComuneId } =
    useGlobalProvider();
  const [user, setUser] = useState(initialState.user);
  const [userToken, setUserToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadUserFromToken();
  }, []);

  async function fetchUser() {
    try {
      await fetchUserNoCatch();
    } catch (err) {
      if (err.response) {
        if (err.response.status === 403) {
          setFatalError({
            title: "Accesso non consentito",
            body: "Stai cercando di accedere ad un'area protetta. Effettua il login.",
          });
          setTimeout(() => logout(), 5000);
        }
      }
      logError(err);
    }
  }

  async function fetchUserNoCatch() {
    const { data } = await apiClient.auth.me();
    setUser(data);
    if (data.comune) {
      setCurrentComuneId(data.comune._id);
    }
    return data;
  }

  const loadUserFromToken = async () => {
    setLoading(true);
    let userData = user;
    const token = tokenStorage.get();
    if (token && !user) {
      try {
        setAuthToken(token);
        setTokenTimeout(token);
        userData = await fetchUserNoCatch();
      } catch (err) {
        setUser(null);
        tokenStorage.remove();
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
    return userData;
  };

  const login = async (username, password, redirect) => {
    try {
      setLoading(true);
      const { data } = await apiClient.auth.login(username, password);
      const { access_token } = data;
      setAuthToken(access_token);
      setTokenTimeout(access_token);
      await fetchUserNoCatch();
      tokenStorage.set(access_token);
      setUserToken(access_token);
      navigate(redirect, { replace: true });
      setMessage(null);
    } catch (err) {
      let message = err.message;
      if (err.response) {
        message = err.response.data.message;
      }
      setMessage({
        title: `Errore: Impossible effettuare il login!`,
        iconType: "alert",
        color: "danger",
        text: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const setTokenTimeout = (access_token) => {
    const decoded = jwt_decode(access_token);
    const expires_in = decoded["exp"] - Math.floor(Date.now() / 1000) - 1;
    setTimeout(() => {
      setMessage({
        title: `Sessione scaduta`,
        iconType: "alert",
        color: "warning",
        timeout: 8000,
      });
      logout();
    }, expires_in * 1000); // expires_in is in seconds
  };

  const logout = () => {
    setUser(null);
    tokenStorage.remove();
    navigate("/login");
  };

  const isTokenExpired = () => {};

  const updateUserData = async () => {
    await fetchUser();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        userToken,
        isTokenExpired,
        updateUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
