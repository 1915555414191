import React from "react";
import { logError } from "./errorLogger";
import { EuiCodeBlock, EuiPanel, EuiSpacer, EuiTitle } from "@elastic/eui";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.fallback = props.fallback || <h1>Riscontrato un errore...</h1>;
    this.state = { hasError: false, error: null, errorStack: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    logError(error, info.componentStack);
    this.setState({
      ...this.state,
      error,
      errorStack: info.componentStack,
    });
  }

  render() {
    if (this.state.hasError) {
      const Fallback = this.fallback;
      // You can render any custom fallback UI
      return (
        <>
          <Fallback />
          {process.env.NODE_ENV === "development" ? (
            <ErrorDebugger
              errorStack={this.state.errorStack}
              error={this.state.error}
            />
          ) : null}
        </>
      );
    }

    return this.props.children;
  }
}

export function ErrorDebugger({ error, errorStack }) {
  return (
    <>
      <EuiSpacer size="l" />
      <EuiPanel color={"danger"} paddingSize={"l"}>
        <EuiCodeBlock language={"javascript"}>
          <EuiTitle size="xs">
            <p>Errore</p>
          </EuiTitle>
          {error.toString()}
          <br />
          {errorStack}
        </EuiCodeBlock>
      </EuiPanel>
    </>
  );
}

export default ErrorBoundary;
