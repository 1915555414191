import { useEffect } from "react";
import useGlobalProvider from "../../providers/globalProvider";
import apiClient, { handleApiError } from "../../apiClient";
import { useQuery } from "@tanstack/react-query";

export const CIMITERI_KEY = "cimiteri";

export function useCimiteri(
  comuneId,
  { query, sort, populate },
  useSetLoading = true
) {
  const { setLoading, setMessage, loading } = useGlobalProvider();
  const params = {
    pageSize: 0,
    page: 0,
    query,
    sort,
    populate,
  };

  const initialData = {
    data: [],
    meta: {
      totalItems: 0,
      currentPage: 0,
      pageSize: 0,
      totalPages: 0,
      query: {},
      sort: {},
    },
  };

  const {
    isLoading,
    isFetching,
    data: cimiteri,
  } = useQuery({
    queryKey: [`${CIMITERI_KEY}-${comuneId}-list`, params],
    queryFn: fetchCimiteri,
    placeholderData: initialData,
    staleTime: 60 * 1000, // 1 minute
    keepPreviousData: true,
    enabled: !!comuneId && !loading,
    onError: (err) =>
      handleApiError(
        err,
        setMessage,
        "Errore durante il caricamento dei cimiteri"
      ),
  });

  async function fetchCimiteri() {
    const { data } = await apiClient.cimitero.getAll(comuneId, params);
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading || isFetching);
    }
  }, [isLoading, isFetching]);

  return { cimiteri, isFetching, isLoading };
}

export function useCimitero(comuneId, id, useSetLoading = true) {
  const { setLoading, setMessage, loading } = useGlobalProvider();

  const {
    isLoading,
    isFetching,
    fetchStatus,
    data: cimitero,
  } = useQuery({
    queryKey: [`${CIMITERI_KEY}-${comuneId}-item`, { id }],
    queryFn: fetchCimitero,
    staleTime: 60 * 1000,
    enabled: !!id && !!comuneId && !loading,
    onError: (err) =>
      handleApiError(
        err,
        setMessage,
        `Errore durante il caricamento del cimitero con id ${id}`
      ),
  });

  async function fetchCimitero() {
    const { data } = await apiClient.cimitero.getById(comuneId, id);
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading((isLoading && fetchStatus !== "idle") || isFetching);
    }
  }, [isLoading, isFetching, fetchStatus]);

  return {
    cimitero,
    isFetching,
    isLoading: isLoading && fetchStatus !== "idle",
  };
}

export function useCimiteriAree(comuneId, query = {}, useSetLoading = true) {
  const { cimiteri, isFetching, isLoading } = useCimiteri(
    comuneId,
    { query, sort: {}, populate: [] },
    useSetLoading
  );

  const aree = cimiteri.data
    .map((cimitero) =>
      cimitero.aree.map((area) => ({ ...area, cimitero: cimitero.nome }))
    )
    .reduce((pre, cur) => pre.concat(cur), []);

  return { aree, isFetching, isLoading };
}

export function useCimiteriTipologiaLampade(
  comuneId,
  query = {},
  useSetLoading = true
) {
  const { cimiteri, isFetching, isLoading } = useCimiteri(
    comuneId,
    { query, sort: {}, populate: [] },
    useSetLoading
  );

  const tipologieLampada = [
    ...new Set(
      cimiteri.data
        .map((cimitero) =>
          cimitero.tipologieLampade.map((tipologia) => tipologia)
        )
        .reduce((pre, cur) => pre.concat(cur), [])
    ),
  ];

  return { tipologieLampada, isFetching, isLoading };
}

export function useCimiteriTipologiaLoculi(
  comuneId,
  query = {},
  useSetLoading = true
) {
  const { cimiteri, isFetching, isLoading } = useCimiteri(
    comuneId,
    { query, sort: {}, populate: [] },
    useSetLoading
  );

  const tipologieLoculi = [
    ...new Set(
      cimiteri.data
        .map((cimitero) =>
          cimitero.tipologieLoculi.map((tipologia) => tipologia)
        )
        .reduce((pre, cur) => pre.concat(cur), [])
    ),
  ];

  return { tipologieLoculi, isFetching, isLoading };
}
