import { EuiEmptyPrompt } from "@elastic/eui";

function WarningBanner({ title, body, actions }) {
  return (
    <EuiEmptyPrompt
      iconType="alert"
      color="warning"
      title={<h2>{title}</h2>}
      body={body}
      actions={actions}
    />
  );
}

export default WarningBanner;
