import { useEffect } from "react";
import useGlobalProvider from "../../providers/globalProvider";
import apiClient, { handleApiError } from "../../apiClient";
import { useQuery } from "@tanstack/react-query";

export const COMUNI_KEY = "comuni";

export function useComuni(
  { pageSize, page, query, sort },
  useSetLoading = true
) {
  const { setLoading, setMessage, loading } = useGlobalProvider();
  const params = { pageSize, page, query, sort };

  const initialData = {
    data: [],
    meta: {
      totalItems: 0,
      currentPage: page,
      pageSize: pageSize,
      totalPages: 0,
      query: {},
      sort: { createdAt: -1 },
    },
  };

  const {
    isLoading,
    isFetching,
    data: comuni,
  } = useQuery({
    queryKey: [`${COMUNI_KEY}-list`, params],
    queryFn: fetchComuni,
    placeholderData: initialData,
    staleTime: 60 * 1000, // 1 minute
    keepPreviousData: true,
    enabled: !loading,
    onError: (err) =>
      handleApiError(
        err,
        setMessage,
        "Errore durante il caricamento dei comuni"
      ),
  });

  async function fetchComuni() {
    const { data } = await apiClient.comune.getAll(params);
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading || isFetching);
    }
  }, [isLoading, isFetching]);

  return { comuni, isFetching, isLoading };
}

export function useComune(id, useSetLoading = true) {
  const { setLoading, setMessage, loading } = useGlobalProvider();

  const {
    isLoading,
    isFetching,
    fetchStatus,
    data: comune,
  } = useQuery({
    queryKey: [`${COMUNI_KEY}-item`, { id }],
    queryFn: fetchComune,
    staleTime: 60 * 1000,
    enabled: !!id && !loading,
    onError: (err) =>
      handleApiError(
        err,
        setMessage,
        `Errore durante il caricamento del comune con id ${id}`
      ),
  });

  async function fetchComune() {
    const { data } = await apiClient.comune.getById(id);
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading((isLoading && fetchStatus !== "idle") || isFetching);
    }
  }, [isLoading, isFetching, fetchStatus]);

  return { comune, isFetching, isLoading: isLoading && fetchStatus !== "idle" };
}
