import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useState } from "react";
import AsyncSelectField from "../FormFields/AsyncSelectField";
import validator from "validator/es";
import { useLampade } from "../../../lib/hooks/api/lampade";

function LampadaSearchField({
  field,
  handleOnChange,
  isSingleSelect,
  isClearable = true,
  compressed = false,
  fullWidth = false,
  helpText = null,
  append = null,
  additionalQuery = {},
}) {
  const { currentComuneId } = useGlobalProvider();
  const [searchQuery, setSearchQuery] = useState({});
  const { lampade, isFetching, isLoading } = useLampade(
    currentComuneId,
    {
      page: 0,
      pageSize: 20,
      query: { ...searchQuery, ...additionalQuery },
      sort: {},
    },
    false
  );

  const handleSearchChange = (searchValue) => {
    setSearchQuery(
      validator.isMongoId(searchValue)
        ? {
            _id: searchValue,
          }
        : {}
    );
  };

  return (
    <AsyncSelectField
      field={field}
      isSingleSelect={isSingleSelect}
      handleOnChange={handleOnChange}
      handleSearchChange={handleSearchChange}
      options={lampade.data.map((lampada) => ({
        code: lampada._id,
        label: `${lampada._id}`,
      }))}
      isLoading={isLoading || isFetching}
      isClearable={isClearable}
      compressed={compressed}
      fullWidth={fullWidth}
      helpText={helpText}
      append={append}
    />
  );
}

export default LampadaSearchField;
