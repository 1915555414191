import { EuiEmptyPrompt } from "@elastic/eui";

function ErrorBanner({ title, body, color = "danger" }) {
  return (
    <EuiEmptyPrompt
      iconType="error"
      color={color}
      title={<h2>{title}</h2>}
      body={body}
    />
  );
}

export default ErrorBanner;
