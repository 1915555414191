/** @jsxImportSource @emotion/react */
import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
  htmlIdGenerator,
} from "@elastic/eui";
import UtenteForm from "../pageForms/Utente.form";
import { useState } from "react";
import ModalMessage from "../../ModelMessage";

function UtenteModalForm({
  isModalVisible,
  setIsModalVisible,
  afterSuccessAction,
  entityName = "Utente",
}) {
  const [modalMessage, setModalMessage] = useState(null);
  const titleId = htmlIdGenerator()();

  const closeFlyout = () => {
    setIsModalVisible(false);
  };

  if (!isModalVisible) {
    return null;
  }

  return (
    <EuiFlyout
      ownFocus
      onClose={closeFlyout}
      size={"l"}
      aria-labelledby={"Utente modal form"}
    >
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2 id={titleId}>Crea {entityName}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody
        banner={
          <ModalMessage message={modalMessage} setMessage={setModalMessage} />
        }
      >
        <UtenteForm
          isEdit={false}
          utenteData={null}
          wrapInPanel={false}
          setMessage={setModalMessage}
          afterNewAction={afterSuccessAction}
        />
      </EuiFlyoutBody>
    </EuiFlyout>
  );
}

export default UtenteModalForm;
