export const REDUCER_ACTIONS = {
  change: "CHANGE",
  updateProp: "UPDATE_PROP",
  error: "ERROR",
  reset: "RESET",
};

export function formReducer(defaultState) {
  return (state, action) => {
    switch (action.type) {
      case REDUCER_ACTIONS.change: {
        return {
          ...state,
          [action.field]: {
            ...state[action.field],
            value: action.value,
            errors: [],
            isInvalid: false,
          },
        };
      }
      case REDUCER_ACTIONS.updateProp: {
        return {
          ...state,
          [action.field]: {
            ...state[action.field],
            [action.prop]: action.value,
          },
        };
      }
      case REDUCER_ACTIONS.error: {
        return {
          ...state,
          [action.field]: {
            ...state[action.field],
            isInvalid: true,
            errors: action.errors,
          },
        };
      }
      case REDUCER_ACTIONS.reset: {
        return {
          ...initFormState({
            objData: action.objData,
            isEdit: action.isEdit,
            defaultState,
          }),
        };
      }
      default: {
        throw Error("Unknown action: " + action.type);
      }
    }
  };
}

export function initFormState({ objData, isEdit, defaultState }) {
  const state = {};
  Object.keys(defaultState).forEach((key) => {
    if (objData && isEdit) {
      state[key] = {
        ...defaultState[key],
        value: objData[key] || objData[key] === 0 ? objData[key] : "",
      };
    } else {
      state[key] = defaultState[key];
    }
  });
  return state;
}

export function handleFieldChange(field, value, dispatcher, setMessage = null) {
  dispatcher({ type: REDUCER_ACTIONS.change, field, value });
  if (setMessage) {
    setMessage(null);
  }
}

export function handleIsRequiredToggle(field, isRequired, dispatcher) {
  dispatcher({
    type: REDUCER_ACTIONS.updateProp,
    field,
    prop: "is_required",
    value: isRequired,
  });
}

export function handleFieldError(field, errors, dispatcher) {
  dispatcher({ type: REDUCER_ACTIONS.error, field, errors });
}

export function handleFormReset(objData, isEdit, dispatcher) {
  dispatcher({ type: REDUCER_ACTIONS.reset, objData, isEdit });
}

export function setFormDataErrors(errors, dispatcher) {
  for (const [field, fieldErrors] of Object.entries(errors)) {
    handleFieldError(field, fieldErrors, dispatcher);
  }
}
