import AsyncSelectField from "../../forms/FormFields/AsyncSelectField";
import { handleFieldChange } from "../../forms/formUtils/reducer";
import TitolareSearchField from "../../forms/entitiesSearchFields/Titolare.search-field";
import DefuntoSearchField from "../../forms/entitiesSearchFields/Defunto.search-field";
import CimiteroSearchField from "../../forms/entitiesSearchFields/Cimitero.search-field";
import ComuneSearchField from "../../forms/entitiesSearchFields/Comune.search-field";
import LampadaSearchField from "../../forms/entitiesSearchFields/Lampada.search-field";
import LoculoSearchField from "../../forms/entitiesSearchFields/Loculo.search-field";
import CimiteroAree from "../../forms/entitiesSearchFields/CimiteroAree";
import CimiteroTipologiaLampade from "../../forms/entitiesSearchFields/CimiteroTipologiaLampade";
import CimiteroTipologiaLoculi from "../../forms/entitiesSearchFields/CimiteroTipologiaLoculi";

function EntitySearchField({
  inputField,
  fullWidth,
  field,
  formDataDispatcher,
  setMessage,
  handleSearchChange,
  isLoading,
  options,
  showPlaceholder,
}) {
  switch (field.entitySearch) {
    case "id":
      return (
        <AsyncSelectField
          field={inputField}
          fullWidth={fullWidth}
          showPlaceholder={showPlaceholder}
          isSingleSelect={field.isSingleSelect}
          handleOnChange={(values) =>
            handleFieldChange(
              field.field,
              values,
              formDataDispatcher,
              setMessage
            )
          }
          handleSearchChange={handleSearchChange}
          isLoading={isLoading}
          options={options}
        />
      );
    case "titolare":
      return (
        <TitolareSearchField
          field={inputField}
          fullWidth={fullWidth}
          isSingleSelect={field.isSingleSelect}
          handleOnChange={(values) =>
            handleFieldChange(
              field.field,
              values,
              formDataDispatcher,
              setMessage
            )
          }
        />
      );
    case "defunto":
      return (
        <DefuntoSearchField
          field={inputField}
          fullWidth={fullWidth}
          isSingleSelect={field.isSingleSelect}
          handleOnChange={(values) =>
            handleFieldChange(
              field.field,
              values,
              formDataDispatcher,
              setMessage
            )
          }
        />
      );
    case "cimitero":
      return (
        <CimiteroSearchField
          field={inputField}
          fullWidth={fullWidth}
          isSingleSelect={field.isSingleSelect}
          handleOnChange={(values) =>
            handleFieldChange(
              field.field,
              values,
              formDataDispatcher,
              setMessage
            )
          }
        />
      );
    case "comune":
      return (
        <ComuneSearchField
          field={inputField}
          fullWidth={fullWidth}
          isSingleSelect={field.isSingleSelect}
          handleOnChange={(values) =>
            handleFieldChange(
              field.field,
              values,
              formDataDispatcher,
              setMessage
            )
          }
        />
      );
    case "lampada":
      return (
        <LampadaSearchField
          field={inputField}
          fullWidth={fullWidth}
          isSingleSelect={field.isSingleSelect}
          handleOnChange={(values) =>
            handleFieldChange(
              field.field,
              values,
              formDataDispatcher,
              setMessage
            )
          }
        />
      );
    case "loculo":
      return (
        <LoculoSearchField
          field={inputField}
          fullWidth={fullWidth}
          isSingleSelect={field.isSingleSelect}
          handleOnChange={(values) =>
            handleFieldChange(
              field.field,
              values,
              formDataDispatcher,
              setMessage
            )
          }
        />
      );
    case "aree":
      return (
        <CimiteroAree
          field={inputField}
          fullWidth={fullWidth}
          isSingleSelect={field.isSingleSelect}
          handleOnChange={(values) =>
            handleFieldChange(
              field.field,
              values,
              formDataDispatcher,
              setMessage
            )
          }
        />
      );
    case "tipologiaLampade":
      return (
        <CimiteroTipologiaLampade
          field={inputField}
          fullWidth={fullWidth}
          isSingleSelect={field.isSingleSelect}
          handleOnChange={(values) =>
            handleFieldChange(
              field.field,
              values,
              formDataDispatcher,
              setMessage
            )
          }
        />
      );
    case "tipologiaLoculi":
      return (
        <CimiteroTipologiaLoculi
          field={inputField}
          fullWidth={fullWidth}
          isSingleSelect={field.isSingleSelect}
          handleOnChange={(values) =>
            handleFieldChange(
              field.field,
              values,
              formDataDispatcher,
              setMessage
            )
          }
        />
      );
    default:
      return null;
  }
}

export default EntitySearchField;
