import Toolbar from "../Toolbar";
import { formReducer, initFormState } from "../../forms/formUtils/reducer";
import { useReducer, useState } from "react";
import moment from "moment";
import validator from "validator/es";
import { useComuni } from "../../../lib/hooks/api/comune";

const searchFields = {
  groups: [
    {
      name: "",
      fields: [
        {
          field: "_id",
          label: "Id",
          type: "select",
          isSingleSelect: false,
          entitySearch: "id",
        },
        { field: "nome", label: "Nome", type: "string" },
        { field: "iban", label: "IBAN", type: "string" },
        { field: "intestatario", label: "Intestatario", type: "string" },
        {
          field: "createdAt",
          label: "Creato il",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(10, "y"),
            maxDate: moment(),
          },
        },
        {
          field: "updatedAt",
          label: "Modificato il",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(10, "y"),
            maxDate: moment(),
          },
        },
      ],
    },
  ],
};

const searchDefaultState = {
  _id: {
    value: [],
    label: "Id",
    name: "_id",
    is_required: false,
  },
  nome: {
    value: "",
    label: "Nome",
    name: "nome",
    is_required: false,
  },
  iban: {
    value: "",
    label: "IBAN",
    name: "iban",
    is_required: false,
  },
  intestatario: {
    value: "",
    label: "Intestatario",
    name: "intestatario",
    is_required: false,
  },
  createdAt: {
    value: {},
    label: "Creato il",
    name: "createdAt",
    is_required: false,
  },
  updatedAt: {
    value: {},
    label: "Modificato il",
    name: "updatedAt",
    is_required: false,
  },
};

function ComuniToolbar({ tableQuery, setTableQuery }) {
  const [searchFormData, searchFormDataDispatcher] = useReducer(
    formReducer(searchDefaultState),
    initFormState({
      objData: null,
      isEdit: false,
      defaultState: searchDefaultState,
    })
  );

  const [searchIdQuery, setSearchIdQuery] = useState({});

  const { comuni, isFetching, isLoading } = useComuni(
    {
      page: 0,
      pageSize: 20,
      query: searchIdQuery,
      sort: {},
    },
    false
  );

  const handleIdSearchChange = (searchValue) => {
    setSearchIdQuery(
      validator.isMongoId(searchValue)
        ? {
            _id: searchValue,
          }
        : {}
    );
  };

  const handleSubmit = (processedQuery) => {
    setTableQuery(processedQuery);
  };

  const handleReset = () => {
    setTableQuery({});
  };

  return (
    <Toolbar
      fieldsColumns={3}
      searchFields={searchFields}
      entitiesName={"Comuni"}
      searchFormData={searchFormData}
      searchFormDataDispatcher={searchFormDataDispatcher}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      isIdLoading={isLoading || isFetching}
      handleIdSearchChange={handleIdSearchChange}
      iDOptions={comuni.data.map((comune) => ({
        code: comune._id,
        label: comune._id,
      }))}
    />
  );
}

export default ComuniToolbar;
