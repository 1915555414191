import { EuiConfirmModal, EuiOutsideClickDetector } from "@elastic/eui";

function ConfirmDeleteModal({
  entities,
  entityName,
  entitiesName,
  showConfirmModal,
  setShowConfirmModal,
  handleSuccess,
  customTitle = null,
  customBody = null,
  extraMessage = null,
}) {
  const handleSuccessInternal = () => {
    setShowConfirmModal(false);
    handleSuccess(entities);
  };

  if (!showConfirmModal) {
    return null;
  }

  let title = "";
  let body = "";
  if (entities.length > 1) {
    title = `Vuoi davvere eliminare i ${entitiesName.toLowerCase()} selezionati?`;
    body = `Procedendo eliminerai i ${
      entities.length
    } ${entitiesName.toLowerCase()} selezionati${
      extraMessage ? `. ${extraMessage}` : ""
    }`;
  } else {
    title = `Vuoi davvere eliminare il ${entityName.toLowerCase()} selezionato?`;
    body = `Procedendo eliminerai il ${entityName.toLowerCase()} selezionato${
      extraMessage ? `. ${extraMessage}` : ""
    }`;
  }
  if (customTitle) {
    title = customTitle;
  }
  if (customBody) {
    body = customBody;
  }

  return (
    <EuiOutsideClickDetector onOutsideClick={() => setShowConfirmModal(false)}>
      <EuiConfirmModal
        title={title}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={handleSuccessInternal}
        cancelButtonText="No, annulla"
        confirmButtonText="Si, procedi"
        buttonColor="danger"
        defaultFocusedButton="cancel"
      >
        <p>{body}</p>
      </EuiConfirmModal>
    </EuiOutsideClickDetector>
  );
}

export default ConfirmDeleteModal;
