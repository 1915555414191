import { isSuperAdmin, UserRole, UsersRolesOptions } from "../../../lib/auth";
import useAuth from "../../../lib/providers/authProvider";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import {
  formReducer,
  handleFieldChange,
  handleFormReset,
  initFormState,
  setFormDataErrors,
} from "../formUtils/reducer";
import { useEffect, useMemo, useReducer, useState } from "react";
import { useCreateApiCall, useUpdateApiCall } from "../../../lib/hooks/api";
import apiClient, { handleApiError } from "../../../lib/apiClient";
import { USERS_KEY } from "../../../lib/hooks/api/users";
import { validateFormData } from "../../../lib/fieldsValidator";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiPanel,
} from "@elastic/eui";
import TextField from "../FormFields/TextField";
import PasswordField from "../FormFields/PasswordField";
import FormActions from "../formUtils/FormActions";
import SelectField from "../FormFields/SelectField";
import ComuneSearchField from "../entitiesSearchFields/Comune.search-field";

function prepareFormDefaultState(superAdmin, isEdit, currentComune) {
  const formDefaultState = {
    username: {
      value: "",
      isInvalid: false,
      errors: [],
      label: "Username",
      name: "username",
      is_required: true,
      validators: [],
      transforms: ["trim", "lowerCase"],
    },
    first_name: {
      value: "",
      isInvalid: false,
      errors: [],
      label: "Nome",
      name: "first_name",
      is_required: false,
      validators: [],
      transforms: ["trim"],
    },
    last_name: {
      value: "",
      isInvalid: false,
      errors: [],
      label: "Cognome",
      name: "last_name",
      is_required: false,
      validators: [],
      transforms: ["trim"],
    },
    roles: {
      value: [{ code: UserRole.Standard, label: UserRole.Standard }],
      isInvalid: false,
      errors: [],
      label: "Ruolo",
      name: "roles",
      is_required: true,
      validators: [],
      transforms: [],
    },
  };
  if (!isEdit) {
    formDefaultState["password"] = {
      value: "",
      isInvalid: false,
      errors: [],
      label: "Password",
      name: "password",
      is_required: true,
      validators: [],
      transforms: [],
    };
    formDefaultState["confirm_password"] = {
      value: "",
      isInvalid: false,
      errors: [],
      label: "Conferma Password",
      name: "confirm_password",
      is_required: true,
      validators: [],
      transforms: [],
    };
  }
  if (superAdmin) {
    formDefaultState["comune"] = {
      value: currentComune
        ? [{ code: currentComune._id, label: currentComune.nome }]
        : [],
      isInvalid: false,
      errors: [],
      label: "Comune",
      name: "comune",
      is_required: true,
      validators: [],
      transforms: [],
    };
  }

  return { formDefaultState };
}

function dataToFormData(userData) {
  if (userData) {
    return {
      ...userData,
      comune: userData.comune
        ? [
            {
              code: userData.comune._id,
              label: `${userData.comune.nome}`,
            },
          ]
        : [],
      roles:
        userData.roles.length > 0
          ? [{ code: userData.roles[0], label: userData.roles[0] }]
          : [],
      password: "",
    };
  } else {
    return userData;
  }
}

function formDataToData(formData) {
  let data = {
    ...formData,
    roles: {
      ...formData.roles,
      value:
        formData.roles.value.length > 0 ? [formData.roles.value[0].code] : [],
    },
  };
  if (formData.comune) {
    data = {
      ...data,
      comune: {
        ...formData.comune,
        value:
          formData.comune.value.length > 0 ? formData.comune.value[0].code : "",
        is_required: !(
          formData.roles.value.length > 0 &&
          formData.roles.value[0].code === UserRole.SuperAdmin
        ),
        isInvalid:
          formData.roles.value.length > 0 &&
          formData.roles.value[0].code === UserRole.SuperAdmin
            ? false
            : formData.comune.isInvalid,
        errors:
          formData.roles.value.length > 0 &&
          formData.roles.value[0].code === UserRole.SuperAdmin
            ? []
            : formData.comune.errors,
      },
    };
  }
  return data;
}

function UsersForm({ isEdit, userData, afterNew, afterEdit }) {
  const { user } = useAuth();
  const { setMessage, setLoading, currentComune } = useGlobalProvider();
  const { formDefaultState } = useMemo(
    () => prepareFormDefaultState(isSuperAdmin(user), isEdit, currentComune),
    []
  );
  const [disableComune, setDisableComune] = useState(
    isEdit && userData.roles[0] === UserRole.SuperAdmin
  );
  const [formData, formDataDispatcher] = useReducer(
    formReducer(formDefaultState),
    initFormState({
      objData: dataToFormData(userData),
      isEdit: isEdit,
      defaultState: formDefaultState,
    })
  );
  const { mutateAsync: createUser } = useCreateApiCall(
    apiClient.users.create,
    `${USERS_KEY}`,
    false
  );
  const { mutateAsync: updateUser } = useUpdateApiCall(
    apiClient.users.update,
    `${USERS_KEY}`,
    false
  );

  const handleReset = () => {
    handleFormReset(dataToFormData(userData), isEdit, formDataDispatcher);
    setMessage(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { isValid, errors, dataDto } = validateFormData(
      formDataToData(formData),
      isEdit,
      userData
    );
    let confirmIsEqual = true;
    if (isValid && !isEdit) {
      if (formData.password.value !== formData.confirm_password.value) {
        confirmIsEqual = false;
        errors["password"] = ["Le due password non corrispondono"];
        errors["confirm_password"] = ["Le due password non corrispondono"];
      }
    }
    if (!isValid || !confirmIsEqual) {
      setFormDataErrors(errors, formDataDispatcher);
      setMessage({
        title: "Errore! Alcuni campi non sono validi",
        color: "danger",
        iconType: "alert",
        text: "Correggi i valori inseriti e riprova",
      });
    } else {
      try {
        setLoading(true);
        if (!isSuperAdmin(user)) {
          dataDto["comune"] = currentComune._id;
        }
        let resultUser;
        if (isEdit) {
          resultUser = await handleEditSubmit(dataDto);
        } else {
          resultUser = await handleNewSubmit(dataDto);
        }
        if (isEdit) {
          afterEdit(resultUser);
        } else {
          afterNew(resultUser);
        }
      } catch (err) {
        handleApiError(
          err,
          setMessage,
          isEdit
            ? "Errore durante la modifica del gestore"
            : "Errore durante la creazione del gestore"
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const handleNewSubmit = async (dataDto) => {
    const { data: newUser } = await createUser([dataDto]);
    setMessage({
      title: `Gestore ${newUser.username} creato con successo`,
      iconType: "document",
      color: "success",
      timeout: 8000,
    });
    return newUser;
  };

  const handleEditSubmit = async (dataDto) => {
    const { data: updatedUser } = await updateUser([
      userData._id,
      dataDto,
      {
        populate: ["comune"],
      },
    ]);
    setMessage({
      title: `Gestore ${updatedUser.username} modificato con successo`,
      iconType: "document",
      color: "success",
      timeout: 8000,
    });
    return updatedUser;
  };

  useEffect(() => {
    if (formData.roles.value.length > 0) {
      if (formData.roles.value[0].code === UserRole.SuperAdmin) {
        setDisableComune(true);
        handleFieldChange("comune", [], formDataDispatcher, setMessage);
      } else {
        setDisableComune(false);
      }
    }
  }, [formData.roles.value]);

  return (
    <>
      <EuiPanel>
        <EuiForm component="form">
          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem>
                <TextField
                  fullWidth={true}
                  field={formData.username}
                  handleOnChange={(e) =>
                    handleFieldChange(
                      "username",
                      e.target.value,
                      formDataDispatcher,
                      setMessage
                    )
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <TextField
                  fullWidth={true}
                  field={formData.first_name}
                  handleOnChange={(e) =>
                    handleFieldChange(
                      "first_name",
                      e.target.value,
                      formDataDispatcher,
                      setMessage
                    )
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <TextField
                  fullWidth={true}
                  field={formData.last_name}
                  handleOnChange={(e) =>
                    handleFieldChange(
                      "last_name",
                      e.target.value,
                      formDataDispatcher,
                      setMessage
                    )
                  }
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>

          {!isEdit ? (
            <EuiFormRow fullWidth>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <PasswordField
                    fullWidth={true}
                    field={formData.password}
                    autoComplete={"new-password"}
                    handleOnChange={(e) =>
                      handleFieldChange(
                        "password",
                        e.target.value,
                        formDataDispatcher,
                        setMessage
                      )
                    }
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <PasswordField
                    fullWidth={true}
                    field={formData.confirm_password}
                    autoComplete={"new-password"}
                    handleOnChange={(e) =>
                      handleFieldChange(
                        "confirm_password",
                        e.target.value,
                        formDataDispatcher,
                        setMessage
                      )
                    }
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          ) : null}

          <EuiHorizontalRule margin="l" size="half" />

          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem>
                <SelectField
                  isSingleSelect={true}
                  field={formData.roles}
                  fullWidth={true}
                  handleOnChange={(val) =>
                    handleFieldChange(
                      "roles",
                      val,
                      formDataDispatcher,
                      setMessage
                    )
                  }
                  options={
                    isSuperAdmin(user)
                      ? UsersRolesOptions
                      : UsersRolesOptions.filter(
                          (role) => role.code !== UserRole.SuperAdmin
                        )
                  }
                  isClearable={true}
                />
              </EuiFlexItem>
              {isSuperAdmin(user) ? (
                <EuiFlexItem>
                  <ComuneSearchField
                    field={formData.comune}
                    disabled={disableComune}
                    fullWidth={true}
                    isSingleSelect={true}
                    handleOnChange={(values) =>
                      handleFieldChange(
                        "comune",
                        values,
                        formDataDispatcher,
                        setMessage
                      )
                    }
                    helpText={`Il comune non deve essere inserito quando il ruolo del gestore è "${UserRole.SuperAdmin}"`}
                  />
                </EuiFlexItem>
              ) : null}
            </EuiFlexGroup>
          </EuiFormRow>

          <FormActions
            handleSubmit={(e) => handleSubmit(e)}
            handleReset={handleReset}
            showReset={true}
            showRequiredText={true}
          />
        </EuiForm>
      </EuiPanel>
    </>
  );
}

export default UsersForm;
