import React, { useEffect } from "react";
import { logError } from "./errorLogger";
import { EuiCodeBlock, EuiPanel, EuiSpacer, EuiTitle } from "@elastic/eui";
import { useLocation } from "react-router-dom";
import useUpdateEffect from "../hooks/customHooks";
import useGlobalProvider from "../providers/globalProvider";
import { ErrorDebugger } from "./ErrorBoundary";

function ErrorResetHandler({ resetState }) {
  const location = useLocation();
  const { setLoading } = useGlobalProvider();

  useUpdateEffect(() => {
    resetState();
  }, [location.pathname]);

  useEffect(() => {
    setLoading(false);
  }, []);

  return null;
}

class ErrorBoundaryInternal extends React.Component {
  constructor(props) {
    super(props);
    this.fallback = props.fallback || <h1>Riscontrato un errore...</h1>;
    this.state = { hasError: false, error: null, errorStack: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    logError(error, info.componentStack);
    this.setState({
      ...this.state,
      error,
      errorStack: info.componentStack,
    });
  }

  render() {
    if (this.state.hasError) {
      const Fallback = this.fallback;
      // You can render any custom fallback UI
      return (
        <>
          <ErrorResetHandler
            resetState={() =>
              this.setState({ hasError: false, error: null, errorStack: null })
            }
          />
          <Fallback />
          {process.env.NODE_ENV === "development" ? (
            <ErrorDebugger
              errorStack={this.state.errorStack}
              error={this.state.error}
            />
          ) : null}
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryInternal;
