export const UserRole = {
  Standard: "standard",
  Admin: "admin",
  SuperAdmin: "super-admin",
};

export const UsersRolesOptions = Object.keys(UserRole).map((role) => ({
  code: UserRole[role],
  label: UserRole[role],
}));

export function userCanVisitPage(user, page) {
  if (page) {
    if (page.roles.length > 0) {
      return page.roles.filter((role) => user.roles.includes(role)).length > 0;
    }
  }
  return true;
}

export function isSuperAdmin(user) {
  if (!user) {
    return false;
  }
  return user.roles.includes(UserRole.SuperAdmin);
}

export function isAdmin(user, strict = false) {
  if (!user) {
    return false;
  }
  if (!strict) {
    return user.roles.includes(UserRole.Admin);
  } else {
    return user.roles.includes(UserRole.Admin) && user.roles.length === 1;
  }
}

export function isStandard(user, strict = false) {
  if (!user) {
    return false;
  }
  if (!strict) {
    return user.roles.includes(UserRole.Standard);
  } else {
    return user.roles.includes(UserRole.Standard) && user.roles.length === 1;
  }
}

export function isAnyAdmin(user) {
  return isSuperAdmin(user) || isAdmin(user, true);
}
