import { connection, loadAuthToken } from "./index";

const USERS_BASE = "/users";

function create(payload) {
  loadAuthToken();
  return connection.post(`${USERS_BASE}`, { ...payload });
}

function getAll(params = {}) {
  loadAuthToken();
  if (params.query) {
    if (typeof params.query !== "string") {
      params.query = JSON.stringify(params.query);
    }
  }
  if (params.sort) {
    if (typeof params.sort !== "string") {
      params.sort = JSON.stringify(params.sort);
    }
  }
  return connection.get(`${USERS_BASE}`, { params });
}

function getById(id, params = {}) {
  loadAuthToken();
  return connection.get(`${USERS_BASE}/${id}`, { params });
}

function update(id, payload, params = {}) {
  loadAuthToken();
  return connection.patch(`${USERS_BASE}/${id}`, { ...payload }, { params });
}

function updatePassword(id, old_password, new_password) {
  loadAuthToken();
  return connection.patch(`${USERS_BASE}/${id}/password`, {
    old_password,
    new_password,
  });
}

function resetPassword(id, new_password) {
  loadAuthToken();
  return connection.patch(`${USERS_BASE}/${id}/resetPassword`, {
    new_password,
  });
}

function remove(id) {
  loadAuthToken();
  return connection.delete(`${USERS_BASE}/${id}`);
}

const users = {
  create,
  getAll,
  getById,
  update,
  updatePassword,
  resetPassword,
  remove,
};

export default users;
