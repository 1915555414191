/** @jsxImportSource @emotion/react */
import { EuiFormRow, EuiFieldNumber } from "@elastic/eui";
import Label from "./Label";
import { css } from "@emotion/react";

function NumberField({
  field,
  handleOnChange,
  compressed = false,
  fullWidth = false,
  width = null,
  showPlaceholder = true,
  step = 1,
}) {
  const cssOptions = width ? `width: ${width}px` : "";
  return (
    <EuiFormRow
      label={<Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      isDisabled={field.disabled}
      fullWidth={fullWidth}
      display={compressed ? "rowCompressed" : "row"}
    >
      <EuiFieldNumber
        css={css(cssOptions)}
        compressed={compressed}
        fullWidth={fullWidth}
        value={field.value}
        placeholder={showPlaceholder ? field.label : ""}
        onChange={(e) => handleOnChange(e)}
        aria-label={field.label.toString()}
        name={field.name}
        isInvalid={field.isInvalid}
        step={step}
      />
    </EuiFormRow>
  );
}

export default NumberField;
