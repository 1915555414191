import { connection } from "./index";

function login(username, password) {
  return connection.post("/auth/login", { username, password });
}

function me() {
  return connection.get("/auth/me");
}

const auth = {
  login,
  me,
};

export default auth;
