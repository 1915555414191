import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useNavigate } from "react-router-dom";
import { useReducer, useState } from "react";
import {
  formReducer,
  handleFieldChange,
  handleFormReset,
  initFormState,
  setFormDataErrors,
} from "../formUtils/reducer";
import { useCreateApiCall, useUpdateApiCall } from "../../../lib/hooks/api";
import apiClient, { handleApiError } from "../../../lib/apiClient";
import { UTENTI_KEY } from "../../../lib/hooks/api/utenti";
import { validateFormData } from "../../../lib/fieldsValidator";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPanel,
} from "@elastic/eui";
import TextField from "../FormFields/TextField";
import FormActions from "../formUtils/FormActions";
import SelectField from "../FormFields/SelectField";
import {
  countriesCodeLabel,
  getCodeLabelObject,
  phoneFieldToString,
} from "../../../lib/data/countries";
import DateField from "../FormFields/DateField";
import moment from "moment";
import PhoneField from "../FormFields/PhoneField";
import { parsePhoneNumber } from "libphonenumber-js";

const formDefaultState = {
  nome: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Nome",
    name: "nome",
    is_required: true,
    validators: [],
    transforms: ["trim"],
  },
  cognome: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Cognome",
    name: "cognome",
    is_required: true,
    validators: [],
    transforms: ["trim"],
  },
  codiceFiscale: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Codice Fiscale",
    name: "codiceFiscale",
    is_required: true,
    validators: ["isCodiceFiscale"],
    transforms: ["trim", "upperCase"],
  },
  email: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Email",
    name: "email",
    is_required: true,
    validators: ["isEmail"],
    transforms: ["trim"],
  },
  telefono: {
    value: { code: "", number: "", countryCode: "" },
    isInvalid: false,
    errors: [],
    label: "Telefono",
    name: "telefono",
    is_required: false,
    validators: ["isPhoneNumber"],
    transforms: ["trim"],
  },
  dataNascita: {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Data Nascita",
    name: "dataNascita",
    is_required: false,
    validators: [],
    transforms: [],
  },
  luogoNascita: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Luogo Nascita",
    name: "luogoNascita",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  statoNascita: {
    value: [getCodeLabelObject("IT")],
    isInvalid: false,
    errors: [],
    label: "Stato Nascita",
    name: "statoNascita",
    is_required: false,
    validators: [],
    transforms: [],
  },
  indirizzo: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Indirizzo",
    name: "indirizzo",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  citta: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Città",
    name: "citta",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  cap: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "CAP",
    name: "cap",
    is_required: false,
    validators: ["isPostalCode"],
    transforms: ["trim"],
  },
  provincia: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Provincia",
    name: "provincia",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  stato: {
    value: [getCodeLabelObject("IT")],
    isInvalid: false,
    errors: [],
    label: "Stato",
    name: "stato",
    is_required: false,
    validators: [],
    transforms: [],
  },
};

function utenteDataToFormData(utenteData) {
  if (utenteData) {
    let parsedPhone;
    if (utenteData.telefono) {
      parsedPhone = parsePhoneNumber(utenteData.telefono, utenteData.stato);
    }
    return {
      ...utenteData,
      statoNascita: utenteData.statoNascita
        ? [getCodeLabelObject(utenteData.statoNascita)]
        : [],
      stato: utenteData.stato ? [getCodeLabelObject(utenteData.stato)] : [],
      telefono: parsedPhone
        ? {
            code: parsedPhone.countryCallingCode,
            number: parsedPhone.nationalNumber,
            countryCode: parsedPhone.country,
          }
        : { code: "", number: "", countryCode: "" },
    };
  } else {
    return utenteData;
  }
}

function formDataToUtenteData(formData) {
  return {
    ...formData,
    statoNascita: {
      ...formData.statoNascita,
      value:
        formData.statoNascita.value.length > 0
          ? formData.statoNascita.value[0].code
          : "",
    },
    stato: {
      ...formData.stato,
      value:
        formData.stato.value.length > 0 ? formData.stato.value[0].code : "",
    },
    telefono: {
      ...formData.telefono,
      value:
        formData.telefono.value.number !== ""
          ? phoneFieldToString(formData.telefono.value)
          : "",
    },
  };
}

function UtenteForm({
  isEdit,
  utenteData,
  setMessage,
  wrapInPanel = true,
  afterNewAction = null,
}) {
  const { currentComuneId } = useGlobalProvider();
  const navigate = useNavigate();
  const [resetToggler, setResetToggler] = useState(false);
  const [formData, formDataDispatcher] = useReducer(
    formReducer(formDefaultState),
    initFormState({
      objData: utenteDataToFormData(utenteData),
      isEdit: isEdit,
      defaultState: formDefaultState,
    })
  );
  const { mutateAsync: createUtente } = useCreateApiCall(
    apiClient.utente.create,
    `${UTENTI_KEY}-${currentComuneId}`,
    true
  );
  const { mutateAsync: updateUtente } = useUpdateApiCall(
    apiClient.utente.update,
    `${UTENTI_KEY}-${currentComuneId}`,
    true
  );

  const handleReset = () => {
    handleFormReset(
      utenteDataToFormData(utenteData),
      isEdit,
      formDataDispatcher
    );
    setResetToggler(!resetToggler);
    setMessage(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors, dataDto } = validateFormData(
      formDataToUtenteData(formData),
      isEdit,
      utenteData
    );
    if (!isValid) {
      setFormDataErrors(errors, formDataDispatcher);
      setMessage({
        title: "Errore! Alcuni campi non sono validi",
        color: "danger",
        iconType: "alert",
        text: "Correggi i valori inseriti e riprova",
      });
    } else {
      if (isEdit) {
        handleEditSubmit(dataDto);
      } else {
        handleNewSubmit(dataDto);
      }
    }
  };

  const handleNewSubmit = async (dataDto) => {
    try {
      const { data: utente } = await createUtente([currentComuneId, dataDto]);
      setMessage({
        title: `Utente ${dataDto.nome} creato con successo`,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
      if (afterNewAction) {
        afterNewAction(utente);
      } else {
        navigate("/utenti");
      }
    } catch (err) {
      handleApiError(
        err,
        setMessage,
        "Errore durante la creazione dell'utente"
      );
    }
  };

  const handleEditSubmit = async (dataDto) => {
    try {
      await updateUtente([currentComuneId, utenteData._id, dataDto]);
      setMessage({
        title: `Utente ${dataDto.nome} modificato con successo`,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
      navigate("/utenti");
    } catch (err) {
      handleApiError(err, setMessage, "Errore durante la modifica dell'utente");
    }
  };

  const form = (
    <EuiForm component="form">
      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <TextField
              field={formData.nome}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "nome",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData.cognome}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "cognome",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData.codiceFiscale}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "codiceFiscale",
                  e.target.value.toUpperCase(),
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <TextField
              field={formData.email}
              fullWidth={true}
              type="email"
              handleOnChange={(e) =>
                handleFieldChange(
                  "email",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <PhoneField
              field={formData.telefono}
              fullWidth={true}
              handleOnChange={(value) =>
                handleFieldChange(
                  "telefono",
                  value,
                  formDataDispatcher,
                  setMessage
                )
              }
              defaultLocale={"IT"}
              reset={resetToggler}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <TextField
              field={formData.luogoNascita}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "luogoNascita",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <DateField
              field={formData.dataNascita}
              fullWidth={true}
              handleOnChange={(val) =>
                handleFieldChange(
                  "dataNascita",
                  val,
                  formDataDispatcher,
                  setMessage
                )
              }
              minDate={moment().subtract(120, "y")}
              maxDate={moment().subtract(18, "y")}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <SelectField
              isSingleSelect={true}
              field={formData.statoNascita}
              fullWidth={true}
              handleOnChange={(val) =>
                handleFieldChange(
                  "statoNascita",
                  val,
                  formDataDispatcher,
                  setMessage
                )
              }
              options={countriesCodeLabel}
              isClearable={true}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <TextField
              field={formData.indirizzo}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "indirizzo",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData.citta}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "citta",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <TextField
              field={formData.cap}
              fullWidth={false}
              width={100}
              handleOnChange={(e) =>
                handleFieldChange(
                  "cap",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <TextField
              field={formData.provincia}
              fullWidth={false}
              width={90}
              handleOnChange={(e) =>
                handleFieldChange(
                  "provincia",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <SelectField
              isSingleSelect={true}
              field={formData.stato}
              fullWidth={true}
              handleOnChange={(val) =>
                handleFieldChange("stato", val, formDataDispatcher, setMessage)
              }
              options={countriesCodeLabel}
              isClearable={true}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <FormActions
        handleSubmit={(e) => handleSubmit(e)}
        handleReset={handleReset}
        showReset={true}
        showRequiredText={true}
      />
    </EuiForm>
  );

  return wrapInPanel ? <EuiPanel>{form}</EuiPanel> : form;
}

export default UtenteForm;
