import React, { createContext, useState, useContext, useEffect } from "react";
import { useComune } from "../hooks/api/comune";

const initialState = {
  message: null,
  /*{
      title: "Message title",
      color: "danger",
      iconType: "alert",
      text: "Message body",
      timeout: 10000  // it will disappear after 10 seconds
    },*/
  selectedComune: null,
};

const GlobalContext = createContext({
  message: initialState.message,
  selectedComune: initialState.selectedComune,
});

export const GlobalProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(initialState.message);
  const [fatalError, setFatalError] = useState(null);
  const [globalWarning, setGlobalWarning] = useState(null);
  const [currentComuneId, setCurrentComuneId] = useState(null);
  const [resetComune, setResetComune] = useState(false);

  const {
    comune: currentComune,
    isLoading,
    isFetching,
  } = useComune(currentComuneId, false);

  useEffect(() => {
    if (message?.timeout) {
      setTimeout(() => setMessage(null), message.timeout);
    }
  }, [message]);

  return (
    <GlobalContext.Provider
      value={{
        loading,
        setLoading,
        message,
        setMessage,
        fatalError,
        setFatalError,
        globalWarning,
        setGlobalWarning,
        currentComuneId,
        setCurrentComuneId,
        currentComune,
        resetComune,
        setResetComune,
      }}
    >
      <div className="app">{children}</div>
    </GlobalContext.Provider>
  );
};

export default function useGlobalProvider() {
  return useContext(GlobalContext);
}
