export const REDUCER_ACTIONS = {
  currentPage: "CURRENT_PAGE",
  pageSize: "PAGE_SIZE",
  sort: "SORT",
  useSpecialQuery: "USE_SPECIAL_QUERY",
  all: "ALL",
};

export function tableReducer(state, action) {
  switch (action.type) {
    case REDUCER_ACTIONS.currentPage:
      return {
        ...state,
        currentPage: action.currentPage < 0 ? 0 : action.currentPage,
      };
    case REDUCER_ACTIONS.pageSize:
      return {
        ...state,
        pageSize: action.pageSize,
      };
    case REDUCER_ACTIONS.sort:
      return {
        ...state,
        sort: action.sort,
      };
    case REDUCER_ACTIONS.useSpecialQuery:
      return {
        ...state,
        useSpecialQuery: action.useSpecialQuery,
      };
    case REDUCER_ACTIONS.all:
      return {
        ...state,
        currentPage: action.currentPage < 0 ? 0 : action.currentPage,
        pageSize: action.pageSize,
        sort: action.sort,
        useSpecialQuery: action.useSpecialQuery
          ? action.useSpecialQuery
          : false,
      };
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

export function handleCurrentPageChange(currentPage, dispatcher) {
  dispatcher({ type: REDUCER_ACTIONS.currentPage, currentPage });
}

export function handlePageSizeChange(pageSize, dispatcher) {
  dispatcher({ type: REDUCER_ACTIONS.pageSize, pageSize });
}

export function handleSortChange(sort, dispatcher) {
  dispatcher({ type: REDUCER_ACTIONS.sort, sort });
}

export function handleAllChange(
  currentPage,
  pageSize,
  sort,
  dispatcher,
  useSpecialQuery = false
) {
  dispatcher({
    type: REDUCER_ACTIONS.all,
    currentPage,
    pageSize,
    sort,
    useSpecialQuery,
  });
}

export function dbSortToTableSort(dbSort, specialFields = {}) {
  let sort = null;
  if (dbSort !== {}) {
    for (const [key, value] of Object.entries(dbSort)) {
      if (specialFields[key]) {
        sort = {
          field: specialFields[key],
          direction: value === 1 ? "asc" : "desc",
        };
      } else {
        sort = {
          field: key,
          direction: value === 1 ? "asc" : "desc",
        };
      }
    }
  }
  return sort;
}

export function tableSortToDbSort(tableSort, specialFields = {}) {
  let sortField = null;
  let sortDirection = null;
  const sortDb = {};
  let useSpecialQuery = false;
  if (tableSort) {
    const { field, direction } = tableSort;
    sortField = field;
    sortDirection = direction === "asc" ? 1 : -1;
    if (specialFields[field]) {
      useSpecialQuery = true;
      if (Array.isArray(specialFields[field])) {
        specialFields[field].forEach((specialField) => {
          sortDb[specialField] = sortDirection;
        });
      } else {
        sortField = specialFields[field];
        sortDb[sortField] = sortDirection;
      }
    } else {
      sortDb[sortField] = sortDirection;
    }
  }
  return { sortDb, useSpecialQuery };
}
