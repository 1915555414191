/** @jsxImportSource @emotion/react */
import { EuiFormRow, EuiFieldText, EuiFormControlLayout } from "@elastic/eui";
import Label from "./Label";
import { css } from "@emotion/react";

function UsernameField({ field, handleOnChange, icon, fullWidth = false }) {
  return (
    <EuiFormRow
      label={<Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      isDisabled={field.disabled}
      fullWidth={fullWidth}
    >
      <EuiFormControlLayout
        icon={icon ? icon : "user"}
        isDisabled={field.disabled}
      >
        <EuiFieldText
          fullWidth={fullWidth}
          value={field.value}
          css={css(`padding-left: 40px !important`)}
          onChange={(e) => handleOnChange(e)}
          aria-label={field.label.toString()}
          name={field.name}
          isInvalid={field.isInvalid}
        />
      </EuiFormControlLayout>
    </EuiFormRow>
  );
}

export default UsernameField;
