import ErrorBanner from "./ErrorBanner";

function InLayoutError({
  title = "Qualcosa è andato storto",
  body = "È stato riscontrato un errore. Prova a ricaricare la pagina, se l'errore persiste contattare l'assistenza",
}) {
  return <ErrorBanner title={title} body={body} />;
}

export default InLayoutError;
