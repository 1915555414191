import useGlobalProvider from "../../../../lib/providers/globalProvider";
import { useCimiteriTipologiaLoculi } from "../../../../lib/hooks/api/cimiteri";
import useUpdateEffect from "../../../../lib/hooks/customHooks";
import { handleFieldChange } from "../../formUtils/reducer";
import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiToolTip,
} from "@elastic/eui";
import CimiteroSearchField from "../../entitiesSearchFields/Cimitero.search-field";
import SelectField from "../../FormFields/SelectField";
import NumberField from "../../FormFields/NumberField";
import CimiteroAree from "../../entitiesSearchFields/CimiteroAree";
import TextField from "../../FormFields/TextField";
import InputImageFieldWithPreview from "../../FormFields/InputImageFieldWithPreview";
import TextAreaField from "../../FormFields/TextAreaField";
import { getStaticServerFilePath } from "../../../../lib/utils";

function LoculoGeneraleFormView({
  loculoData,
  formData,
  formDataDispatcher,
  fotoRef,
  qrCodeRef,
  isEdit,
}) {
  const { setMessage, currentComuneId } = useGlobalProvider();

  const {
    tipologieLoculi,
    isLoading: isTipologiaLoculoLoading,
    isFetching: isTipologiaLoculoFetching,
  } = useCimiteriTipologiaLoculi(
    currentComuneId,
    formData.cimitero.value.length > 0
      ? { _id: formData.cimitero.value[0].code }
      : {},
    false
  );

  useUpdateEffect(() => {
    let reset = true;
    if (isEdit) {
      if (formData.cimitero.value.length > 0) {
        if (formData.cimitero.value[0].code === loculoData?.cimitero?._id) {
          reset = false;
        }
      }
    }
    if (reset) {
      handleFieldChange("tipologia", [], formDataDispatcher, setMessage);
      handleFieldChange("area", [], formDataDispatcher, setMessage);
    }
  }, [formData.cimitero.value]);

  return (
    <>
      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <CimiteroSearchField
              field={formData.cimitero}
              fullWidth={true}
              isSingleSelect={true}
              handleOnChange={(values) =>
                handleFieldChange(
                  "cimitero",
                  values,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <SelectField
              field={formData.tipologia}
              fullWidth={true}
              options={tipologieLoculi.map((tipologia) => ({
                code: tipologia,
                label: tipologia,
              }))}
              isSingleSelect={true}
              handleOnChange={(values) =>
                handleFieldChange(
                  "tipologia",
                  values,
                  formDataDispatcher,
                  setMessage
                )
              }
              isLoading={isTipologiaLoculoLoading || isTipologiaLoculoFetching}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <NumberField
              field={formData.latitudine}
              fullWidth={true}
              step={0.1}
              handleOnChange={(e) =>
                handleFieldChange(
                  "latitudine",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <NumberField
              field={formData.longitudine}
              fullWidth={true}
              step={0.1}
              handleOnChange={(e) =>
                handleFieldChange(
                  "longitudine",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiHorizontalRule size="half" margin="l" />

      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <CimiteroAree
              field={formData.area}
              fullWidth={true}
              isSingleSelect={true}
              handleOnChange={(values) =>
                handleFieldChange(
                  "area",
                  values,
                  formDataDispatcher,
                  setMessage
                )
              }
              additionalQuery={
                formData.cimitero.value.length > 0
                  ? {
                      _id: formData.cimitero.value[0].code,
                    }
                  : {}
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData.settore}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "settore",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData.lotto}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "lotto",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData.fila}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "fila",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData.piano}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "piano",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <TextField
              field={formData.padiglione}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "padiglione",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData.zona}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "zona",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData.posto}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "posto",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData.localita}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "localita",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData.blocco}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "blocco",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiHorizontalRule size="half" margin="l" />
      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <InputImageFieldWithPreview
              field={formData.foto}
              fullWidth={true}
              fieldRef={fotoRef}
              handleOnChange={(file) =>
                handleFieldChange(
                  "foto",
                  file[0],
                  formDataDispatcher,
                  setMessage
                )
              }
              initialValue={
                loculoData?.foto
                  ? getStaticServerFilePath(loculoData.foto)
                  : null
              }
              previewSize={"s"}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <InputImageFieldWithPreview
              field={formData.qrCode}
              fullWidth={true}
              fieldRef={qrCodeRef}
              handleOnChange={(file) =>
                handleFieldChange(
                  "qrCode",
                  file[0],
                  formDataDispatcher,
                  setMessage
                )
              }
              initialValue={
                loculoData?.qrCode
                  ? getStaticServerFilePath(loculoData.qrCode)
                  : null
              }
              previewSize="s"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <TextField
              field={formData.tour_link}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "tour_link",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
              append={
                <EuiToolTip position={"top"} content={"Vai a tour virtuale"}>
                  <EuiButtonIcon
                    aria-label={"Virtual tour"}
                    iconType={"visMapRegion"}
                    href={formData.tour_link.value}
                    disabled={formData.tour_link.value === ""}
                    target={"_blank"}
                  />
                </EuiToolTip>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextAreaField
              field={formData.note}
              fullWidth={true}
              rows={5}
              handleOnChange={(e) =>
                handleFieldChange(
                  "note",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
              helpText={"Autorizzazioni, sopralluoghi, verbali, etc."}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    </>
  );
}

export default LoculoGeneraleFormView;
