import { connection, loadAuthToken } from "./index";
import { getComuneResourcePath } from "./comune";

const BASE_PATH = "cimitero";

function create(comuneId, payload) {
  loadAuthToken();
  return connection.post(
    `${getComuneResourcePath(comuneId, BASE_PATH)}`,
    payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}

function getAll(comuneId, params = {}) {
  loadAuthToken();
  if (params.query) {
    if (typeof params.query !== "string") {
      params.query = JSON.stringify(params.query);
    }
  }
  if (params.sort) {
    if (typeof params.sort !== "string") {
      params.sort = JSON.stringify(params.sort);
    }
  }

  return connection.get(`${getComuneResourcePath(comuneId, BASE_PATH)}`, {
    params,
  });
}

function getById(comuneId, id) {
  loadAuthToken();
  return connection.get(`${getComuneResourcePath(comuneId, BASE_PATH)}/${id}`);
}

function update(comuneId, id, payload) {
  loadAuthToken();
  return connection.patch(
    `${getComuneResourcePath(comuneId, BASE_PATH)}/${id}`,
    payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}

function remove(comuneId, id) {
  loadAuthToken();
  return connection.delete(
    `${getComuneResourcePath(comuneId, BASE_PATH)}/${id}`
  );
}

const cimitero = {
  create,
  getAll,
  getById,
  update,
  remove,
};

export default cimitero;
