import Papa from "papaparse";

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function roundToDecimal(value, precision = 2) {
  let multiplier = 1;
  for (let i = 0; i < precision; i++) {
    multiplier *= 10;
  }

  return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function getStaticServerFilePath(imagePath) {
  return `${process.env.REACT_APP_API_URL}/${imagePath}`;
}

export function readCsvFile(file) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete: function (results) {
        resolve(results);
      },
      error: function (err, file, inputElem, reason) {
        reject(err);
      },
    });
  });
}

export function readJSONFile(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = (e) => {
      const content = e.target.result;
      try {
        resolve(JSON.parse(content));
      } catch (err) {
        reject(err);
      }
    };
  });
}

export function dateToIso(date, showTimeSelect) {
  if (showTimeSelect) {
    return date.toISOString();
  } else {
    return date
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISOString();
  }
}
