import AsyncSelectField from "../FormFields/AsyncSelectField";
import { useState } from "react";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useCimiteri } from "../../../lib/hooks/api/cimiteri";
import { useGeneratedHtmlId } from "@elastic/eui";
import { useComuni } from "../../../lib/hooks/api/comune";

function ComuneSearchField({
  field,
  handleOnChange,
  isSingleSelect,
  isClearable = true,
  compressed = false,
  fullWidth = false,
  append = null,
  helpText = null,
  prefix = "",
  disabled = false,
}) {
  const [searchQuery, setSearchQuery] = useState({});
  const { comuni, isFetching, isLoading } = useComuni(
    {
      page: 0,
      pageSize: 25,
      query: searchQuery,
      sort: { nome: 1 },
    },
    false
  );

  const handleSearchChange = (searchValue) => {
    setSearchQuery({
      nome: { $regex: ".*" + searchValue + ".*", $options: "i" },
    });
  };

  return (
    <AsyncSelectField
      field={field}
      isSingleSelect={isSingleSelect}
      handleOnChange={handleOnChange}
      handleSearchChange={handleSearchChange}
      options={comuni.data.map((comune) => ({
        code: comune._id,
        label: `${comune.nome}`,
      }))}
      isLoading={isLoading || isFetching}
      isClearable={isClearable}
      compressed={compressed}
      fullWidth={fullWidth}
      append={append}
      helpText={helpText}
      prefix={prefix}
      isDisabled={disabled}
    />
  );
}

export default ComuneSearchField;
