import useGlobalProvider from "../../../../lib/providers/globalProvider";
import {
  formReducer,
  handleFieldChange,
  handleFormReset,
  initFormState,
  setFormDataErrors,
} from "../../formUtils/reducer";
import { useReducer, useRef, useState } from "react";
import { validateFormData } from "../../../../lib/fieldsValidator";
import LoculoFormView from "./Loculo.form-view";
import { EuiSpacer, EuiTabs, EuiTab } from "@elastic/eui";
import LampadaForm from "../Lampada.form";
import DefuntiForm from "../Defunti.form";
import {
  errorMessagesMapping,
  isRequired,
} from "../../../../lib/fieldsValidator/string";
import apiClient, { handleApiError } from "../../../../lib/apiClient";
import { useCreateApiCall, useUpdateApiCall } from "../../../../lib/hooks/api";
import { LOCULI_KEY } from "../../../../lib/hooks/api/loculi";
import { LAMPADE_KEY } from "../../../../lib/hooks/api/lampade";
import { DEFUNTI_KEY } from "../../../../lib/hooks/api/defunti";
import { useQueryClient } from "@tanstack/react-query";

const formDefaultState = {
  cimitero: {
    value: [],
    isInvalid: false,
    errors: [],
    label: "Cimitero",
    name: "cimitero",
    is_required: true,
    validators: [],
    transforms: [],
  },
  tipologia: {
    value: [],
    isInvalid: false,
    errors: [],
    label: "Tipologia",
    name: "tipologia",
    is_required: true,
    validators: [],
    transforms: [],
  },
  latitudine: {
    value: "0",
    isInvalid: false,
    errors: [],
    label: "Latitudine",
    name: "latitudine",
    is_required: false,
    validators: ["isFloat"],
    transforms: ["trim"],
  },
  longitudine: {
    value: "0",
    isInvalid: false,
    errors: [],
    label: "Longitudine",
    name: "longitudine",
    is_required: false,
    validators: ["isFloat"],
    transforms: ["trim"],
  },
  area: {
    value: [],
    isInvalid: false,
    errors: [],
    label: "Area",
    name: "area",
    is_required: true,
    validators: [],
    transforms: [],
  },
  settore: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Settore",
    name: "settore",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  lotto: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Lotto",
    name: "lotto",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  fila: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Fila",
    name: "fila",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  piano: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Piano",
    name: "piano",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  padiglione: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Padiglione",
    name: "padiglione",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  zona: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Zona",
    name: "zona",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  posto: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Posto",
    name: "posto",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  localita: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Localita",
    name: "localita",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  blocco: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Blocco",
    name: "blocco",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  tour_link: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Tour Link",
    name: "tour_link",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  foto: {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Foto",
    name: "foto",
    is_required: false,
    validators: [],
    transforms: [],
  },
  qrCode: {
    value: null,
    isInvalid: false,
    errors: [],
    label: "QrCode",
    name: "qrCode",
    is_required: false,
    validators: [],
    transforms: [],
  },
  note: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Note",
    name: "note",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  "contratto.titolare": {
    value: [],
    isInvalid: false,
    errors: [],
    label: "Utente",
    name: "contratto.titolare",
    is_required: false,
    validators: [],
    transforms: [],
  },
  "contratto.dataConcessione": {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Data Concessione",
    name: "contratto.dataConcessione",
    is_required: false,
    validators: [],
    transforms: [],
  },
  "contratto.dataDelibera": {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Data Delibera",
    name: "contratto.dataDelibera",
    is_required: false,
    validators: [],
    transforms: [],
  },
  "contratto.dataContratto": {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Data Contratto",
    name: "contratto.dataContratto",
    is_required: false,
    validators: [],
    transforms: [],
  },
  "contratto.dataScadenza": {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Data Scadenza",
    name: "contratto.dataScadenza",
    is_required: false,
    validators: [],
    transforms: [],
  },
  "contratto.protocollo": {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Protocollo",
    name: "contratto.protocollo",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  "contratto.repertorio": {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Repertorio",
    name: "contratto.repertorio",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  "contratto.registro": {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Registro",
    name: "contratto.registro",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  "contratto.pagina": {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Pagina",
    name: "contratto.pagina",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  "contratto.documentazione": {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Fascicolo",
    name: "contratto.documentazione",
    is_required: false,
    validators: [],
    transforms: [],
  },
  defunti: {
    value: [],
    isInvalid: false,
    errors: [],
    label: "Defunti",
    name: "defunti",
    is_required: false,
    validators: [],
    transforms: [],
  },
  lampada: {
    value: [],
    isInvalid: false,
    errors: [],
    label: "Lampada",
    name: "lampada",
    is_required: false,
    validators: [],
    transforms: [],
  },
};

function dataToFormData(loculoData) {
  if (loculoData) {
    const contrattoData = {};
    if (loculoData.contratto) {
      for (const [key, value] of Object.entries(loculoData.contratto)) {
        switch (key) {
          case "titolare":
            if (value) {
              contrattoData[`contratto.${key}`] = [
                {
                  code: value._id,
                  label: `${value.cognome} ${value.nome} - ${value.codiceFiscale}`,
                },
              ];
            } else {
              contrattoData[`contratto.${key}`] = [];
            }
            break;
          default:
            contrattoData[`contratto.${key}`] = value;
        }
      }
      if (!contrattoData.hasOwnProperty("contratto.titolare")) {
        contrattoData["contratto.titolare"] = [];
      }
    } else {
      contrattoData["contratto.titolare"] = [];
    }
    const parsedData = { ...contrattoData };
    for (const [key, value] of Object.entries(loculoData)) {
      switch (key) {
        case "latitudine":
          parsedData[key] = value.toString();
          break;
        case "longitudine":
          parsedData[key] = value.toString();
          break;
        case "tipologia":
          parsedData[key] = [{ code: value, label: value }];
          break;
        case "area":
          parsedData[key] = [{ code: value, label: value }];
          break;
        case "cimitero":
          parsedData[key] = value
            ? [
                {
                  code: value._id,
                  label: `${value.nome}`,
                },
              ]
            : [];
          break;
        case "defunti":
          parsedData[key] = value.map((defunto) => ({
            code: defunto._id,
            label: `${defunto.cognome} ${defunto.nome} - ${defunto.codiceFiscale}`,
          }));
          break;
        case "lampada":
          parsedData[key] = value
            ? [{ code: value._id, label: value._id }]
            : [];
          break;
        default:
          parsedData[key] = value;
      }
    }
    if (!parsedData.cimitero) {
      parsedData.cimitero = [];
    }
    if (!parsedData.lampada) {
      parsedData.lampada = [];
    }
    return parsedData;
  } else {
    return loculoData;
  }
}

function formDataToData(formData) {
  return {
    ...formData,
    tipologia: {
      ...formData.tipologia,
      value:
        formData.tipologia.value.length > 0
          ? formData.tipologia.value[0].code
          : "",
    },
    area: {
      ...formData.area,
      value: formData.area.value.length > 0 ? formData.area.value[0].code : "",
    },
    cimitero: {
      ...formData.cimitero,
      value:
        formData.cimitero.value.length > 0
          ? formData.cimitero.value[0].code
          : "",
    },
    ["contratto.titolare"]: {
      ...formData["contratto.titolare"],
      value:
        formData["contratto.titolare"].value.length > 0
          ? formData["contratto.titolare"].value[0].code
          : "",
    },
    defunti: {
      ...formData.defunti,
      value:
        formData.defunti.value.length > 0
          ? formData.defunti.value.map((defunto) => defunto.code)
          : [],
    },
    lampada: {
      ...formData.lampada,
      value:
        formData.lampada.value.length > 0
          ? formData.lampada.value[0].code
          : undefined,
    },
  };
}

function addContrattoData(dataDto) {
  const newDto = {};
  let addContratto = false;
  const contrattoDto = {};
  for (const [key, value] of Object.entries(dataDto)) {
    if (key.includes("contratto.")) {
      contrattoDto[key.replace("contratto.", "")] = value;
      addContratto = true;
    } else {
      newDto[key] = value;
    }
  }
  if (addContratto) {
    newDto["contratto"] = contrattoDto;
  }
  return newDto;
}

const tabs = [
  {
    id: "loculo",
    name: "Loculo",
  },
  {
    id: "lampada",
    name: "Lampada",
  },
  {
    id: "defunti",
    name: "Defunti",
  },
];

function LoculoForm({ isEdit, loculoData, afterNew, afterEdit }) {
  const { currentComuneId, setMessage, setLoading } = useGlobalProvider();
  const [formData, formDataDispatcher] = useReducer(
    formReducer(formDefaultState),
    initFormState({
      objData: dataToFormData(loculoData),
      isEdit: isEdit,
      defaultState: formDefaultState,
    })
  );
  const [selectedTabId, setSelectedTabId] = useState("loculo");
  const [showDocumentazioneViewer, setShowDocumentazioneViewer] = useState(
    isEdit && loculoData?.contratto?.documentazione
  );
  const documentazioneRef = useRef(null);
  const fotoRef = useRef(null);
  const qrCodeRef = useRef(null);

  const queryClient = useQueryClient();
  const { mutateAsync: createLoculo } = useCreateApiCall(
    apiClient.loculo.create,
    `${LOCULI_KEY}-${currentComuneId}`,
    false
  );
  const { mutateAsync: updateLoculo } = useUpdateApiCall(
    apiClient.loculo.update,
    `${LOCULI_KEY}-${currentComuneId}`,
    false
  );
  const { mutateAsync: updateLoculoLampadaAndDefuntiMutate } = useUpdateApiCall(
    apiClient.loculo.updateLampadaAndDefunti,
    `${LOCULI_KEY}-${currentComuneId}`,
    false
  );

  const afterLampadaIsCreated = (lampada) => {
    setSelectedTabId("loculo");
    handleFieldChange(
      "lampada",
      [
        {
          code: lampada._id,
          label: `${lampada._id}`,
        },
      ],
      formDataDispatcher,
      setMessage
    );
    setLoading(false);
  };

  const afterDefuntoIsCreated = (defunto) => {
    setSelectedTabId("loculo");
    handleFieldChange(
      "defunti",
      [
        ...formData.defunti.value,
        {
          code: defunto._id,
          label: `${defunto.cognome} ${defunto.nome} - ${defunto.codiceFiscale}`,
        },
      ],
      formDataDispatcher,
      setMessage
    );
    setLoading(false);
  };

  const handleReset = () => {
    handleFormReset(dataToFormData(loculoData), isEdit, formDataDispatcher);
    if (documentazioneRef.current) {
      documentazioneRef.current.removeFiles();
    }
    if (fotoRef.current) {
      fotoRef.current.removeFiles();
    }
    if (qrCodeRef.current) {
      qrCodeRef.current.removeFiles();
    }
    setShowDocumentazioneViewer(
      isEdit && loculoData?.contratto?.documentazione
    );
  };

  const verifyContrattoData = (errors) => {
    let contrattoIsValid = true;
    if (!isEdit) {
      let anyFilled = false;
      for (const [fieldName, fieldInfo] of Object.entries(formData)) {
        if (
          fieldName.includes("contratto.") &&
          fieldInfo.value !== "" &&
          fieldInfo.value?.length > 0 &&
          fieldInfo.value !== null &&
          fieldInfo.value !== undefined
        ) {
          anyFilled = true;
        }
      }
      if (formData["contratto.documentazione"].value) {
        anyFilled = true;
      }
      if (anyFilled) {
        for (const [fieldName, fieldInfo] of Object.entries(formData)) {
          if (fieldName.includes("contratto.")) {
            if (!isRequired({ ...fieldInfo, is_required: true })) {
              contrattoIsValid = false;
              errors[fieldName] = [errorMessagesMapping.is_required(fieldName)];
            }
          }
        }
      }
    }
    return contrattoIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { isValid, errors, dataDto } = validateFormData(
      formDataToData(formData),
      isEdit,
      loculoData
    );
    const contrattoIsValid = verifyContrattoData(errors);
    if (!isValid || !contrattoIsValid) {
      setFormDataErrors(errors, formDataDispatcher);
      setMessage({
        title: "Errore! Alcuni campi non sono validi",
        color: "danger",
        iconType: "alert",
        text: "Correggi i valori inseriti e riprova",
      });
    } else {
      const dtoToUpload = addContrattoData(dataDto);
      const dataDtoFormData = new FormData();
      for (const [key, value] of Object.entries(dtoToUpload)) {
        switch (key) {
          case "contratto":
            delete value["documentazione"];
            dataDtoFormData.append(key, JSON.stringify(value));
            break;
          case "defunti":
            dataDtoFormData.append(key, JSON.stringify(value));
            break;
          default:
            dataDtoFormData.append(key, value);
        }
      }
      if (dataDto["contratto.documentazione"]) {
        dataDtoFormData.append(
          "documentazione",
          dataDto["contratto.documentazione"]
        );
      }
      try {
        setLoading(true);
        let loculo;
        if (isEdit) {
          loculo = await handleEditSubmit(dataDtoFormData);
        } else {
          loculo = await handleNewSubmit(dataDtoFormData);
        }
        await setLampadaAndDefunti(loculo, dataDto);
        if (isEdit) {
          afterEdit(loculo);
        } else {
          afterNew(loculo);
        }
      } catch (err) {
        handleApiError(
          err,
          setMessage,
          isEdit
            ? "Errore durante la modifica del defunto"
            : "Errore durante la creazione del defunto"
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const handleNewSubmit = async (dataDto) => {
    const { data: loculo } = await createLoculo([currentComuneId, dataDto]);
    setMessage({
      title: `Loculo creato con successo`,
      iconType: "document",
      color: "success",
      timeout: 8000,
    });
    return loculo;
  };

  const handleEditSubmit = async (dataDto) => {
    const { data: loculo } = await updateLoculo([
      currentComuneId,
      loculoData._id,
      dataDto,
      {
        populate: ["contratto.titolare", "lampada", "cimitero", "defunti"],
      },
    ]);
    setMessage({
      title: `Loculo modificato con successo`,
      iconType: "document",
      color: "success",
      timeout: 8000,
    });
    return loculo;
  };

  const setLampadaAndDefunti = async (loculo, dataDto) => {
    const payload = {};
    let doUpdate = false;
    if (!isEdit) {
      payload["addDefunti"] = dataDto.defunti;
      payload["removeDefunti"] = [];
      if (dataDto.lampada) {
        payload["addLampada"] = dataDto.lampada;
      }
      doUpdate = true;
    } else {
      const existingDefunti = loculoData.defunti.map((defunto) => defunto._id);
      const addDefunti = [];
      const removeDefunti = [];
      dataDto.defunti.forEach((defunto) => {
        if (!existingDefunti.includes(defunto)) {
          addDefunti.push(defunto);
        }
      });
      existingDefunti.forEach((defunto) => {
        if (!dataDto.defunti.includes(defunto)) {
          removeDefunti.push(defunto);
        }
      });
      payload["addDefunti"] = addDefunti;
      payload["removeDefunti"] = removeDefunti;
      if (loculoData.lampada && !dataDto.lampada) {
        payload["removeLampada"] = loculoData.lampada._id;
      }
      if (!loculoData.lampada && dataDto.lampada) {
        payload["addLampada"] = dataDto.lampada;
      }
      if (
        loculoData.lampada &&
        dataDto.lampada &&
        loculoData.lampada._id !== dataDto.lampada
      ) {
        payload["removeLampada"] = loculoData.lampada._id;
        payload["addLampada"] = dataDto.lampada;
      }
      doUpdate = true;
    }
    if (doUpdate) {
      const { data: updatedData } = await updateLoculoLampadaAndDefuntiMutate([
        currentComuneId,
        loculo._id,
        payload,
        {
          populate: ["contratto.titolare", "lampada", "cimitero", "defunti"],
        },
      ]);
      const defuntiKey = `${DEFUNTI_KEY}-${currentComuneId}`;
      const lampadaKey = `${LAMPADE_KEY}-${currentComuneId}`;
      const loculoKey = `${LOCULI_KEY}-${currentComuneId}`;
      queryClient.setQueryData(
        [`${loculoKey}-item`, { id: loculo._id }],
        updatedData
      );
      queryClient.invalidateQueries({ queryKey: `${loculoKey}-list` });
      queryClient.invalidateQueries({ queryKey: `${lampadaKey}-list` });
      queryClient.removeQueries({
        queryKey: [
          `${lampadaKey}-item`,
          {
            id: payload.lampada,
          },
        ],
      });
      queryClient.invalidateQueries({ queryKey: `${defuntiKey}-list` });
      payload.addDefunti.forEach((defunto) =>
        queryClient.removeQueries({
          queryKey: [
            `${defuntiKey}-item`,
            {
              id: defunto,
            },
          ],
        })
      );
      payload.removeDefunti.forEach((defunto) =>
        queryClient.removeQueries({
          queryKey: [
            `${defuntiKey}-item`,
            {
              id: defunto,
            },
          ],
        })
      );
    }
  };

  const onSelectedTabChanged = (id) => setSelectedTabId(id);

  const renderTabs = tabs.map((tab, index) => (
    <EuiTab
      onClick={() => onSelectedTabChanged(tab.id)}
      isSelected={tab.id === selectedTabId}
      key={index}
    >
      {tab.name}
    </EuiTab>
  ));

  return (
    <>
      <EuiTabs>{renderTabs}</EuiTabs>
      <EuiSpacer size="l" />
      <TabContent
        loculoData={loculoData}
        selectedTabId={selectedTabId}
        onSelectedTabChanged={onSelectedTabChanged}
        formData={formData}
        formDataDispatcher={formDataDispatcher}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        afterLampadaIsCreated={afterLampadaIsCreated}
        afterDefuntoIsCreated={afterDefuntoIsCreated}
        fotoRef={fotoRef}
        documentazioneRef={documentazioneRef}
        qrCodeRef={qrCodeRef}
        isEdit={isEdit}
        showDocumentazioneViewer={showDocumentazioneViewer}
        setShowDocumentazioneViewer={setShowDocumentazioneViewer}
      />
    </>
  );
}

function TabContent({
  loculoData,
  selectedTabId,
  onSelectedTabChanged,
  formData,
  formDataDispatcher,
  handleReset,
  handleSubmit,
  afterLampadaIsCreated,
  afterDefuntoIsCreated,
  fotoRef,
  documentazioneRef,
  qrCodeRef,
  isEdit,
  showDocumentazioneViewer,
  setShowDocumentazioneViewer,
}) {
  switch (selectedTabId) {
    case "loculo":
      return (
        <LoculoFormView
          loculoData={loculoData}
          formData={formData}
          formDataDispatcher={formDataDispatcher}
          handleReset={handleReset}
          handleSubmit={handleSubmit}
          onSelectedTabChanged={onSelectedTabChanged}
          fotoRef={fotoRef}
          documentazioneRef={documentazioneRef}
          qrCodeRef={qrCodeRef}
          isEdit={isEdit}
          showDocumentazioneViewer={showDocumentazioneViewer}
          setShowDocumentazioneViewer={setShowDocumentazioneViewer}
        />
      );
    case "lampada":
      return (
        <LampadaForm
          lampadaData={null}
          isEdit={false}
          afterEdit={() => {}}
          afterNew={afterLampadaIsCreated}
          wireLoculo={false}
        />
      );
    case "defunti":
      return (
        <DefuntiForm
          defuntoData={null}
          isEdit={false}
          afterEdit={() => {}}
          afterNew={afterDefuntoIsCreated}
          wireLoculo={false}
        />
      );
    default:
      return null;
  }
}

export default LoculoForm;
