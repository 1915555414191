import LoculiPage from "../../pages/navbar/Loculo/Loculi.page";
import LampadePage from "../../pages/navbar/Lampada/Lampade.page";
import DefuntiPage from "../../pages/navbar/Defunto/Defunti.page";
import UtentiPage from "../../pages/navbar/Utente/Utenti.page";
import UsersPage from "../../pages/navbar/User/Users.page";
import { UserRole } from "../auth";
import ComuniPage from "../../pages/navbar/Comune/Comuni.page";
import ComunePage from "../../pages/navbar/Comune/Comune.page";
import loculiIcon from "../../assets/images/loculi.svg";
import lampadeIcon from "../../assets/images/lampadina.svg";
import defuntiIcon from "../../assets/images/defunti.svg";
import utentiIcon from "../../assets/images/utenti.svg";
import comuneIcon from "../../assets/images/comune.svg";
import usersIcon from "../../assets/images/user.svg";
import UtentePage from "../../pages/navbar/Utente/Utente.page";
import DefuntoPage from "../../pages/navbar/Defunto/Defunto.page";
import LampadaPage from "../../pages/navbar/Lampada/Lampada.page";
import LoculoPage from "../../pages/navbar/Loculo/Loculo.page";
import UserPage from "../../pages/navbar/User/User.page";

export const pages = [
  {
    code: "dashboard",
    url: "/",
    label: "Cimiteri",
    icon: null,
    useOnLogo: true,
    mainNavbar: false,
    isFullPage: false,
    roles: [],
  },
  {
    code: "loculi",
    url: "/loculi",
    label: "Loculi",
    icon: loculiIcon,
    mainNavbar: true,
    isFullPage: false,
    component: <LoculiPage />,
    subComponent: <LoculoPage />,
    roles: [],
  },
  {
    code: "lampade",
    url: "/lampade",
    label: "Lampade",
    icon: lampadeIcon,
    mainNavbar: true,
    isFullPage: false,
    component: <LampadePage />,
    subComponent: <LampadaPage />,
    roles: [],
  },
  {
    code: "defunti",
    url: "/defunti",
    label: "Defunti",
    icon: defuntiIcon,
    mainNavbar: true,
    isFullPage: false,
    component: <DefuntiPage />,
    subComponent: <DefuntoPage />,
    roles: [],
  },
  {
    code: "utenti",
    url: "/utenti",
    label: "Utenti",
    icon: utentiIcon,
    mainNavbar: true,
    isFullPage: false,
    component: <UtentiPage />,
    subComponent: <UtentePage />,
    roles: [],
  },
  {
    code: "users",
    url: "/users",
    label: "Gestori",
    icon: "user",
    mainNavbar: true,
    isFullPage: false,
    component: <UsersPage />,
    subComponent: <UserPage />,
    roles: [UserRole.Admin, UserRole.SuperAdmin],
  },
  {
    code: "comuni",
    url: "/comuni",
    label: "Comuni",
    icon: comuneIcon,
    mainNavbar: true,
    isFullPage: false,
    component: <ComuniPage />,
    subComponent: <ComunePage />,
    roles: [UserRole.SuperAdmin],
  },
  {
    code: "profile",
    url: "/profile",
    label: "Edit profile",
    icon: "fa-solid fa-user",
    mainNavbar: false,
    profileMenu: true,
    isFullPage: false,
    roles: [],
  },
  {
    code: "login",
    url: "/login",
    label: "Login",
    icon: "fa-solid fa-arrow-right-from-bracket",
    mainNavbar: false,
    profileMenu: false,
    isLogout: false,
    isLogin: true,
    isFullPage: true,
    roles: [],
  },
  {
    code: "forbidden",
    url: "/forbidden",
    label: "Forbidden",
    icon: "fa-solid fa-arrow-right-from-bracket",
    mainNavbar: false,
    profileMenu: false,
    isLogout: false,
    isLogin: false,
    isFullPage: true,
    roles: [],
  },
  {
    code: "logout",
    url: null,
    label: "Log out",
    icon: "fa-solid fa-arrow-right-from-bracket",
    mainNavbar: false,
    profileMenu: false,
    isLogout: true,
    isFullPage: false,
    roles: [],
  },
];

export function getPage(pageCode) {
  const filtered = pages.filter((page) => page.code === pageCode);
  if (filtered.length === 1) {
    return filtered[0];
  } else {
    return null;
  }
}

export function getPageFromUrl(pageUrl) {
  const filtered = pages.filter((page) => page.url === pageUrl);
  if (filtered.length === 1) {
    return filtered[0];
  } else {
    return null;
  }
}

export function getLogoPage() {
  return pages.filter((page) => page.code === "dashboard")[0];
}

export function getLogoutPage() {
  return pages.filter((page) => page.code === "logout")[0];
}

export function getLoginPage() {
  return pages.filter((page) => page.code === "login")[0];
}

export function getMainNavbarPages() {
  return pages.filter((page) => page.mainNavbar);
}

export function getProfileMenuPages() {
  return pages.filter((page) => page.profileMenu);
}
