import React from "react";
import {
  EuiHeaderSectionItem,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiI18n,
} from "@elastic/eui";
import { getMainNavbarPages } from "../../lib/data/pages";
import useNavigation from "../../lib/providers/navigationProvider";
import { Link } from "react-router-dom";
import useAuth from "../../lib/providers/authProvider";
import { userCanVisitPage } from "../../lib/auth";

function PagesMenu() {
  const { user } = useAuth();
  const { currentPage } = useNavigation();
  const mainPages = getMainNavbarPages();

  const navbarPages = mainPages
    .filter((page) => userCanVisitPage(user, page))
    .map((page, index) => (
      <Link key={index} to={page.url}>
        <EuiHeaderLink
          isActive={currentPage === page.code}
          iconType={page.icon}
        >
          <EuiI18n token={page.label} default={page.label} />
        </EuiHeaderLink>
      </Link>
    ));

  return (
    <EuiHeaderSectionItem>
      <EuiHeaderLinks aria-label={`${process.env.REACT_APP_NAME}-navigation`}>
        {navbarPages}
      </EuiHeaderLinks>
    </EuiHeaderSectionItem>
  );
}

export default PagesMenu;
