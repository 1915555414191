import { useEffect } from "react";
import useGlobalProvider from "../../providers/globalProvider";
import apiClient, { handleApiError } from "../../apiClient";
import { useQuery } from "@tanstack/react-query";

export const LOCULI_KEY = "loculi";

export function useLoculi(
  comuneId,
  { pageSize, page, query, sort, populate, useSpecialQuery },
  useSetLoading = true
) {
  const { setLoading, setMessage, loading } = useGlobalProvider();
  const params = {
    pageSize,
    page,
    query,
    sort,
    populate,
    useSpecialQuery: useSpecialQuery ? "1" : "0",
  };

  const initialData = {
    data: [],
    meta: {
      totalItems: 0,
      currentPage: 0,
      pageSize: 0,
      totalPages: 0,
      query: {},
      sort: { createdAt: -1 },
    },
  };

  const {
    isLoading,
    isFetching,
    data: loculi,
  } = useQuery({
    queryKey: [`${LOCULI_KEY}-${comuneId}-list`, params],
    queryFn: fetch,
    placeholderData: initialData,
    staleTime: 60 * 1000, // 1 minute
    keepPreviousData: true,
    enabled: !!comuneId && !loading,
    onError: (err) =>
      handleApiError(
        err,
        setMessage,
        "Errore durante il caricamento dei loculi"
      ),
  });

  async function fetch() {
    const { data } = await apiClient.loculo.getAll(comuneId, params);
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading || isFetching);
    }
  }, [isLoading, isFetching]);

  return { loculi, isFetching, isLoading };
}

export function useLoculo(comuneId, id, useSetLoading = true, populate = null) {
  const { setLoading, setMessage, loading } = useGlobalProvider();

  const {
    isLoading,
    isFetching,
    fetchStatus,
    data: loculo,
  } = useQuery({
    queryKey: [`${LOCULI_KEY}-${comuneId}-item`, { id }],
    queryFn: fetch,
    staleTime: 60 * 1000,
    enabled: !!id && !!comuneId && !loading,
    onError: (err) =>
      handleApiError(
        err,
        setMessage,
        `Errore durante il caricamento del loculo con id ${id}`
      ),
  });

  async function fetch() {
    const { data } = await apiClient.loculo.getById(comuneId, id, {
      populate,
    });
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading((isLoading && fetchStatus !== "idle") || isFetching);
    }
  }, [isLoading, isFetching, fetchStatus]);

  return { loculo, isFetching, isLoading: isLoading && fetchStatus !== "idle" };
}
