import ErrorBanner from "./ErrorBanner";

function TableError({
  title = "Qualcosa è andato storto durante la creazione della tabella",
  body = "È stato riscontrato un errore. Prova a ricaricare la pagina, se l'errore persiste contattare l'assistenza",
}) {
  return <ErrorBanner title={title} body={body} color={"warning"} />;
}

export default TableError;
