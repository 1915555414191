import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useCimiteri } from "../../../lib/hooks/api/cimiteri";
import CimiteroCard from "../../../components/CimiteroCard";
import { Fragment, useEffect } from "react";
import { EuiButton, EuiSpacer } from "@elastic/eui";
import useNavigation from "../../../lib/providers/navigationProvider";
import useApp from "../../../lib/providers/appProvider";
import { useNavigate } from "react-router-dom";
import WarningBanner from "../../../components/WarningBanner";
import { isSuperAdmin } from "../../../lib/auth";
import useAuth from "../../../lib/providers/authProvider";

function CimiteriPage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setManualPageTitle } = useApp();
  const { setPageHeaderActions } = useNavigation();
  const { currentComuneId, globalWarning } = useGlobalProvider();
  const { cimiteri } = useCimiteri(currentComuneId, {
    query: {},
    sort: {},
    populate: ["comune"],
  });

  useEffect(() => {
    setManualPageTitle("Cimiteri");
  }, []);

  const newCimiteroButton = (
    <EuiButton size="s" fill onClick={() => navigate("/cimitero/nuovo")}>
      Crea nuovo
    </EuiButton>
  );

  useEffect(() => {
    if (globalWarning) {
      setPageHeaderActions([]);
    } else {
      if (isSuperAdmin(user)) {
        setPageHeaderActions([newCimiteroButton]);
      }
    }
    return () => setPageHeaderActions([]);
  }, [globalWarning]);

  if (globalWarning) {
    return (
      <WarningBanner
        title={globalWarning.title}
        body={globalWarning.body}
        actions={globalWarning.actions}
      />
    );
  }

  return (
    <Fragment>
      {cimiteri.data.map((cimitero, index) => (
        <Fragment key={index}>
          <CimiteroCard
            key={index}
            cimiteroData={cimitero}
            comuneId={currentComuneId}
          />
          <EuiSpacer size="l" />
        </Fragment>
      ))}
    </Fragment>
  );
}

export default CimiteriPage;
