import AsyncSelectField from "../FormFields/AsyncSelectField";
import { useEffect, useState } from "react";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useUtenti } from "../../../lib/hooks/api/utenti";

function TitolareSearchField({
  field,
  handleOnChange,
  isSingleSelect,
  isClearable = true,
  compressed = false,
  fullWidth = false,
  append = null,
  helpText = null,
}) {
  const { currentComuneId } = useGlobalProvider();
  const [searchQuery, setSearchQuery] = useState({});
  const { utenti, isFetching, isLoading } = useUtenti(
    currentComuneId,
    {
      page: 0,
      pageSize: 20,
      query: searchQuery,
      sort: { cognome: 1, nome: 1 },
    },
    false
  );

  const handleSearchChange = (searchValue) => {
    const andConditions = [];

    searchValue.split(" ").forEach((seed) => {
      andConditions.push({
        $or: [
          { cognome: { $regex: ".*" + seed + ".*", $options: "i" } },
          { nome: { $regex: ".*" + seed + ".*", $options: "i" } },
          { codiceFiscale: { $regex: ".*" + seed + ".*", $options: "i" } },
        ],
      });
    });

    setSearchQuery({
      $and: andConditions,
    });
  };

  return (
    <AsyncSelectField
      field={field}
      isSingleSelect={isSingleSelect}
      handleOnChange={handleOnChange}
      handleSearchChange={handleSearchChange}
      options={utenti.data.map((utente) => ({
        code: utente._id,
        label: `${utente.cognome} ${utente.nome} - ${utente.codiceFiscale}`,
      }))}
      isLoading={isLoading || isFetching}
      isClearable={isClearable}
      compressed={compressed}
      fullWidth={fullWidth}
      append={append}
      helpText={helpText}
    />
  );
}

export default TitolareSearchField;
