import useGlobalProvider from "../../../../lib/providers/globalProvider";
import {
  EuiForm,
  EuiPanel,
  EuiText,
  EuiAccordion,
  useGeneratedHtmlId,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import FormActions from "../../formUtils/FormActions";
import LoculoLampadaAndDefuntiFormView from "./LoculoLampadaAndDefunti.form-view";
import LoculoGeneraleFormView from "./LoculoGenerale.form-view";
import LoculoContrattoFormView from "./LoculoContratto.form-view";

function LoculoFormView({
  loculoData,
  formData,
  formDataDispatcher,
  handleSubmit,
  handleReset,
  onSelectedTabChanged,
  fotoRef,
  documentazioneRef,
  qrCodeRef,
  isEdit,
  showDocumentazioneViewer,
  setShowDocumentazioneViewer,
}) {
  const { setMessage } = useGlobalProvider();
  const loculoFormId = useGeneratedHtmlId({ prefix: "loculo_form" });
  const generaleAccordionId = useGeneratedHtmlId({
    prefix: "loculo_generale-accordion",
  });
  const contrattoAccordionId = useGeneratedHtmlId({
    prefix: "loculo_contratto-accordion",
  });
  const lampadaDefuntiAccordionId = useGeneratedHtmlId({
    prefix: "lampada_defunti-accordion",
  });

  return (
    <EuiPanel>
      <EuiForm component="form">
        <EuiAccordion
          id={generaleAccordionId}
          buttonContent={<AccordionButton title={"Dati generali loculo"} />}
          paddingSize="m"
          arrowDisplay="right"
          initialIsOpen={true}
          element="fieldset"
          className="euiAccordionForm"
          buttonClassName="euiAccordionForm__button"
        >
          <LoculoGeneraleFormView
            loculoData={loculoData}
            formData={formData}
            formDataDispatcher={formDataDispatcher}
            fotoRef={fotoRef}
            documentazioneRef={documentazioneRef}
            qrCodeRef={qrCodeRef}
            isEdit={isEdit}
          />
        </EuiAccordion>
        <EuiAccordion
          id={contrattoAccordionId}
          buttonContent={
            <AccordionButton
              title={"Dati contratto loculo"}
              subTitle={
                "Puoi aggiungere il contratto in un secondo momento. Nel caso in cui si aggiunga il contratto i campi sono obbligatori."
              }
            />
          }
          paddingSize="m"
          arrowDisplay="right"
          element="fieldset"
          className="euiAccordionForm"
          buttonClassName="euiAccordionForm__button"
        >
          <LoculoContrattoFormView
            loculoData={loculoData}
            formData={formData}
            formDataDispatcher={formDataDispatcher}
            documentazioneRef={documentazioneRef}
            isEdit={isEdit}
            showDocumentazioneViewer={showDocumentazioneViewer}
            setShowDocumentazioneViewer={setShowDocumentazioneViewer}
          />
        </EuiAccordion>
        <EuiAccordion
          id={lampadaDefuntiAccordionId}
          buttonContent={
            <AccordionButton
              title={"Collega lampada e defunti al loculo"}
              subTitle={
                "Puoi collegare la lampada e i defunti in un secondo momento"
              }
            />
          }
          paddingSize="m"
          arrowDisplay="right"
          element="fieldset"
          buttonClassName="euiAccordionForm__button"
          initialIsOpen={true}
        >
          <LoculoLampadaAndDefuntiFormView
            loculoData={loculoData}
            formData={formData}
            formDataDispatcher={formDataDispatcher}
            onSelectedTabChanged={onSelectedTabChanged}
            isEdit={isEdit}
          />
        </EuiAccordion>
        <FormActions
          spacerSize={"m"}
          handleSubmit={(e) => handleSubmit(e)}
          handleReset={handleReset}
          showReset={true}
          showRequiredText={true}
        />
      </EuiForm>
    </EuiPanel>
  );
}

function AccordionButton({ title, subTitle = null, icon = null }) {
  return (
    <div>
      <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false}>
        {icon ? (
          <EuiFlexItem grow={false}>
            <EuiIcon type={icon} size="m" />
          </EuiFlexItem>
        ) : null}

        <EuiFlexItem>
          <EuiTitle size="xs">
            <h3>{title}</h3>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      {subTitle ? (
        <EuiText size="s">
          <p>
            <EuiTextColor color="subdued">{subTitle}</EuiTextColor>
          </p>
        </EuiText>
      ) : null}
    </div>
  );
}

export default LoculoFormView;
