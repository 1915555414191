/** @jsxImportSource @emotion/react */
import React from "react";
import {
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiHeaderSection,
  useEuiTheme,
  EuiText,
} from "@elastic/eui";
import useAuth from "../../lib/providers/authProvider";
import logo from "../../assets/images/logo-verticale.svg";
import UserMenu from "./UserMenu";
import PagesMenu from "./PagesMenu";
import { useNavigate } from "react-router-dom";
import { getLogoPage } from "../../lib/data/pages";
import ComuneSelector from "../../components/ComuneSelector";
import { isSuperAdmin } from "../../lib/auth";
import { css } from "@emotion/react";
import useGlobalProvider from "../../lib/providers/globalProvider";

function PageNavbar() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { euiTheme } = useEuiTheme();

  const logoPage = getLogoPage();

  const handleLogoClick = () => {
    if (user) {
      navigate(logoPage.url);
    }
  };

  return (
    <EuiHeader position="fixed">
      <EuiHeaderSection grow={false}>
        <EuiHeaderSectionItem border="right">
          <EuiHeaderLogo
            iconType={logo}
            iconTitle={"Dove Riposano Gestionale"}
            onClick={handleLogoClick}
            css={css`
              cursor: pointer;
            `}
          >
            <EuiText
              css={css`
                color: ${euiTheme.colors.primary} !important;
              `}
            >
              <strong>DR Gestionale</strong>
            </EuiText>
          </EuiHeaderLogo>
        </EuiHeaderSectionItem>
        {user ? <PagesMenu /> : null}
      </EuiHeaderSection>
      <EuiHeaderSection side="right">
        {user && isSuperAdmin(user) ? (
          <EuiHeaderSectionItem>
            <ComuneSelector />
          </EuiHeaderSectionItem>
        ) : (
          <EuiHeaderSectionItem>
            <ComuneDisplay />
          </EuiHeaderSectionItem>
        )}
        {user ? (
          <EuiHeaderSectionItem>
            <UserMenu user={user} logout={logout} />
          </EuiHeaderSectionItem>
        ) : null}
      </EuiHeaderSection>
    </EuiHeader>
  );
}

function ComuneDisplay({}) {
  const { currentComune } = useGlobalProvider();

  if (true /*!currentComune*/) {
    return null;
  }

  return (
    <EuiText
      css={css`
        margin-right: 8px;
      `}
    >
      {currentComune.nome}
    </EuiText>
  );
}

export default PageNavbar;
