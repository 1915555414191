import LoginForm from "../components/forms/Login.form";
import { EuiSpacer } from "@elastic/eui";

function LoginPage() {
  return (
    <>
      <EuiSpacer size={"l"} />
      <LoginForm />
    </>
  );
}

export default LoginPage;
