import { connection, loadAuthToken } from "./index";

const BASE_PATH = "/comune";

export function getComuneResourcePath(comuneId, resource) {
  return `${BASE_PATH}/${comuneId}/${resource}`;
}

function create(payload) {
  loadAuthToken();
  return connection.post(`${BASE_PATH}`, { ...payload });
}

function getAll(params = {}) {
  loadAuthToken();
  if (params.query) {
    if (typeof params.query !== "string") {
      params.query = JSON.stringify(params.query);
    }
  }
  if (params.sort) {
    if (typeof params.sort !== "string") {
      params.sort = JSON.stringify(params.sort);
    }
  }

  return connection.get(`${BASE_PATH}`, {
    params: params,
  });
}

function importCsv(importFile, params = {}) {
  loadAuthToken();
  const formData = new FormData();
  formData.append("importFile", importFile);
  return connection.post(`${BASE_PATH}/import`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params,
  });
}

function exportCsv(params = {}) {
  loadAuthToken();
  if (params.query) {
    params.query = JSON.stringify(params.query);
  }
  if (params.sort) {
    params.sort = JSON.stringify(params.sort);
  }

  return connection.get(`${BASE_PATH}/export`, {
    params,
    responseType: "blob",
    headers: { "Content-type": "text/csv,application/octet-stream" },
  });
}

function getById(id) {
  loadAuthToken();
  return connection.get(`${BASE_PATH}/${id}`);
}

function update(id, payload) {
  loadAuthToken();
  return connection.patch(`${BASE_PATH}/${id}`, { ...payload });
}

function remove(id) {
  loadAuthToken();
  return connection.delete(`${BASE_PATH}/${id}`);
}

const comune = {
  create,
  getAll,
  importCsv,
  exportCsv,
  getById,
  update,
  remove,
};

export default comune;
