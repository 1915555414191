import React, { useState } from "react";
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiPopover,
  EuiSpacer,
  EuiText,
  useGeneratedHtmlId,
} from "@elastic/eui";
import { capitalize } from "../../lib/utils";
import { getProfileMenuPages, getLogoutPage } from "../../lib/data/pages";
import { Link } from "react-router-dom";

function getFullUsername(user) {
  if (user.first_name && user.last_name) {
    return `${capitalize(user.first_name)} ${capitalize(user.last_name)}`;
  } else {
    return capitalize(user.username);
  }
}

function UserMenu({ user, logout }) {
  const userPopoverId = useGeneratedHtmlId({ prefix: "userPopover" });
  const [isOpen, setIsOpen] = useState(false);

  const profilePages = getProfileMenuPages();
  const logoutPage = getLogoutPage();

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const userFullName = getFullUsername(user);

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={userPopoverId}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={onMenuButtonClick}
    >
      <EuiAvatar name={userFullName} size="s" />
    </EuiHeaderSectionItemButton>
  );

  const menuPages = profilePages.map((page, index) =>
    index < profilePages.length - 1 ? (
      <span key={index}>
        <Link to={page.url}>
          <EuiLink>{page.label}</EuiLink>
        </Link>
        <EuiSpacer size="s" />
      </span>
    ) : (
      <Link key={index} to={page.url}>
        <EuiLink>{page.label}</EuiLink>
      </Link>
    )
  );

  return (
    <EuiPopover
      id={userPopoverId}
      repositionOnScroll
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={closeMenu}
      panelPaddingSize="none"
    >
      <div style={{ width: 320 }}>
        <EuiFlexGroup
          gutterSize="m"
          className="euiHeaderProfile"
          responsive={false}
          alignItems="center"
        >
          <EuiFlexItem grow={false}>
            <EuiAvatar name={userFullName} size="xl" />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>{userFullName}</p>
            </EuiText>

            <EuiSpacer size="m" />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
                  <EuiFlexItem grow={false}>{menuPages}</EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={logout}>{logoutPage.label}</EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  );
}

export default UserMenu;
