import { useEffect } from "react";
import useGlobalProvider from "../../providers/globalProvider";
import apiClient, { handleApiError } from "../../apiClient";
import { useQuery } from "@tanstack/react-query";

export const USERS_KEY = "users";

export function useUsers(
  { pageSize, page, query, sort, populate, useSpecialQuery },
  useSetLoading = true
) {
  const { setLoading, setMessage, loading } = useGlobalProvider();
  const params = {
    pageSize,
    page,
    query,
    sort,
    populate,
    useSpecialQuery: useSpecialQuery ? "1" : "0",
  };

  const initialData = {
    data: [],
    meta: {
      totalItems: 0,
      currentPage: page,
      pageSize: pageSize,
      totalPages: 0,
      query: {},
      sort: { createdAt: -1 },
    },
  };

  const {
    isLoading,
    isFetching,
    data: users,
  } = useQuery({
    queryKey: [`${USERS_KEY}-list`, params],
    queryFn: fetch,
    placeholderData: initialData,
    staleTime: 60 * 1000, // 1 minute
    keepPreviousData: true,
    enabled: !loading,
    onError: (err) =>
      handleApiError(
        err,
        setMessage,
        "Errore durante il caricamento dei gestori"
      ),
  });

  async function fetch() {
    const { data } = await apiClient.users.getAll(params);
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading || isFetching);
    }
  }, [isLoading, isFetching]);

  return { users, isFetching, isLoading };
}

export function useUser(id, useSetLoading = true, populate = null) {
  const { setLoading, setMessage, loading } = useGlobalProvider();

  const {
    isLoading,
    isFetching,
    fetchStatus,
    data: user,
  } = useQuery({
    queryKey: [`${USERS_KEY}-item`, { id }],
    queryFn: fetch,
    staleTime: 60 * 1000,
    enabled: !!id && !loading,
    onError: (err) =>
      handleApiError(
        err,
        setMessage,
        `Errore durante il caricamento del gestore con id ${id}`
      ),
  });

  async function fetch() {
    const { data } = await apiClient.users.getById(id, {
      populate,
    });
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading((isLoading && fetchStatus !== "idle") || isFetching);
    }
  }, [isLoading, isFetching, fetchStatus]);

  return { user, isFetching, isLoading: isLoading && fetchStatus !== "idle" };
}
