/** @jsxImportSource @emotion/react */
import React from "react";
import { EuiIcon, EuiPageTemplate } from "@elastic/eui";
import { Outlet, useLocation } from "react-router-dom";
import useNavigation from "../lib/providers/navigationProvider";
import AppMessage from "../components/AppMessage";
import useGlobalProvider from "../lib/providers/globalProvider";
import ErrorBanner from "../components/errors/ErrorBanner";
import PageNavbar from "./PageNavbar";
import FullPageLoader from "../components/FullPageLoader";
import { css } from "@emotion/react";
import useApp from "../lib/providers/appProvider";
import InLayoutError from "../components/errors/InLayoutError";
import ErrorBoundaryInternal from "../lib/ErrorService/ErrorBoundaryInternal";
import logoImage from "../assets/images/logo-rettangolare.svg";

function LoginContent({ loading }) {
  return (
    <EuiPageTemplate.EmptyPrompt
      icon={<EuiIcon type={logoImage} size={"original"} />}
    >
      <AppMessage />
      <ErrorBoundaryInternal fallback={InLayoutError}>
        <Outlet />
      </ErrorBoundaryInternal>
      <FullPageLoader visible={loading} />
    </EuiPageTemplate.EmptyPrompt>
  );
}

function StandardContent({ loading, fatalError }) {
  return (
    <EuiPageTemplate.Section restrictWidth>
      <AppMessage />
      {fatalError ? (
        <ErrorBanner title={fatalError.title} body={fatalError.body} />
      ) : (
        <ErrorBoundaryInternal fallback={InLayoutError}>
          <Outlet />
        </ErrorBoundaryInternal>
      )}
      <FullPageLoader visible={loading} />
    </EuiPageTemplate.Section>
  );
}

function ProtectedPageLayout() {
  const { fatalError, loading } = useGlobalProvider();
  const { currentPageInfo, pageHeaderActions } = useNavigation();
  const { manualPageTitle } = useApp();
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const pageTitle = manualPageTitle || currentPageInfo?.label;
  const isFullPage =
    isLoginPage || currentPageInfo?.isFullPage || pageTitle === null;

  return (
    <EuiPageTemplate
      paddingSize={isLoginPage ? "none" : "l"}
      offset={48}
      restrictWidth={!isLoginPage}
    >
      <PageNavbar />
      {isFullPage ? null : (
        <EuiPageTemplate.Header
          restrictWidth
          pageTitle={pageTitle}
          rightSideItems={pageHeaderActions}
          rightSideGroupProps={{ alignItems: "center", gutterSize: "s" }}
          bottomBorder
          css={css`
            background-color: #ffffff !important;
          `}
        />
      )}
      {isLoginPage ? (
        <LoginContent loading={loading} />
      ) : (
        <StandardContent loading={loading} fatalError={fatalError} />
      )}
    </EuiPageTemplate>
  );
}

export default ProtectedPageLayout;
