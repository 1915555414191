/** @jsxImportSource @emotion/react */
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { EuiProvider, EuiContext, transparentize } from "@elastic/eui";
import createCache from "@emotion/cache";
import { Helmet, HelmetProvider } from "react-helmet-async";
import useNavigation from "./navigationProvider";
import { theme } from "../../assets/theme";
import { mappings } from "../data/translations";
import { Global, css } from "@emotion/react";

const cache = createCache({
  key: "dove-riposano-gestionale",
  container: document.querySelector(
    'meta[name="dove-riposano-gestionale-style"]'
  ),
});
cache.compat = true;

export const DEFAULT_TITLE = "Dove Riposano Gestionale";

const initialState = {
  headTitle: DEFAULT_TITLE,
};

const AppContext = createContext({
  headTitle: initialState.headTitle,
});

const themeModifications = {
  ...theme,
};

export const AppProvider = ({ children }) => {
  const [headTitle, setHeadTitle] = useState(initialState.headTitle);
  const { currentPageInfo } = useNavigation();
  const [manualPageTitle, setManualPageTitle] = useState(null);
  const pageTitle = currentPageInfo?.label;
  const i18n = useMemo(
    () => ({
      mapping: mappings["it"],
      render: undefined,
    }),
    []
  );

  /*  useEffect(() => {
    setHeadTitle(`${initialState.headTitle}: ${pageTitle ? pageTitle : ""}`);
  }, [currentPageInfo]);*/

  useEffect(() => {
    setHeadTitle(
      `${initialState.headTitle} - ${manualPageTitle ? manualPageTitle : ""}`
    );
  }, [manualPageTitle]);

  return (
    <HelmetProvider>
      <AppContext.Provider
        value={{
          manualPageTitle,
          setManualPageTitle,
          headTitle,
          setHeadTitle,
        }}
      >
        <Helmet>
          <title>{headTitle}</title>
        </Helmet>
        <Global
          styles={css`
            ::selection {
              background: ${transparentize(
                theme?.selection?.color ? theme.selection.color : "#07C",
                0.1
              )} !important;
            }
            ::-moz-selection {
              background: ${transparentize(
                theme?.selection?.color ? theme.selection.color : "#07C",
                0.1
              )} !important;
            }
          `}
        />
        <EuiContext i18n={i18n}>
          <EuiProvider cache={cache} modify={themeModifications}>
            {children}
          </EuiProvider>
        </EuiContext>
      </AppContext.Provider>
    </HelmetProvider>
  );
};

export default function useApp() {
  return useContext(AppContext);
}
