import AsyncSelectField from "../FormFields/AsyncSelectField";
import { useState } from "react";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useCimiteri } from "../../../lib/hooks/api/cimiteri";
import { useGeneratedHtmlId } from "@elastic/eui";

function CimiteroSearchField({
  field,
  handleOnChange,
  isSingleSelect,
  isClearable = true,
  compressed = false,
  fullWidth = false,
  append = null,
  helpText = null,
  prefix = "",
}) {
  const { currentComuneId } = useGlobalProvider();
  const [searchQuery, setSearchQuery] = useState({});
  const { cimiteri, isFetching, isLoading } = useCimiteri(
    currentComuneId,
    {
      page: 0,
      pageSize: 20,
      query: searchQuery,
      sort: { nome: 1 },
    },
    false
  );

  const handleSearchChange = (searchValue) => {
    setSearchQuery({
      nome: { $regex: ".*" + searchValue + ".*", $options: "i" },
    });
  };

  return (
    <AsyncSelectField
      field={field}
      isSingleSelect={isSingleSelect}
      handleOnChange={handleOnChange}
      handleSearchChange={handleSearchChange}
      options={cimiteri.data.map((cimitero) => ({
        code: cimitero._id,
        label: `${cimitero.nome}`,
      }))}
      isLoading={isLoading || isFetching}
      isClearable={isClearable}
      compressed={compressed}
      fullWidth={fullWidth}
      append={append}
      helpText={helpText}
      prefix={prefix}
    />
  );
}

export default CimiteroSearchField;
