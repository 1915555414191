const set = (token) => {
  localStorage.setItem("token", token);
};

const get = () => {
  return localStorage.getItem("token");
};

const remove = () => {
  localStorage.removeItem("token");
};

const tokenStorage = {
  set,
  get,
  remove,
};

export default tokenStorage;
