import { EuiFormRow, EuiFieldPassword } from "@elastic/eui";
import Label from "./Label";

function PasswordField({
  field,
  handleOnChange,
  fullWidth = false,
  autoComplete = "on",
}) {
  return (
    <EuiFormRow
      label={<Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      isDisabled={field.disabled}
      fullWidth={fullWidth}
    >
      <EuiFieldPassword
        type={"dual"}
        fullWidth={fullWidth}
        value={field.value}
        onChange={(e) => handleOnChange(e)}
        aria-label={field.label.toString()}
        name={field.name}
        isInvalid={field.isInvalid}
        autoComplete={autoComplete}
      />
    </EuiFormRow>
  );
}

export default PasswordField;
