import { BrowserRouter as Router } from "react-router-dom";
import { GlobalProvider } from "./lib/providers/globalProvider";
import { AppProvider } from "./lib/providers/appProvider";
import AppRouter from "./pages/AppRouter";
import { NavigationProvider } from "./lib/providers/navigationProvider";
import { AuthProvider } from "./lib/providers/authProvider";
import "@elastic/eui/dist/eui_theme_light.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ErrorBoundary from "./lib/ErrorService/ErrorBoundary";
import FullPageError from "./components/errors/FullPageError";

const queryClient = new QueryClient();

function App() {
  return (
    <ErrorBoundary fallback={FullPageError}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <GlobalProvider>
            <NavigationProvider>
              <AuthProvider>
                <AppProvider>
                  <AppRouter />
                </AppProvider>
              </AuthProvider>
            </NavigationProvider>
          </GlobalProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
