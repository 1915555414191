import React, { useEffect } from "react";
import { EuiText } from "@elastic/eui";
import { Link } from "react-router-dom";
import useApp from "../lib/providers/appProvider";

function NotFoundPage() {
  const { setManualPageTitle } = useApp();

  useEffect(() => {
    setManualPageTitle(null);
  }, []);

  return (
    <EuiText grow={false}>
      <h3>Pagina non trovata</h3>
      <p>
        <Link to="/">Ritorna alla homepage</Link>
      </p>
    </EuiText>
  );
}

export default NotFoundPage;
