import moment from "moment/moment";
import { useEffect, useState } from "react";
import { EuiDatePicker, EuiFormRow } from "@elastic/eui";
import Label from "./Label";
import { dateToIso } from "../../../lib/utils";
import "moment/locale/it";

function DateField({
  field,
  handleOnChange,
  minDate = moment().subtract(4, "y"),
  maxDate = moment(),
  showTimeSelect = false,
  fullWidth = false,
  compressed = false,
  locale = "it",
}) {
  const [fieldDate, setFieldDate] = useState(
    field.value ? moment(field.value) : null
  );
  const middleYear =
    minDate.year() + Math.floor((maxDate.year() - minDate.year()) / 2);

  const handleChangeInternal = (date) => {
    setFieldDate(date);
    handleOnChange(dateToIso(date));
  };

  useEffect(() => {
    setFieldDate(field.value ? moment(field.value) : null);
  }, [field.value]);

  return (
    <EuiFormRow
      label={<Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      isDisabled={field.disabled}
      fullWidth={fullWidth}
      display={compressed ? "rowCompressed" : "row"}
    >
      <EuiDatePicker
        selected={fieldDate}
        onChange={(date) => handleChangeInternal(date)}
        minDate={minDate}
        maxDate={maxDate}
        aria-label={field.label.toString()}
        placeholder={field.label.toString()}
        showTimeSelect={showTimeSelect}
        dateFormat={"DD/MM/YYYY"}
        locale={locale}
        yearDropdownItemNumber={middleYear}
      />
    </EuiFormRow>
  );
}

export default DateField;
