import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../../lib/providers/authProvider";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useReducer } from "react";
import { tableReducer } from "../reducer";
import {
  queryKeyMappingUpdated,
  useRemoveApiCall,
} from "../../../lib/hooks/api";
import apiClient, {
  downloadFile,
  handleApiError,
} from "../../../lib/apiClient";
import moment from "moment/moment";
import Table from "../Table";
import { isAnyAdmin } from "../../../lib/auth";
import { getCountryLabel } from "../../../lib/data/countries";
import { DEFUNTI_KEY, useDefunti } from "../../../lib/hooks/api/defunti";
import { getDocumentazioneFilename } from "../../../lib/apiClient/defunto";
import { EuiButtonIcon, EuiToolTip } from "@elastic/eui";
import { css } from "@emotion/react";
import { CimiteroTableLink } from "../specialFields";

const tableInitialState = {
  currentPage: 0,
  pageSize: 15,
  sort: { createdAt: -1 },
  useSpecialQuery: false,
};

const defaultVisibleColumns = [
  { field: "_id", visible: false },
  { field: "epiteto", visible: false },
  { field: "nome", visible: true },
  { field: "cognome", visible: true },
  { field: "madre", visible: false },
  { field: "padre", visible: false },
  { field: "codiceFiscale", visible: false },
  { field: "dataNascita", visible: true },
  { field: "luogoNascita", visible: false },
  { field: "statoNascita", visible: false },
  { field: "dataMorte", visible: true },
  { field: "luogoMorte", visible: false },
  { field: "statoMorte", visible: false },
  { field: "agenzia", visible: false },
  { field: "titolare", visible: true },
  { field: "loculo", visible: true },
  { field: "cimitero", visible: true },
  { field: "dataCerimonia", visible: false },
  { field: "luogoCerimonia", visible: false },
  { field: "dataSepoltura", visible: false },
  { field: "dataCremazione", visible: false },
  { field: "dataEsumazione", visible: false },
  { field: "documentazione", visible: false },
  { field: "note", visible: false },
  { field: "createdAt", visible: false },
  { field: "updatedAt", visible: false },
];

const columns = (user) => [
  {
    field: "_id",
    name: "Id",
    "data-test-subj": "codeCell",
    sortable: true,
    truncateText: true,
    enlarge: 1,
    align: "center",
  },
  {
    field: "epiteto",
    name: "Epiteto",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "nome",
    name: "Nome",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "cognome",
    name: "Cognome",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "madre",
    name: "Madre",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "padre",
    name: "Padre",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "codiceFiscale",
    name: "Codice Fiscale",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (cod) => (cod ? cod.toUpperCase() : ""),
  },
  {
    field: "dataNascita",
    name: "Data Nascita",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) =>
      date ? <span>{moment(date).format("DD-MM-YYYY")}</span> : "",
  },
  {
    field: "luogoNascita",
    name: "Luogo Nascita",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "statoNascita",
    name: "Stato Nascita",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (countryCode) => <span>{getCountryLabel(countryCode)}</span>,
  },
  {
    field: "dataMorte",
    name: "Data Morte",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) =>
      date ? <span>{moment(date).format("DD-MM-YYYY")}</span> : "",
  },
  {
    field: "luogoMorte",
    name: "Luogo Morte",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "statoMorte",
    name: "Stato Morte",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (countryCode) => <span>{getCountryLabel(countryCode)}</span>,
  },
  {
    field: "titolare",
    name: "Utente",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (titolare) =>
      titolare ? (
        <EuiToolTip position="top" content={"Vai al titolare"}>
          <Link to={`/utenti/${titolare._id}/modifica`}>
            {titolare.cognome} {titolare.nome}
          </Link>
        </EuiToolTip>
      ) : (
        ""
      ),
  },
  {
    field: "loculo",
    name: "Loculo",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (loculo) =>
      loculo ? (
        <EuiToolTip position="top" content={"Vai al loculo"}>
          <Link to={`/loculi/${loculo._id}/modifica`}>{loculo._id}</Link>
        </EuiToolTip>
      ) : (
        ""
      ),
  },
  {
    field: "cimitero",
    name: "Cimitero",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (cimitero, defunto) => (
      <CimiteroTableLink
        user={user}
        cimitero={defunto.loculo ? defunto.loculo.cimitero : null}
      />
    ),
  },
  {
    field: "agenzia",
    name: "Agenzia",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "dataCerimonia",
    name: "Data Cerimonia",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) =>
      date ? <span>{moment(date).format("DD-MM-YYYY")}</span> : "",
  },
  {
    field: "luogoCerimonia",
    name: "Luogo Cerimonia",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "dataSepoltura",
    name: "Data Sepoltura",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) =>
      date ? <span>{moment(date).format("DD-MM-YYYY")}</span> : "",
  },
  {
    field: "dataCremazione",
    name: "Data Cremazione",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) =>
      date ? <span>{moment(date).format("DD-MM-YYYY")}</span> : "",
  },
  {
    field: "dataEsumazione",
    name: "Data Esumazione",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) =>
      date ? <span>{moment(date).format("DD-MM-YYYY")}</span> : "",
  },
  {
    field: "note",
    name: "Note",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (note) =>
      note ? (
        <span
          css={css`
            width: 200px;
            text-overflow: ellipsis;
            overflow: hidden !important;
            white-space: nowrap;
            display: block;
          `}
        >
          {note}
        </span>
      ) : (
        ""
      ),
  },
  {
    field: "documentazione",
    name: "Fascicolo",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (documentazione, defunto) => {
      if (documentazione) {
        return (
          <EuiToolTip position="top" content={"Scarica documentazione"}>
            <EuiButtonIcon
              iconType="download"
              aria-label="download documentazione"
              onClick={() =>
                downloadFile(
                  () =>
                    apiClient.defunto.downloadDocumentazione(
                      defunto.comune,
                      defunto._id
                    ),
                  getDocumentazioneFilename(defunto),
                  null,
                  ""
                )
              }
            />
          </EuiToolTip>
        );
      } else {
        return "";
      }
    },
  },
  {
    field: "createdAt",
    name: "Creato il",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) => <span>{moment(date).format("DD-MM-YYYY HH:mm:SS")}</span>,
  },
  {
    field: "updatedAt",
    name: "Modificato il",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) => <span>{moment(date).format("DD-MM-YYYY HH:mm:SS")}</span>,
  },
];

function DefuntiTable({ tableQuery }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setMessage, currentComuneId, setLoading } = useGlobalProvider();
  const [tableState, tableDispatcher] = useReducer(
    tableReducer,
    tableInitialState
  );
  const { mutateAsync: removeDefuntoAsync } = useRemoveApiCall(
    apiClient.defunto.remove,
    `${DEFUNTI_KEY}-${currentComuneId}`,
    true,
    queryKeyMappingUpdated(currentComuneId)
  );
  const { defunti, isFetching, isLoading } = useDefunti(
    currentComuneId,
    {
      page: tableState.currentPage,
      pageSize: tableState.pageSize,
      query: tableQuery,
      sort: tableState.sort,
      populate: ["titolare", "loculo", "loculo.cimitero"],
      useSpecialQuery: tableState.useSpecialQuery,
    },
    false
  );

  const handleEditAction = (item) => {
    navigate(`/defunti/${item._id}/modifica`);
  };

  const handleRemoveAction = async (defunti) => {
    let title;
    if (defunti.length > 1) {
      title = `I ${defunti.length} defunti sono stati eliminati con successo`;
    } else {
      title = `Il defunto è stato eliminato con successo`;
    }
    try {
      const promises = defunti.map((defunto) =>
        removeDefuntoAsync([currentComuneId, defunto._id])
      );
      await Promise.all(promises);
      setMessage({
        title: title,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
    } catch (err) {
      handleApiError(
        err,
        setMessage,
        "Errore durante l'eliminazione dei defunti"
      );
    }
  };

  const handleDownload = async () => {
    setLoading(true);
    await downloadFile(
      () =>
        apiClient.defunto.exportCsv(currentComuneId, {
          query: tableQuery,
          sort: tableState.sort,
        }),
      "defunti.csv",
      setMessage,

      "Errore durante la preparazione del file"
    );
    setLoading(false);
  };

  if (!defunti) {
    return null;
  }

  return (
    <Table
      entities={defunti}
      entityName={"Defunto"}
      entitiesName={"Defunti"}
      isLoading={isLoading || isFetching}
      tableColumns={columns(user)}
      tableState={tableState}
      tableDispatcher={tableDispatcher}
      canEdit={true}
      canRemove={isAnyAdmin(user)}
      handleEditAction={handleEditAction}
      handleRemoveAction={handleRemoveAction}
      showDownloadButton={true}
      handleDownload={handleDownload}
      defaultVisibleColumns={defaultVisibleColumns}
      pageSizeOptions={[60, 30, 15]}
      tableLayout="auto"
      sortSpecialFields={{
        titolare: ["titolare.cognome", "titolare.nome"],
        cimitero: "loculo.cimitero.nome",
      }}
      sortSpecialFieldsMapping={{
        "titolare.cognome": "titolare",
        "titolare.nome": "titolare",
        "loculo.cimitero.nome": "cimitero",
      }}
    />
  );
}

export default DefuntiTable;
