import useApp from "../../../lib/providers/appProvider";
import { useEffect, useState } from "react";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { EuiButton, EuiHorizontalRule } from "@elastic/eui";
import useNavigation from "../../../lib/providers/navigationProvider";
import { useNavigate } from "react-router-dom";
import UsersTable from "../../../components/table/pageTables/Users.table";
import useAuth from "../../../lib/providers/authProvider";
import { isSuperAdmin } from "../../../lib/auth";
import UsersToolbar from "../../../components/searchToolbar/pageToolbars/Users.toolbar";
import TableError from "../../../components/errors/TableError";
import ErrorBoundaryInternal from "../../../lib/ErrorService/ErrorBoundaryInternal";

function UsersPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setManualPageTitle } = useApp();
  const { setPageHeaderActions } = useNavigation();
  const { currentComuneId, globalWarning } = useGlobalProvider();
  const [tableQuery, setTableQuery] = useState(
    isSuperAdmin(user) ? {} : { comune: currentComuneId }
  );

  useEffect(() => {
    setManualPageTitle("Gestori");
  }, []);

  const newButton = (
    <EuiButton size="s" fill onClick={() => navigate("/users/nuovo")}>
      Crea nuovo
    </EuiButton>
  );

  useEffect(() => {
    setPageHeaderActions([newButton]);
    return () => setPageHeaderActions([]);
  }, []);

  /*if (globalWarning) {
    return (
      <WarningBanner
        title={globalWarning.title}
        body={globalWarning.body}
        actions={globalWarning.actions}
      />
    );
  }*/

  return (
    <>
      <UsersToolbar tableQuery={tableQuery} setTableQuery={setTableQuery} />
      <EuiHorizontalRule margin="l" />
      <ErrorBoundaryInternal fallback={TableError}>
        <UsersTable tableQuery={tableQuery} />
      </ErrorBoundaryInternal>
    </>
  );
}

export default UsersPage;
