import moment from "moment";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import {
  formReducer,
  handleFieldChange,
  initFormState,
} from "../../forms/formUtils/reducer";
import validator from "validator/es";
import Toolbar from "../Toolbar";
import { useEffect, useReducer, useState } from "react";
import { useLoculi } from "../../../lib/hooks/api/loculi";
import { useLocation } from "react-router-dom";

const searchFields = {
  groups: [
    {
      name: "Generale",
      fields: [
        {
          field: "_id",
          label: "Id",
          type: "select",
          isSingleSelect: false,
          entitySearch: "id",
        },
        {
          field: "cimitero",
          label: "Cimitero",
          type: "select",
          isSingleSelect: false,
          entitySearch: "cimitero",
        },
        {
          field: "tipologia",
          label: "Tipologia",
          type: "select",
          isSingleSelect: false,
          entitySearch: "tipologiaLoculi",
        },
        { field: "latitudine", label: "Latitudine", type: "float" },
        { field: "longitudine", label: "Longitudine", type: "float" },
        {
          field: "area",
          label: "Area",
          type: "select",
          isSingleSelect: false,
          entitySearch: "aree",
        },
        { field: "settore", label: "Settore", type: "string" },
        { field: "lotto", label: "Lotto", type: "string" },
        { field: "fila", label: "Fila", type: "string" },
        { field: "piano", label: "Piano", type: "string" },
        { field: "padiglione", label: "Padiglione", type: "string" },
        { field: "zona", label: "Zona", type: "string" },
        { field: "posto", label: "Posto", type: "string" },
        { field: "localita", label: "Località", type: "string" },
        { field: "blocco", label: "Blocco", type: "string" },
        { field: "note", label: "Note", type: "string" },
        {
          field: "createdAt",
          label: "Creato il",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(10, "y"),
            maxDate: moment(),
          },
        },
        {
          field: "updatedAt",
          label: "Modificato il",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(10, "y"),
            maxDate: moment(),
          },
        },
      ],
    },
    {
      name: "Contratto",
      fields: [
        {
          field: "contratto.titolare",
          label: "Utente",
          type: "select",
          isSingleSelect: false,
          entitySearch: "titolare",
        },
        {
          field: "contratto.dataConcessione",
          label: "Data Concessione",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(50, "y"),
            maxDate: moment(),
          },
        },
        {
          field: "contratto.dataDelibera",
          label: "Data Delibera",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(50, "y"),
            maxDate: moment(),
          },
        },
        {
          field: "contratto.dataContratto",
          label: "Data Contratto",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(50, "y"),
            maxDate: moment(),
          },
        },
        {
          field: "contratto.dataScadenza",
          label: "Data Scadenza",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(50, "y"),
            maxDate: moment().add(100, "y"),
          },
        },
        { field: "contratto.protocollo", label: "Protocollo", type: "string" },
        { field: "contratto.repertorio", label: "Repertorio", type: "string" },
        { field: "contratto.registro", label: "Registro", type: "string" },
        { field: "contratto.pagina", label: "Pagina", type: "string" },
      ],
    },
    {
      name: "Lampada e Defunti",
      fields: [
        {
          field: "defunti",
          label: "Defunti",
          type: "select",
          isSingleSelect: false,
          entitySearch: "defunto",
        },
        {
          field: "lampada",
          label: "Lampada",
          type: "select",
          isSingleSelect: false,
          entitySearch: "lampada",
        },
      ],
    },
  ],
};

const DEFAULT_TYPES_VALUE = {
  string: "",
  select: [],
  dateRange: {},
  int: "",
  float: "",
};

const searchDefaultState = {};

searchFields.groups.forEach((group) =>
  group.fields.forEach((field) => {
    searchDefaultState[field.field] = {
      value: DEFAULT_TYPES_VALUE[field.type],
      label: field.label,
      name: field.field,
      is_required: false,
    };
  })
);

function LoculiToolbar({ tableQuery, setTableQuery }) {
  const location = useLocation();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { currentComuneId, setMessage } = useGlobalProvider();
  const [searchFormData, searchFormDataDispatcher] = useReducer(
    formReducer(searchDefaultState),
    initFormState({
      objData: null,
      isEdit: false,
      defaultState: searchDefaultState,
    })
  );
  const [searchIdQuery, setSearchIdQuery] = useState({});

  const { loculi, isFetching, isLoading } = useLoculi(
    currentComuneId,
    {
      page: 0,
      pageSize: 20,
      query: searchIdQuery,
      sort: {},
    },
    false
  );

  const handleIdSearchChange = (searchValue) => {
    setSearchIdQuery(
      validator.isMongoId(searchValue)
        ? {
            _id: searchValue,
          }
        : {}
    );
  };

  const handleSubmit = (processedQuery) => {
    setTableQuery(processedQuery);
  };

  const handleReset = () => {
    setTableQuery({});
  };

  useEffect(() => {
    if (location.state?.query) {
      if (location.state.query.cimitero && location.state.cimiteroLabel) {
        const cimiteroId = location.state.query.cimitero["$in"][0];
        const cimiteroLabel = location.state.cimiteroLabel;
        handleFieldChange(
          "cimitero",
          [{ code: cimiteroId, label: cimiteroLabel }],
          searchFormDataDispatcher,
          setMessage
        );
        setHasSubmitted(true);
      }
      if (location.state.query.area && location.state.areaLabel) {
        const areaCode = location.state.query.area["$in"][0];
        const areaLabel = location.state.areaLabel;
        handleFieldChange(
          "area",
          [{ code: areaCode, label: areaLabel }],
          searchFormDataDispatcher,
          setMessage
        );
        setHasSubmitted(true);
      }
    }
  }, [location.state]);

  return (
    <Toolbar
      fieldsColumns={4}
      searchFields={searchFields}
      entitiesName={"Loculi"}
      searchFormData={searchFormData}
      searchFormDataDispatcher={searchFormDataDispatcher}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      isIdLoading={isLoading || isFetching}
      handleIdSearchChange={handleIdSearchChange}
      iDOptions={loculi.data.map((loculo) => ({
        code: loculo._id,
        label: loculo._id,
      }))}
      externalHasSubmitted={hasSubmitted}
    />
  );
}

export default LoculiToolbar;
