import { useMutation, useQueryClient } from "@tanstack/react-query";
import useGlobalProvider from "../../providers/globalProvider";
import { useEffect } from "react";
import { COMUNI_KEY } from "./comune";
import { CIMITERI_KEY } from "./cimiteri";
import { UTENTI_KEY } from "./utenti";
import { DEFUNTI_KEY } from "./defunti";
import { LAMPADE_KEY } from "./lampade";
import { LOCULI_KEY } from "./loculi";
import { USERS_KEY } from "./users";

export const QUERY_KEY_MAPPING = {
  comune: COMUNI_KEY,
  cimitero: CIMITERI_KEY,
  utente: UTENTI_KEY,
  defunto: DEFUNTI_KEY,
  lampada: LAMPADE_KEY,
  loculo: LOCULI_KEY,
  user: USERS_KEY,
};

export function getQueryKey(entity, comuneId = null) {
  if (comuneId) {
    return `${QUERY_KEY_MAPPING[entity]}-${comuneId}`;
  } else {
    return QUERY_KEY_MAPPING[entity];
  }
}

export function queryKeyMappingUpdated(comuneId = null) {
  if (comuneId) {
    const newMapping = {};
    for (const [key, value] of Object.entries(QUERY_KEY_MAPPING)) {
      newMapping[key] = `${value}-${comuneId}`;
    }
    return newMapping;
  } else {
    return QUERY_KEY_MAPPING;
  }
}

export function useCreateApiCall(apiCall, queryKey, useSetLoading = true) {
  const { setLoading } = useGlobalProvider();
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (args) => apiCall(...args),
    onSuccess: (data) => {
      // After insert new element, list of entities need to be update
      queryClient.invalidateQueries({ queryKey: `${queryKey}-list` });
    },
  });

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading);
    }
  }, [isLoading]);

  return { isLoading, mutateAsync };
}

export function useUpdateApiCall(
  apiCall,
  queryKey,
  useSetLoading = true,
  invalidateData = true
) {
  const { setLoading } = useGlobalProvider();
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (args) => apiCall(...args),
    onSuccess: (data) => {
      if (invalidateData) {
        const updatedData = data.data;
        // After update we replace the cached value of the updated entity and we invalidate the list
        queryClient.setQueryData(
          [`${queryKey}-item`, { id: updatedData._id }],
          updatedData
        );
        queryClient.invalidateQueries({ queryKey: `${queryKey}-list` });
      }
    },
  });

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading);
    }
  }, [isLoading]);

  return { isLoading, mutateAsync };
}

export function useRemoveApiCall(
  apiCall,
  queryKey,
  useSetLoading = true,
  entityQueryKeyMapping = QUERY_KEY_MAPPING
) {
  const { setLoading } = useGlobalProvider();
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (args) => apiCall(...args),
    onSuccess: (data) => {
      const { removed: entityRemoved, relationshipsRemoved } = data.data;
      queryClient.invalidateQueries({
        queryKey: [`${queryKey}-item`, { id: entityRemoved._id }],
      });
      queryClient.invalidateQueries({ queryKey: `${queryKey}-list` });
      if (relationshipsRemoved) {
        for (const [entity, ids] of Object.entries(relationshipsRemoved)) {
          const entityQueryKey = entityQueryKeyMapping[entity];
          ids.unsetIds.map((id) => {
            queryClient.removeQueries({
              queryKey: [`${entityQueryKey}-item`, { id }],
            });
            queryClient.invalidateQueries({
              queryKey: `${entityQueryKey}-list`,
            });
          });
        }
      }
    },
  });

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading);
    }
  }, [isLoading]);

  return { isLoading, mutateAsync };
}
