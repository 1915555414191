import useApp from "../../../lib/providers/appProvider";
import { useEffect, useState } from "react";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import WarningBanner from "../../../components/WarningBanner";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { EuiButton, EuiHorizontalRule } from "@elastic/eui";
import ImportCsvModal from "../../../components/ImportCsvModal";
import apiClient, { handleApiError } from "../../../lib/apiClient";
import { DEFUNTI_KEY } from "../../../lib/hooks/api/defunti";
import useNavigation from "../../../lib/providers/navigationProvider";
import DefuntiTable from "../../../components/table/pageTables/Defunti.table";
import DefuntiToolbar from "../../../components/searchToolbar/pageToolbars/Defunti.toolbar";
import ErrorBoundaryInternal from "../../../lib/ErrorService/ErrorBoundaryInternal";
import InLayoutError from "../../../components/errors/InLayoutError";
import TableError from "../../../components/errors/TableError";

function DefuntiPage() {
  const navigate = useNavigate();
  const { setPageHeaderActions } = useNavigation();
  const { setManualPageTitle } = useApp();
  const { currentComuneId, globalWarning, setLoading, setMessage, loading } =
    useGlobalProvider();
  const queryClient = useQueryClient();
  const [tableQuery, setTableQuery] = useState({});
  const [showImportCsvModal, setShowImportCsvModal] = useState(false);

  useEffect(() => {
    setManualPageTitle("Defunti");
  }, []);

  const newButton = (
    <EuiButton size="s" fill onClick={() => navigate("/defunti/nuovo")}>
      Crea nuovo
    </EuiButton>
  );

  const importCsvButton = (
    <EuiButton size="s" onClick={() => setShowImportCsvModal(true)}>
      Importa CSV
    </EuiButton>
  );

  const handleCsvImportSuccess = async (csvFile, delimiter = ",") => {
    try {
      setLoading(true);
      const { data } = await apiClient.defunto.importCsv(
        currentComuneId,
        csvFile,
        { delimiter }
      );
      queryClient.invalidateQueries({
        queryKey: `${DEFUNTI_KEY}-${currentComuneId}-list`,
      });
      setMessage({
        title: `Defunti importati con successo`,
        iconType: "document",
        color: "success",
        text: `${data.entityCreated} defunti sono stati importati`,
        timeout: 8000,
      });
    } catch (err) {
      handleApiError(err, setMessage, "Errore durante l'import dei defunti");
    } finally {
      setLoading(false);
      setShowImportCsvModal(false);
    }
  };

  useEffect(() => {
    if (globalWarning) {
      setPageHeaderActions([]);
    } else {
      setPageHeaderActions([newButton, importCsvButton]);
    }
    return () => setPageHeaderActions([]);
  }, [globalWarning]);

  if (globalWarning) {
    return (
      <WarningBanner
        title={globalWarning.title}
        body={globalWarning.body}
        actions={globalWarning.actions}
      />
    );
  }

  return (
    <>
      <DefuntiToolbar tableQuery={tableQuery} setTableQuery={setTableQuery} />
      <EuiHorizontalRule margin="l" />
      <ErrorBoundaryInternal fallback={TableError}>
        <DefuntiTable tableQuery={tableQuery} />
      </ErrorBoundaryInternal>
      <ImportCsvModal
        entitiesName={"Defunti"}
        showModal={showImportCsvModal}
        setShowModal={setShowImportCsvModal}
        handleSuccess={handleCsvImportSuccess}
      />
    </>
  );
}

export default DefuntiPage;
