import { useNavigate } from "react-router-dom";
import useAuth from "../../../lib/providers/authProvider";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useReducer } from "react";
import { tableReducer } from "../reducer";
import {
  queryKeyMappingUpdated,
  useRemoveApiCall,
} from "../../../lib/hooks/api";
import apiClient, {
  downloadFile,
  handleApiError,
} from "../../../lib/apiClient";
import { useUtenti, UTENTI_KEY } from "../../../lib/hooks/api/utenti";
import moment from "moment/moment";
import Table from "../Table";
import { isAnyAdmin } from "../../../lib/auth";
import { getCountryLabel } from "../../../lib/data/countries";

const tableInitialState = {
  currentPage: 0,
  pageSize: 15,
  sort: { createdAt: -1 },
  useSpecialQuery: false,
};

const defaultVisibleColumns = [
  { field: "_id", visible: false },
  { field: "nome", visible: true },
  { field: "cognome", visible: true },
  { field: "codiceFiscale", visible: true },
  { field: "email", visible: true },
  { field: "telefono", visible: true },
  { field: "dataNascita", visible: false },
  { field: "luogoNascita", visible: false },
  { field: "statoNascita", visible: false },
  { field: "indirizzo", visible: false },
  { field: "citta", visible: true },
  { field: "cap", visible: false },
  { field: "provincia", visible: false },
  { field: "stato", visible: false },
  { field: "createdAt", visible: false },
  { field: "updatedAt", visible: false },
];

const columns = [
  {
    field: "_id",
    name: "Id",
    "data-test-subj": "codeCell",
    sortable: true,
    truncateText: true,
    enlarge: 1,
    align: "center",
  },
  {
    field: "nome",
    name: "Nome",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "cognome",
    name: "Cognome",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "codiceFiscale",
    name: "Codice Fiscale",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (cod) => cod.toUpperCase(),
  },
  {
    field: "email",
    name: "Email",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "telefono",
    name: "Telefono",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "dataNascita",
    name: "Data Nascita",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) =>
      date ? <span>{moment(date).format("DD-MM-YYYY")}</span> : "",
  },
  {
    field: "luogoNascita",
    name: "Luogo Nascita",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "statoNascita",
    name: "Stato Nascita",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (countryCode) => <span>{getCountryLabel(countryCode)}</span>,
  },
  {
    field: "indirizzo",
    name: "Indirizzo",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "citta",
    name: "Città",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "cap",
    name: "CAP",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "provincia",
    name: "Provincia",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "stato",
    name: "Stato",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (countryCode) => <span>{getCountryLabel(countryCode)}</span>,
  },
  {
    field: "createdAt",
    name: "Creato il",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) => <span>{moment(date).format("DD-MM-YYYY HH:mm:SS")}</span>,
  },
  {
    field: "updatedAt",
    name: "Modificato il",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) => <span>{moment(date).format("DD-MM-YYYY HH:mm:SS")}</span>,
  },
];

function UtentiTable({ tableQuery }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setMessage, currentComuneId, setLoading } = useGlobalProvider();
  const [tableState, tableDispatcher] = useReducer(
    tableReducer,
    tableInitialState
  );
  const { mutateAsync: removeUtenteAsync } = useRemoveApiCall(
    apiClient.utente.remove,
    `${UTENTI_KEY}-${currentComuneId}`,
    true,
    queryKeyMappingUpdated(currentComuneId)
  );
  const { utenti, isFetching, isLoading } = useUtenti(
    currentComuneId,
    {
      page: tableState.currentPage,
      pageSize: tableState.pageSize,
      query: tableQuery,
      sort: tableState.sort,
      useSpecialQuery: tableState.useSpecialQuery,
    },
    false
  );

  const handleEditAction = (item) => {
    navigate(`/utenti/${item._id}/modifica`);
  };

  const handleRemoveAction = async (utenti) => {
    let title;
    if (utenti.length > 1) {
      title = `I ${utenti.length} utenti sono stati eliminati con successo`;
    } else {
      title = `L'utente è stato eliminato con successo`;
    }
    try {
      const promises = utenti.map((utente) =>
        removeUtenteAsync([currentComuneId, utente._id])
      );
      await Promise.all(promises);
      setMessage({
        title: title,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
    } catch (err) {
      handleApiError(
        err,
        setMessage,
        "Errore durante l'eliminazione degli utenti"
      );
    }
  };

  const handleDownload = async () => {
    setLoading(true);
    await downloadFile(
      () =>
        apiClient.utente.exportCsv(currentComuneId, {
          query: tableQuery,
          sort: tableState.sort,
        }),
      "utenti.csv",
      setMessage,
      "Errore durante la preparazione del file"
    );
    setLoading(false);
  };

  if (!utenti) {
    return null;
  }

  return (
    <Table
      entities={utenti}
      entityName={"Utente"}
      entitiesName={"Utenti"}
      isLoading={isLoading || isFetching}
      tableColumns={columns}
      tableState={tableState}
      tableDispatcher={tableDispatcher}
      canEdit={true}
      canRemove={isAnyAdmin(user)}
      handleEditAction={handleEditAction}
      handleRemoveAction={handleRemoveAction}
      showDownloadButton={true}
      handleDownload={handleDownload}
      defaultVisibleColumns={defaultVisibleColumns}
      pageSizeOptions={[60, 30, 15]}
      tableLayout="auto"
    />
  );
}

export default UtentiTable;
