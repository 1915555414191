import validator from "validator/es";
import { isValidPhoneNumber } from "libphonenumber-js";

export function isRequired(fieldInfo) {
  let isSet;
  if (fieldInfo.is_required) {
    let value = fieldInfo.value;
    if (value) {
      value = value.toString();
      isSet = value.length > 0;
    } else {
      isSet = false;
    }
  } else {
    isSet = true;
  }
  return isSet;
}

export const isRequiredError = (field, value) =>
  `Il campo ${field} è obbligatorio`;

export function isCodiceFiscale(value) {
  return (
    validator.isAlphanumeric(value) &&
    validator.isLength(value, { min: 16, max: 16 })
  );
}

export function isNumberOnly(value) {
  return validator.isNumeric(value, { locale: "it-IT" });
}

export function isInt(value) {
  if (value !== "") {
    return validator.isInt(value);
  } else {
    return true;
  }
}

export function isFloat(value) {
  if (value !== "") {
    return validator.isFloat(value);
  } else {
    return true;
  }
}

export function isPhoneNumber(value) {
  return isValidPhoneNumber(value, "IT");
}

export const isCodiceFiscaleError = (field, value) =>
  `${value} non è un codice fiscale valido per il campo ${field}`;

export function lowerCase(value) {
  return value.toLowerCase();
}

export const errorMessagesMapping = {
  is_required: isRequiredError,
  isCodiceFiscale: isCodiceFiscaleError,
  isMobilePhone: (field, value) =>
    `${value} non è un numero di telefono corretto per il compo ${field}`,
  isPhoneNumber: (field, value) =>
    `${value} non è un numero di telefono corretto per il compo ${field}`,
  isNumberOnly: (field, value) =>
    `${field} può solo contenere numeri e i simboli - o +`,
  isEmail: (field, value) =>
    `${value} non è una email valida per il campo ${field}`,
  isIBAN: (field, value) =>
    `${value} non è un IBAN corretto per il campo ${field}`,
  isStrongPassword: (field, value) =>
    `Il campo  ${field} contiene una password troppo debole`,
  isAlphanumeric: (field, value) =>
    `Il campo ${field} deve contenere solamente lettere o numeri`,
  isPostalCode: (field, value) =>
    `${value} non è codice postale valido per il campo ${field}`,
  isInt: (field, value) =>
    `${value} deve essere un numero intero per il campo ${field}`,
  isFloat: (field, value) =>
    `${value} deve essere un numero decimale per il campo ${field}`,
};

export const customValidators = {
  isCodiceFiscale,
  isNumberOnly,
  isInt,
  isFloat,
  isPhoneNumber,
};

export const customTransforms = {
  lowerCase: lowerCase,
  upperCase: (value) => value.toUpperCase(),
};
