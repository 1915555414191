import { connection, loadAuthToken } from "./index";
import { getComuneResourcePath } from "./comune";

const BASE_PATH = "loculo";

function create(comuneId, payload) {
  loadAuthToken();
  return connection.post(
    `${getComuneResourcePath(comuneId, BASE_PATH)}`,
    payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}

function getAll(comuneId, params = {}) {
  loadAuthToken();
  if (params.query) {
    if (typeof params.query !== "string") {
      params.query = JSON.stringify(params.query);
    }
  }
  if (params.sort) {
    if (typeof params.sort !== "string") {
      params.sort = JSON.stringify(params.sort);
    }
  }

  return connection.get(`${getComuneResourcePath(comuneId, BASE_PATH)}`, {
    params,
  });
}

function importCsv(comuneId, importFile, params = {}) {
  loadAuthToken();
  const formData = new FormData();
  formData.append("importFile", importFile);
  return connection.post(
    `${getComuneResourcePath(comuneId, BASE_PATH)}/import`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params,
    }
  );
}

function exportCsv(comuneId, params = {}) {
  loadAuthToken();
  if (params.query) {
    params.query = JSON.stringify(params.query);
  }
  if (params.sort) {
    params.sort = JSON.stringify(params.sort);
  }

  return connection.get(
    `${getComuneResourcePath(comuneId, BASE_PATH)}/export`,
    {
      params,
      responseType: "blob",
      headers: { "Content-type": "text/csv,application/octet-stream" },
    }
  );
}

function getById(comuneId, id, params = {}) {
  loadAuthToken();
  return connection.get(`${getComuneResourcePath(comuneId, BASE_PATH)}/${id}`, {
    params,
  });
}

function update(comuneId, id, payload, params = {}) {
  loadAuthToken();
  return connection.patch(
    `${getComuneResourcePath(comuneId, BASE_PATH)}/${id}`,
    payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
      params,
    }
  );
}

function updateLampadaAndDefunti(comuneId, id, payload, params = {}) {
  loadAuthToken();
  return connection.patch(
    `${getComuneResourcePath(comuneId, BASE_PATH)}/${id}/lampadaDefunti`,
    { ...payload },
    { params }
  );
}

function remove(comuneId, id) {
  loadAuthToken();
  return connection.delete(
    `${getComuneResourcePath(comuneId, BASE_PATH)}/${id}`
  );
}

function downloadDocumentazione(comuneId, id) {
  loadAuthToken();
  return connection.get(
    `${getComuneResourcePath(comuneId, BASE_PATH)}/${id}/documentazione`,
    {
      responseType: "blob",
    }
  );
}

const loculo = {
  create,
  getAll,
  importCsv,
  exportCsv,
  getById,
  update,
  updateLampadaAndDefunti,
  remove,
  downloadDocumentazione,
};

export function getDocumentazioneFilename(loculo) {
  const parts = loculo.contratto.documentazione.split(".");
  const extension = parts[parts.length - 1];
  return `fascicolo-${loculo.tipologia}_${loculo.contratto.protocollo}-${loculo.contratto.repertorio}-${loculo.contratto.registro}-${loculo.contratto.pagina}.${extension}`;
}

export default loculo;
