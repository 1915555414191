import {
  EuiForm,
  useEuiMaxBreakpoint,
  useEuiMinBreakpoint,
} from "@elastic/eui";
import {
  formReducer,
  handleFieldChange,
  initFormState,
  REDUCER_ACTIONS,
  setFormDataErrors,
} from "./formUtils/reducer";
import { useReducer } from "react";
import UsernameField from "./FormFields/UsernameField";
import { css } from "@emotion/react";
import PasswordField from "./FormFields/PasswordField";
import FormActions from "./formUtils/FormActions";
import { validateFormData } from "../../lib/fieldsValidator";
import useGlobalProvider from "../../lib/providers/globalProvider";
import { useLocation } from "react-router-dom";
import useAuth from "../../lib/providers/authProvider";

const loginDefaultState = {
  username: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Username",
    name: "username",
    is_required: true,
    validators: [],
    transforms: ["trim", "lowerCase"],
  },
  password: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Password",
    name: "password",
    is_required: true,
    validators: [],
    transforms: [],
  },
};

function LoginForm() {
  const { setMessage } = useGlobalProvider();
  const { login } = useAuth();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";
  const [loginState, loginStateDispatcher] = useReducer(
    formReducer(loginDefaultState),
    initFormState({
      objData: null,
      isEdit: false,
      defaultState: loginDefaultState,
    })
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { isValid, errors, dataDto } = validateFormData(loginState);
    if (!isValid) {
      setFormDataErrors(errors, loginStateDispatcher);
      setMessage({
        title: "Errore! Alcuni campi non sono validi",
        color: "danger",
        iconType: "error",
        text: "Correggi i valori inseriti e riprova",
      });
    } else {
      await login(dataDto.username, dataDto.password, from);
    }
  };

  return (
    <EuiForm
      component="form"
      css={css`
        text-align: left;
        ${useEuiMaxBreakpoint("s")} {
          min-width: calc(100vw - 96px);
        }
        ${useEuiMinBreakpoint("s")} {
          min-width: 400px;
        }
      `}
    >
      <UsernameField
        field={loginState.username}
        handleOnChange={(e) =>
          handleFieldChange(
            "username",
            e.target.value,
            loginStateDispatcher,
            setMessage
          )
        }
      />
      <PasswordField
        field={loginState.password}
        handleOnChange={(e) =>
          handleFieldChange(
            "password",
            e.target.value,
            loginStateDispatcher,
            setMessage
          )
        }
      />
      <FormActions
        handleSubmit={handleSubmit}
        submitLabel={"Accedi"}
        showReset={false}
        showRequiredText={true}
      />
    </EuiForm>
  );
}

export default LoginForm;
