import { EuiImage, EuiFormRow, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { getStaticServerFilePath } from "../../../lib/utils";
import { useEffect, useState } from "react";
import InputFileField from "./InputFileField";

function InputImageFieldWithPreview({
  field,
  handleOnChange,
  fieldRef,
  helpText,
  compressed = false,
  fullWidth = false,
  append = null,
  initialValue = null,
  previewSize = "m",
}) {
  const [imagePreview, setImagePreview] = useState(initialValue);

  useEffect(() => {
    if (field.value) {
      if (typeof field.value === "string") {
        setImagePreview(getStaticServerFilePath(field.value));
      } else {
        setImagePreview(URL.createObjectURL(field.value));
      }
    } else {
      setImagePreview(initialValue);
    }
  }, [field.value]);

  return (
    <EuiFormRow fullWidth={fullWidth}>
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={true}>
          <InputFileField
            field={field}
            fullWidth={fullWidth}
            handleOnChange={handleOnChange}
            accept={"image/*"}
            fieldRef={fieldRef}
            helpText={helpText}
            compressed={compressed}
            append={append}
          />
        </EuiFlexItem>
        {imagePreview ? (
          <EuiFlexItem grow={false}>
            <EuiImage size={previewSize} src={imagePreview} alt={field.label} />
          </EuiFlexItem>
        ) : null}
      </EuiFlexGroup>
    </EuiFormRow>
  );
}

export default InputImageFieldWithPreview;
