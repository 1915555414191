import { useNavigate, useParams } from "react-router-dom";
import useApp from "../../../lib/providers/appProvider";
import { useEffect } from "react";
import { useUser } from "../../../lib/hooks/api/users";
import UsersForm from "../../../components/forms/pageForms/Users.form";

function UserPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { setManualPageTitle } = useApp();

  const userId = params?.id || null;
  const isEdit = !!userId;

  const {
    user: userData,
    isLoading,
    isFetching,
  } = useUser(userId, true, ["comune"]);

  useEffect(() => {
    setManualPageTitle(userId ? `Modifica gestore` : "Crea gestore");
  }, []);

  if (isLoading || (isEdit && !userData)) {
    return null;
  }

  return (
    <UsersForm
      userData={userData}
      isEdit={isEdit}
      afterNew={() => navigate("/users")}
      afterEdit={() => navigate("/users")}
    />
  );
}

export default UserPage;
