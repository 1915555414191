/** @jsxImportSource @emotion/react */
import {
  EuiFormRow,
  EuiInputPopover,
  EuiFieldText,
  EuiListGroup,
  EuiButtonIcon,
  EuiSpacer,
  EuiText,
  EuiHorizontalRule,
  EuiListGroupItem,
} from "@elastic/eui";
import Label from "./Label";
import { useEffect, useState } from "react";

function parseItemsArray(items, itemField) {
  if (items.length > 0) {
    if (typeof items[0] === "string") {
      return items;
    } else {
      return items.map((item) => item[itemField]);
    }
  } else {
    return items;
  }
}

function buildItemLabel(item, itemAppendField, field) {
  let label = item;
  if (itemAppendField) {
    const filtered = field.value.filter(
      (val) => val.code === item.toLowerCase()
    );
    if (filtered.length === 1) {
      const value = filtered[0][itemAppendField];
      if (value) {
        if (typeof value === "string") {
          label += " - " + itemAppendField + ": " + value;
        } else {
          label += " - " + itemAppendField + ": " + JSON.stringify(value);
        }
      }
    }
  }
  return label;
}

function ArrayField({
  field,
  handleOnChange,
  helpText,
  itemField = null,
  itemAppendField = null,
  compressed = false,
  fullWidth = false,
  addSpacer = true,
  resetFlag = null,
}) {
  const [items, setItems] = useState(parseItemsArray(field.value, itemField));
  const [newField, setNewField] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const removeItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const addItem = () => {
    setItems([...items, newField]);
    setNewField("");
  };

  const handleInputFocus = () => {
    setIsPopoverOpen(true);
  };

  const closePopOver = () => {
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    handleOnChange(
      items.map((item) => {
        if (itemField) {
          const filtered = field.value.filter(
            (val) => val.code === item.toLowerCase()
          );
          let itemValue = { code: item.toLowerCase(), label: item };
          if (filtered.length === 1) {
            itemValue = {
              ...filtered[0],
              code: item.toLowerCase(),
              label: item,
            };
          }
          return itemValue;
        } else {
          return item;
        }
      })
    );
  }, [items]);

  useEffect(() => {
    setItems(parseItemsArray(field.value, itemField));
  }, [resetFlag]);

  const inputField = (
    <EuiFormRow
      label={<Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      isDisabled={field.disabled}
      fullWidth={fullWidth}
      display={compressed ? "rowCompressed" : "row"}
    >
      <EuiFieldText
        prepend={`Elementi ${items.length}`}
        append={
          <EuiButtonIcon
            iconType="plus"
            onClick={addItem}
            aria-label="Aggiungi elemento"
          />
        }
        compressed={compressed}
        fullWidth={fullWidth}
        value={newField}
        onChange={(e) => setNewField(e.target.value)}
        aria-label={field.label.toString()}
        name={`${field.name}-add`}
        isInvalid={field.isInvalid}
        onFocus={handleInputFocus}
        placeholder={`Inserisci ${field.label.toLowerCase()}`}
      />
    </EuiFormRow>
  );

  return (
    <>
      {addSpacer ? <EuiSpacer size="m" /> : null}
      <EuiInputPopover
        fullWidth={fullWidth}
        input={inputField}
        isOpen={isPopoverOpen}
        closePopover={closePopOver}
        panelPaddingSize="m"
        disableFocusTrap={true}
      >
        <EuiText size="s">
          <strong>Elementi Inseriti</strong>
        </EuiText>
        <EuiHorizontalRule margin="s" />
        <EuiListGroup flush={true} size="s">
          {items.map((item, index) => (
            <EuiListGroupItem
              key={index}
              wrapText={true}
              onClick={() => removeItem(index)}
              label={buildItemLabel(item, itemAppendField, field)}
              iconType="trash"
              iconProps={{ color: "danger" }}
            />
          ))}
        </EuiListGroup>
      </EuiInputPopover>
      <EuiSpacer size="xs" />
      <EuiText size="xs" color="subdued">
        {helpText}
      </EuiText>
      {addSpacer ? <EuiSpacer size="m" /> : null}
    </>
  );
}

export default ArrayField;
