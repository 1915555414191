import { Fragment } from "react";

function Label({ field }) {
  return (
    <Fragment>
      {field.label}
      {field.is_required ? " *" : ""}
    </Fragment>
  );
}

export default Label;
