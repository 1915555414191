import Axios from "axios";
import { parse, stringify } from "qs";
import auth from "./auth";
import users from "./users";
import comune from "./comune";
import cimitero from "./cimitero";
import utente from "./utente";
import tokenStorage from "../tokenStorage";
import defunto from "./defunto";
import loculo from "./loculo";
import lampada from "./lampada";
import { logError } from "../ErrorService/errorLogger";

let urls = {
  development: `${process.env.REACT_APP_API_URL}/api/v1`,
  production: `${process.env.REACT_APP_API_URL}/api/v1`,
};

export const connection = Axios.create({
  baseURL:
    process.env.NODE_ENV === "production" ? urls.production : urls.development,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  paramsSerializer: {
    encode: parse,
    serialize: (params) => stringify(params, { arrayFormat: "brackets" }),
  },
});

export function setAuthToken(token) {
  connection.defaults.headers.common.Authorization = `bearer ${token}`;
}

export function loadAuthToken() {
  if (!connection.defaults.headers.common.Authorization) {
    const token = tokenStorage.get();
    connection.defaults.headers.common.Authorization = `bearer ${token}`;
  }
}

export async function doApiCall(
  apiCall,
  successMessage,
  errorMessage,
  setLoading,
  setMessage,
  successIcon = "check",
  messagesTimeout = 8000,
  showSuccessMessage = true
) {
  try {
    setLoading(true);
    const { data } = await apiCall();
    if (showSuccessMessage) {
      setMessage({
        title: successMessage,
        iconType: successIcon,
        color: "success",
        timeout: messagesTimeout,
      });
    }
    setLoading(false);
    return data;
  } catch (err) {
    handleApiError(err, setMessage, errorMessage, messagesTimeout);
    setLoading(false);
  }
}

export function handleApiError(
  error,
  setMessage,
  errorMessage,
  messagesTimeout = 15000
) {
  logError(error);
  let message = error.message;
  if (error.response) {
    message = error.response.data.message;
  }
  setMessage({
    title: errorMessage,
    iconType: "error",
    color: "danger",
    text: message,
    timeout: messagesTimeout,
  });
}

export async function downloadFile(
  apiCall,
  filename,
  setMessage,
  errorMessage,
  messagesTimeout = 8000
) {
  try {
    const { data, headers } = await apiCall();
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: headers["content-type"],
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (err) {
    if (setMessage) {
      handleApiError(err, setMessage, errorMessage, messagesTimeout);
    } else {
      console.log(err);
    }
  }
}

const apiClient = {
  auth,
  users,
  comune,
  cimitero,
  utente,
  defunto,
  lampada,
  loculo,
};

export default apiClient;
