import { EuiFormRow, EuiComboBox } from "@elastic/eui";
import Label from "./Label";

function SelectField({
  field,
  handleOnChange,
  options,
  isSingleSelect,
  isClearable = true,
  compressed = false,
  fullWidth = false,
  showPlaceholder = true,
  append = null,
  helpText = null,
  isLoading = false,
}) {
  return (
    <EuiFormRow
      label={<Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      isDisabled={field.disabled}
      fullWidth={fullWidth}
      helpText={helpText}
      display={compressed ? "rowCompressed" : "row"}
    >
      <EuiComboBox
        compressed={compressed}
        fullWidth={fullWidth}
        options={options}
        selectedOptions={field.value}
        placeholder={showPlaceholder ? field.label : ""}
        onChange={(e) => handleOnChange(e)}
        aria-label={field.label.toString()}
        name={field.name}
        singleSelection={isSingleSelect ? { asPlainText: true } : false}
        isInvalid={field.isInvalid}
        isClearable={isClearable}
        append={append}
        isLoading={isLoading}
      />
    </EuiFormRow>
  );
}

export default SelectField;
