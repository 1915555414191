import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getPage } from "../data/pages";

const initialState = {
  currentPage: "dashboard",
  currentPageInfo: getPage("dashboard"),
};

const NavigationContext = createContext({
  currentPage: initialState.currentPage,
  currentPageInfo: initialState.currentPageInfo,
});

export const NavigationProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(initialState.currentPage);
  const [currentPageInfo, setCurrentPageInfo] = useState(
    initialState.currentPageInfo
  );
  const [pageHeaderActions, setPageHeaderActions] = useState([]);
  const [isDetailPage, setIsDetailPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setCurrentPage("dashboard");
      window.scrollTo(0, 0);
    } else {
      setCurrentPage(location.pathname.replace("/", ""));
      window.scrollTo(0, 0);
    }
    if (
      location.pathname.includes("nuovo") ||
      location.pathname.includes("modifica")
    ) {
      setIsDetailPage(true);
    } else {
      setIsDetailPage(false);
    }
  }, [location]);

  useEffect(() => {
    const pageInfo = getPage(currentPage);
    setCurrentPageInfo(pageInfo);
  }, [currentPage]);

  return (
    <NavigationContext.Provider
      value={{
        currentPage,
        currentPageInfo,
        setCurrentPage,
        pageHeaderActions,
        setPageHeaderActions,
        isDetailPage,
        setIsDetailPage,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default function useNavigation() {
  return useContext(NavigationContext);
}
