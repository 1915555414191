/** @jsxImportSource @emotion/react */
import moment from "moment/moment";
import { EuiTextColor, EuiToolTip, EuiButtonIcon } from "@elastic/eui";
import { css } from "@emotion/react";
import { mailtoLink } from "../../lib/mailto";
import useGlobalProvider from "../../lib/providers/globalProvider";
import { Link } from "react-router-dom";
import { isSuperAdmin } from "../../lib/auth";

export function dateInScadenza(date) {
  if (date) {
    const momentDate = moment(date);
    const now = moment();
    const difference = momentDate.diff(now, "month", true);
    return difference <= 1;
  }
}

export const DataScadenza = ({ date, titolare, entityName }) => {
  const { currentComune } = useGlobalProvider();

  if (!date) {
    return null;
  }

  const momentDate = moment(date);
  const now = moment();
  const difference = momentDate.diff(now, "month", true);
  let status;
  let color;
  let showNotify = false;
  if (difference > 1) {
    status = "Contratto in corso di validità";
    color = "success";
  } else if (difference <= 1 && difference > 0) {
    status = "Contratto in scadenza, notifica via email il titolare";
    color = "warning";
    showNotify = true;
  } else if (difference < 1) {
    status = "Contratto scaduto, notifica via email il titolare";
    color = "danger";
    showNotify = true;
  }

  const handleClick = (e) => {
    if (showNotify) {
      window.location.href = mailtoLink(
        titolare.email,
        `Scadenza contratto ${entityName.toLowerCase()}`,
        `Gentile ${titolare.nome} ${
          titolare.cognome
        }, le ricordiamo che è in scadenza il contratto n. Xxxxx relativo alla fornitura n.xxxxx. L’importo annuale da versare è di euro xxxx sul cc n ${
          currentComune.iban
        } intestato a ${
          currentComune.intestatario
        } con causale: rinnovo concessione n. Xxxxx del ${momentDate.format(
          "DD-MM-YYYY"
        )}.`
      );
    }
    e.preventDefault();
  };

  const dateRender = (
    <EuiTextColor
      color={color}
      onClick={handleClick}
      css={
        showNotify
          ? css`
              cursor: pointer;
            `
          : null
      }
    >
      {momentDate.format("DD-MM-YYYY")}
    </EuiTextColor>
  );

  return (
    <EuiToolTip content={status} position={"top"}>
      <>
        {dateRender}{" "}
        {showNotify ? (
          <EuiButtonIcon
            color={color}
            css={css`
              margin-left: 8px;
            `}
            iconType={"email"}
            onClick={handleClick}
            aria-label={"Notifica scadenza"}
          />
        ) : null}
      </>
    </EuiToolTip>
  );
};

export const CustomAction = ({
  item,
  name,
  icon,
  onClick,
  enabled,
  color = "primary",
}) => {
  return (
    <EuiToolTip position="top" content={name}>
      <EuiButtonIcon
        color={color}
        iconType={icon}
        disabled={!enabled}
        aria-label={name}
        onClick={() => onClick(item)}
      />
    </EuiToolTip>
  );
};

export function CimiteroTableLink({ user, cimitero }) {
  if (cimitero) {
    if (isSuperAdmin(user)) {
      return (
        <EuiToolTip position="top" content={"Vai al cimitero"}>
          <Link to={`/cimitero/${cimitero._id}/modifica`}>{cimitero.nome}</Link>
        </EuiToolTip>
      );
    } else {
      return cimitero.nome;
    }
  } else {
    return null;
  }
}
