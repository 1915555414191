import { connection, loadAuthToken } from "./index";
import { getComuneResourcePath } from "./comune";

const BASE_PATH = "utente";

function create(comuneId, payload) {
  loadAuthToken();
  return connection.post(`${getComuneResourcePath(comuneId, BASE_PATH)}`, {
    ...payload,
  });
}

function getAll(comuneId, params = {}) {
  loadAuthToken();
  if (params.query) {
    if (typeof params.query !== "string") {
      params.query = JSON.stringify(params.query);
    }
  }
  if (params.sort) {
    if (typeof params.sort !== "string") {
      params.sort = JSON.stringify(params.sort);
    }
  }

  return connection.get(`${getComuneResourcePath(comuneId, BASE_PATH)}`, {
    params,
  });
}

function importCsv(comuneId, importFile, params = {}) {
  loadAuthToken();
  const formData = new FormData();
  formData.append("importFile", importFile);
  return connection.post(
    `${getComuneResourcePath(comuneId, BASE_PATH)}/import`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params,
    }
  );
}

function exportCsv(comuneId, params = {}) {
  loadAuthToken();
  if (params.query) {
    params.query = JSON.stringify(params.query);
  }
  if (params.sort) {
    params.sort = JSON.stringify(params.sort);
  }

  return connection.get(
    `${getComuneResourcePath(comuneId, BASE_PATH)}/export`,
    {
      params,
      responseType: "blob",
      headers: { "Content-type": "text/csv,application/octet-stream" },
    }
  );
}

function getById(comuneId, id) {
  loadAuthToken();
  return connection.get(`${getComuneResourcePath(comuneId, BASE_PATH)}/${id}`);
}

function update(comuneId, id, payload) {
  loadAuthToken();
  return connection.patch(
    `${getComuneResourcePath(comuneId, BASE_PATH)}/${id}`,
    { ...payload }
  );
}

function remove(comuneId, id) {
  loadAuthToken();
  return connection.delete(
    `${getComuneResourcePath(comuneId, BASE_PATH)}/${id}`
  );
}

const utente = {
  create,
  getAll,
  importCsv,
  exportCsv,
  getById,
  update,
  remove,
};

export default utente;
