import { useNavigate, useParams } from "react-router-dom";
import useApp from "../../../lib/providers/appProvider";
import { useEffect } from "react";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useLampada } from "../../../lib/hooks/api/lampade";
import LampadaForm from "../../../components/forms/pageForms/Lampada.form";

function LampadaPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { setManualPageTitle } = useApp();
  const { currentComuneId } = useGlobalProvider();

  const lampadaId = params?.id || null;
  const isEdit = !!lampadaId;

  const {
    lampada: lampadaData,
    isLoading,
    isFetching,
  } = useLampada(currentComuneId, lampadaId, true, [
    "titolare",
    "loculo",
    "cimitero",
  ]);

  useEffect(() => {
    setManualPageTitle(lampadaId ? `Modifica lampada` : "Crea lampada");
  }, []);

  if (isLoading || (isEdit && !lampadaData)) {
    return null;
  }

  return (
    <LampadaForm
      isEdit={isEdit}
      lampadaData={lampadaData}
      afterNew={() => navigate("/lampade")}
      afterEdit={() => navigate("/lampade")}
    />
  );
}

export default LampadaPage;
