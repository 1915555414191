import React, { useEffect } from "react";
import { EuiText } from "@elastic/eui";
import { Link } from "react-router-dom";
import ErrorBanner from "../components/errors/ErrorBanner";
import useApp from "../lib/providers/appProvider";

function ForbiddenPage() {
  const { setManualPageTitle } = useApp();

  useEffect(() => {
    setManualPageTitle(null);
  }, []);

  return (
    <ErrorBanner
      title={"Stai cercando di accedere ad una pagina non consentita"}
      body={
        <EuiText grow={false}>
          <p>
            <Link to="/">Ritorna alla homepage</Link>
          </p>
        </EuiText>
      }
    />
  );
}

export default ForbiddenPage;
