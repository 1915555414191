import { EuiFormRow, EuiComboBox, useGeneratedHtmlId } from "@elastic/eui";
import Label from "./Label";

function AsyncSelectField({
  field,
  handleOnChange,
  handleSearchChange,
  isLoading,
  isSingleSelect,
  options,
  isClearable = true,
  compressed = false,
  fullWidth = false,
  showPlaceholder = true,
  append = null,
  helpText = null,
  prefix = "",
  isDisabled = false,
}) {
  const fieldId = useGeneratedHtmlId({
    prefix,
  });
  const onSearchChange = (searchValue) => {
    handleSearchChange(searchValue);
  };

  return (
    <EuiFormRow
      label={<Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      isDisabled={field.disabled}
      helpText={helpText}
      fullWidth={fullWidth}
      display={compressed ? "rowCompressed" : "row"}
    >
      <EuiComboBox
        compressed={compressed}
        fullWidth={fullWidth}
        options={options}
        async={true}
        selectedOptions={field.value}
        placeholder={showPlaceholder ? field.label : ""}
        onChange={(e) => handleOnChange(e)}
        aria-label={field.label.toString()}
        name={field.name}
        onSearchChange={onSearchChange}
        singleSelection={isSingleSelect ? { asPlainText: true } : false}
        isInvalid={field.isInvalid}
        isClearable={isClearable}
        isLoading={isLoading}
        append={append}
        id={fieldId}
        isDisabled={isDisabled}
      />
    </EuiFormRow>
  );
}

export default AsyncSelectField;
