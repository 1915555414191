/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EuiButton } from "@elastic/eui";

function TableFooterActions({ selectedEntities, handleRemoveAction }) {
  if (selectedEntities.length === 0) {
    return null;
  }

  return (
    <div
      css={css`
        text-align: right;
      `}
    >
      <EuiButton
        color="danger"
        onClick={() => handleRemoveAction(selectedEntities)}
      >
        Elimina selezionati
      </EuiButton>
    </div>
  );
}

export default TableFooterActions;
