import { useNavigate, useParams } from "react-router-dom";
import useApp from "../../../lib/providers/appProvider";
import { useEffect } from "react";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useLoculo } from "../../../lib/hooks/api/loculi";
import LoculoForm from "../../../components/forms/pageForms/LoculoForm";

function LoculoPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { setManualPageTitle } = useApp();
  const { currentComuneId } = useGlobalProvider();

  const loculoId = params?.id || null;
  const isEdit = !!loculoId;

  const {
    loculo: loculoData,
    isLoading,
    isFetching,
  } = useLoculo(currentComuneId, loculoId, true, [
    "contratto.titolare",
    "lampada",
    "cimitero",
    "defunti",
  ]);

  useEffect(() => {
    setManualPageTitle(loculoId ? `Modifica loculo` : "Crea loculo");
  }, []);

  if (isLoading || (isEdit && !loculoData)) {
    return null;
  }

  return (
    <LoculoForm
      isEdit={isEdit}
      loculoData={loculoData}
      afterNew={() => navigate("/loculi")}
      afterEdit={() => navigate("/loculi")}
    />
  );
}

export default LoculoPage;
