import countries from "i18n-iso-countries";
import italian from "i18n-iso-countries/langs/it.json";
import { getCountryCallingCode, isSupportedCountry } from "libphonenumber-js";

countries.registerLocale(italian);

const allCounties = countries.getNames("it", {
  select: "official",
});

export const countriesCodeLabel = Object.keys(allCounties).map(
  (countryCode) => ({ code: countryCode, label: allCounties[countryCode] })
);

export const defaultItalyCodeLabel = {
  code: "IT",
  label: countries.getName("IT", "it"),
};

export const getCodeLabelObject = (code) => ({
  code,
  label: countries.getName(code, "it"),
});

export function getCountryLabel(code) {
  return countries.getName(code, "it");
}

export const countryPhoneCodes = Object.keys(allCounties)
  .filter((countryCode) => isSupportedCountry(countryCode))
  .map((countryCode) => ({
    code: countryCode,
    label: `+${getCountryCallingCode(countryCode)} - ${
      allCounties[countryCode]
    }`,
  }));

export const getPhoneCode = (countryCode) => getCountryCallingCode(countryCode);
export const getPhoneCodeLabel = (countryCode) =>
  `+${getCountryCallingCode(countryCode)} - ${allCounties[countryCode]}`;

export const getPhoneCodeAndLabel = (countryCode) => ({
  code: countryCode,
  label: getPhoneCodeLabel(countryCode),
});

export const phoneFieldToString = (phoneField) =>
  `+${phoneField.code} ${phoneField.number}`;
