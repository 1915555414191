import {
  EuiOutsideClickDetector,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiForm,
  EuiModalFooter,
  EuiButton,
  EuiButtonEmpty,
  htmlIdGenerator,
} from "@elastic/eui";
import { useRef, useState } from "react";
import InputFileField from "./forms/FormFields/InputFileField";
import TextField from "./forms/FormFields/TextField";

function ImportCsvModal({
  showModal,
  setShowModal,
  entitiesName,
  handleSuccess,
}) {
  const [csvField, setCsvField] = useState({
    value: null,
    isInvalid: false,
    errors: [],
    label: "CSV File",
    name: "csvFile",
    is_required: true,
  });
  const [delimiter, setDelimiter] = useState({
    value: ";",
    isInvalid: false,
    errors: [],
    label: "Delimitatore colonne csv",
    name: "delimiter",
    is_required: false,
  });
  const csvRef = useRef();
  const formId = htmlIdGenerator()();

  const closeModal = () => {
    setShowModal(false);
    setCsvField({
      ...csvField,
      value: null,
      isInvalid: false,
      errors: [],
    });
  };

  const handleFileChange = (files) => {
    if (files.length > 0) {
      const parts = files[0].name.split(".");
      const fileExt = parts[parts.length - 1];
      if (fileExt === "csv") {
        setCsvField({
          ...csvField,
          value: files[0],
          errors: [],
          isInvalid: false,
        });
      } else {
        csvRef.current.removeFiles();
      }
    } else {
      setCsvField({
        ...csvField,
        value: null,
        errors: [],
        isInvalid: false,
      });
    }
  };

  const handleDelimiterChange = (e) => {
    setDelimiter({
      ...delimiter,
      value: e.target.value,
      isInvalid: false,
      errors: [],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!csvField.value) {
      setCsvField({
        ...csvField,
        errors: ["Carica un file csv prima di procedere"],
        isInvalid: true,
      });
    } else {
      handleSuccess(csvField.value, delimiter.value);
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <EuiOutsideClickDetector onOutsideClick={closeModal}>
      <EuiModal
        onClose={closeModal}
        initialFocus="[name=csvFile]"
        style={{ width: 500 }}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>Importa {entitiesName}</EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiForm id={formId} component="form">
            <InputFileField
              field={csvField}
              fullWidth={true}
              fieldRef={csvRef}
              handleOnChange={handleFileChange}
              accept={".csv"}
            />

            <TextField
              fullWidth={true}
              field={delimiter}
              handleOnChange={handleDelimiterChange}
            />
          </EuiForm>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty size="s" onClick={closeModal}>
            Annulla
          </EuiButtonEmpty>
          <EuiButton
            size="s"
            type="submit"
            form={formId}
            fill
            onClick={handleSubmit}
          >
            Importa
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOutsideClickDetector>
  );
}

export default ImportCsvModal;
