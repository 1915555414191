/** @jsxImportSource @emotion/react */
import {
  EuiFlexGroup,
  EuiHorizontalRule,
  EuiPopover,
  EuiButton,
  EuiFlexItem,
  EuiText,
  EuiSwitch,
  EuiSpacer,
  useCurrentEuiBreakpoint,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { ACTIONS_LABEL } from "./index";

function columnsToVisibleColumnsObject(columns) {
  const obj = {};
  columns
    .filter((col) => col.name !== ACTIONS_LABEL)
    .forEach((col) => (obj[col.field] = col.visible));
  return obj;
}

function TableToolbar({
  originalColumns,
  setColumns,
  totalEntities,
  entitiesName,
  selectedEntities,
  showDownloadButton,
  handleDownload,
  defaultVisibleColumns,
  compressTable,
  setCompressedTable,
}) {
  const [columnsMenuIsOpen, setColumnsMenuIsOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(
    columnsToVisibleColumnsObject(defaultVisibleColumns)
  );
  const currentBreakpoint = useCurrentEuiBreakpoint();

  const handleColumnsClick = () => {
    setColumnsMenuIsOpen(!columnsMenuIsOpen);
  };

  const closeColumnsMenu = () => {
    setColumnsMenuIsOpen(false);
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns({
      ...visibleColumns,
      [column.field]: !visibleColumns[column.field],
    });
  };

  useEffect(() => {
    const filteredColumns = originalColumns.filter(
      (col) => visibleColumns[col.field] || col.name === ACTIONS_LABEL
    );
    setColumns(filteredColumns);
  }, [visibleColumns]);

  const columnsMenuButton = (
    <EuiButton
      iconSide="right"
      color="text"
      size="s"
      onClick={handleColumnsClick}
      iconType="listAdd"
    >
      Colonne
    </EuiButton>
  );

  return (
    <>
      <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
        <EuiFlexItem grow={false}>
          <EuiText size="s">
            {selectedEntities.length > 0
              ? `${entitiesName} selezionati ${selectedEntities.length}`
              : `${entitiesName} totali ${totalEntities}`}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup
            responsive={false}
            wrap
            gutterSize="s"
            alignItems="center"
            justifyContent={"flexEnd"}
          >
            {!["xs", "s"].includes(currentBreakpoint) ? (
              <EuiFlexItem grow={false}>
                <EuiButton
                  iconSide="right"
                  color="text"
                  size="s"
                  onClick={() => setCompressedTable(!compressTable)}
                  iconType={
                    compressTable ? "tableDensityNormal" : "tableDensityCompact"
                  }
                >
                  {compressTable ? "Espandi" : "Comprimi"}
                </EuiButton>
              </EuiFlexItem>
            ) : null}
            <EuiFlexItem grow={false}>
              {/* TODO Refactor to EuiContextMenu*/}
              <EuiPopover
                button={columnsMenuButton}
                isOpen={columnsMenuIsOpen}
                closePopover={closeColumnsMenu}
                panelPaddingSize="s"
              >
                <div
                  css={css`
                    min-width: 160px;
                  `}
                >
                  {originalColumns
                    .filter((col) => col.name !== ACTIONS_LABEL)
                    .map((col, index) => (
                      <Fragment key={index}>
                        <EuiFlexGroup
                          justifyContent="flexStart"
                          alignItems="center"
                          gutterSize="none"
                        >
                          <EuiFlexItem grow={false}>
                            <EuiSwitch
                              label=""
                              className="euiSwitch--mini"
                              checked={visibleColumns[col.field]}
                              onChange={() => toggleColumnVisibility(col)}
                              compressed={true}
                            />
                          </EuiFlexItem>
                          <EuiFlexItem grow={true}>
                            <span
                              onClick={() => toggleColumnVisibility(col)}
                              css={css`
                                cursor: pointer;
                              `}
                            >
                              {col.name}
                            </span>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiSpacer size="s" />
                      </Fragment>
                    ))}
                </div>
              </EuiPopover>
            </EuiFlexItem>
            {showDownloadButton ? (
              <EuiFlexItem grow={false}>
                <EuiButton
                  iconSide="right"
                  color="text"
                  size="s"
                  onClick={handleDownload}
                  iconType="download"
                >
                  Scarica
                </EuiButton>
              </EuiFlexItem>
            ) : null}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule margin="s" />
    </>
  );
}

export default TableToolbar;
