import { useParams } from "react-router-dom";
import useApp from "../../../lib/providers/appProvider";
import { useEffect } from "react";
import { useCimitero } from "../../../lib/hooks/api/cimiteri";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import CimiteroForm from "../../../components/forms/pageForms/Cimitero.form";
import useAuth from "../../../lib/providers/authProvider";
import { isSuperAdmin } from "../../../lib/auth";
import ErrorBanner from "../../../components/errors/ErrorBanner";

function CimiteroPage() {
  const { user } = useAuth();
  const params = useParams();
  const { setManualPageTitle } = useApp();
  const { currentComuneId } = useGlobalProvider();

  const cimiteroId = params?.id || null;
  const isEdit = !!cimiteroId;

  const {
    cimitero: cimiteroData,
    isLoading,
    isFetching,
  } = useCimitero(currentComuneId, cimiteroId, true);

  useEffect(() => {
    setManualPageTitle(cimiteroId ? `Modifica cimitero` : "Crea cimitero");
  }, []);

  if (isLoading || (isEdit && !cimiteroData)) {
    return null;
  }

  if (!isSuperAdmin(user) && !isEdit) {
    return (
      <ErrorBanner
        title={"Accesso alla pagina non autorizzato"}
        body={
          "Accesso alla pagina non autorizzato. Il tuo utente non può accedere a questo contenuto."
        }
      />
    );
  }

  return <CimiteroForm cimiteroData={cimiteroData} isEdit={isEdit} />;
}

export default CimiteroPage;
