/** @jsxImportSource @emotion/react */
import {
  EuiAccordion,
  EuiPanel,
  useGeneratedHtmlId,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon,
  EuiTitle,
  EuiForm,
  EuiHorizontalRule,
  EuiFlexGrid,
  EuiSpacer,
  EuiButton,
} from "@elastic/eui";
import { handleFormReset } from "../../forms/formUtils/reducer";
import FormActions from "../../forms/formUtils/FormActions";
import { isEmpty } from "../../../lib/utils";
import ComponentFromField from "./ComponentFromField";
import { useEffect, useState } from "react";
import { css } from "@emotion/react";

export const FieldInputValueFunctions = {
  string: (val) => ({ $regex: ".*" + val + ".*", $options: "i" }),
  int: (val) => parseInt(val),
  float: (val) => parseFloat(val),
  dateRange: (val) => val,
  select: (values) => ({ $in: values.map((val) => val.code) }),
};

function Toolbar({
  entitiesName,
  handleSubmit,
  handleReset,
  searchFields,
  searchFormData,
  searchFormDataDispatcher,
  handleIdSearchChange,
  isIdLoading,
  iDOptions,
  fieldsColumns = 3,
  externalHasSubmitted = false,
}) {
  const [forceAccordionState, setAccordionForceState] = useState("closed");
  const [hasSubmitted, setHasSubmitted] = useState(externalHasSubmitted);

  const handleSubmitInternal = (e) => {
    e.preventDefault();
    const processedQuery = {};
    searchFields.groups.map((group) =>
      group.fields.map((field) => {
        const value = searchFormData[field.field].value;
        if (field.type === "dateRange" && !isEmpty(value)) {
          processedQuery[field.field] =
            FieldInputValueFunctions[field.type](value);
        } else {
          if (value.length > 0) {
            processedQuery[field.field] =
              FieldInputValueFunctions[field.type](value);
          }
        }
      })
    );
    setAccordionForceState("closed");
    setHasSubmitted(true);
    handleSubmit(processedQuery);
  };

  const handleResetInternal = () => {
    handleFormReset(null, false, searchFormDataDispatcher);
    setAccordionForceState("closed");
    setHasSubmitted(false);
    handleReset();
  };

  useEffect(() => {
    setHasSubmitted(externalHasSubmitted);
  }, [externalHasSubmitted]);

  return (
    <ToolbarWrapper
      entitiesName={entitiesName}
      forceAccordionState={forceAccordionState}
      setAccordionForceState={setAccordionForceState}
      showReset={hasSubmitted}
      onReset={handleResetInternal}
    >
      <EuiSpacer size="l" />
      <EuiPanel
        color="plain"
        paddingSize="none"
        hasShadow={false}
        hasBorder={false}
      >
        <EuiForm component="form" onSubmit={handleSubmitInternal}>
          {searchFields.groups.map((group, groupIndex) => (
            <FormSearchGroup
              key={groupIndex}
              groupIndex={groupIndex}
              groupsCount={searchFields.groups.length}
              title={group.name}
            >
              <EuiFlexGrid gutterSize="l" columns={fieldsColumns}>
                {group.fields.map((field, fieldIndex) => (
                  <EuiFlexItem key={fieldIndex} grow={true}>
                    <ComponentFromField
                      field={field}
                      inputField={searchFormData[field.field]}
                      key={fieldIndex}
                      fullWidth={true}
                      formDataDispatcher={searchFormDataDispatcher}
                      props={field.props ? field.props : {}}
                      handleSearchChange={handleIdSearchChange}
                      isLoading={isIdLoading}
                      options={iDOptions}
                    />
                  </EuiFlexItem>
                ))}
              </EuiFlexGrid>
            </FormSearchGroup>
          ))}
          <FormActions
            spacerSize="s"
            buttonSize="s"
            handleSubmit={() => {}}
            handleReset={handleResetInternal}
            showReset={true}
            submitLabel={"Filtra"}
            showRequiredText={false}
          />
        </EuiForm>
      </EuiPanel>
    </ToolbarWrapper>
  );
}

function FormSearchGroup({ title, groupIndex, groupsCount, children }) {
  return (
    <div>
      {title.length > 0 ? (
        <>
          <EuiText>
            <h5>{title}</h5>
          </EuiText>
          <EuiSpacer size={"l"} />
        </>
      ) : null}
      {children}
      {groupIndex + 1 < groupsCount ? <EuiHorizontalRule margin="l" /> : null}
    </div>
  );
}

function ToolbarWrapper({
  entitiesName,
  children,
  forceAccordionState,
  setAccordionForceState,
  showReset,
  onReset,
}) {
  const searchToolbarId = useGeneratedHtmlId({ prefix: "searchToolbarId" });

  const onToggle = (isOpen) => {
    const newState = isOpen ? "open" : "closed";
    setAccordionForceState(newState);
  };

  const buttonContent = (
    <div>
      <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiIcon type="filterInCircle" size="m" />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiTitle size="xs">
            <h3>Filtra e ricerca {entitiesName}</h3>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );

  return (
    <EuiAccordion
      id={searchToolbarId}
      buttonContent={buttonContent}
      arrowDisplay="right"
      forceState={forceAccordionState}
      onToggle={onToggle}
      extraAction={
        showReset && forceAccordionState === "closed" ? (
          <EuiButton
            size={"s"}
            type="reset"
            onClick={onReset}
            css={css`
              margin-right: 8px;
            `}
          >
            Azzera
          </EuiButton>
        ) : null
      }
    >
      {children}
    </EuiAccordion>
  );
}

export default Toolbar;
