/** @jsxImportSource @emotion/react */
import {
  EuiFormRow,
  EuiFieldText,
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
} from "@elastic/eui";
import Label from "./Label";
import { css } from "@emotion/react";
import {
  countryPhoneCodes,
  getPhoneCodeAndLabel,
} from "../../../lib/data/countries";
import { useEffect, useState } from "react";
import { getCountryCallingCode } from "libphonenumber-js";
import useUpdateEffect from "../../../lib/hooks/customHooks";

function PhoneField({
  field,
  handleOnChange,
  reset,
  compressed = false,
  fullWidth = false,
  type = "text",
  showPlaceholder = true,
  defaultLocale = "IT",
}) {
  const [countryCallingCode, setCountryCallingCode] = useState(
    field.value.code !== ""
      ? field.value.code
      : getCountryCallingCode(defaultLocale)
  );
  const [phoneCountryCode, setPhoneCountryCode] = useState(
    field.value.countryCode !== "" ? field.value.countryCode : defaultLocale
  );

  useEffect(() => {
    handleOnChange({
      ...field.value,
      code: countryCallingCode,
    });
  }, [countryCallingCode]);

  useUpdateEffect(() => {
    setCountryCallingCode(
      field.value.code !== ""
        ? field.value.code
        : getCountryCallingCode(defaultLocale)
    );
    setPhoneCountryCode(
      field.value.countryCode !== "" ? field.value.countryCode : defaultLocale
    );
  }, [reset]);

  const handleChangeInternal = (e) => {
    handleOnChange({
      code: countryCallingCode,
      number: e.target.value,
    });
  };

  return (
    <EuiFormRow
      fullWidth={fullWidth}
      label={<Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      isDisabled={field.disabled}
    >
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem grow={false}>
          <PhoneCodeSelector
            countryCallingCode={countryCallingCode}
            setCountryCallingCode={setCountryCallingCode}
            phoneCountryCode={phoneCountryCode}
            setPhoneCountryCode={setPhoneCountryCode}
            name={field.name}
            isInvalid={field.isInvalid}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiFormRow fullWidth={true}>
            <EuiFieldText
              compressed={compressed}
              fullWidth={true}
              value={field.value.number}
              placeholder={showPlaceholder ? field.label : ""}
              onChange={(e) => handleChangeInternal(e)}
              aria-label={field.label.toString()}
              name={field.name}
              isInvalid={field.isInvalid}
              type={type}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFormRow>
  );
}

function PhoneCodeSelector({
  countryCallingCode,
  setCountryCallingCode,
  phoneCountryCode,
  setPhoneCountryCode,
  name,
  isInvalid,
}) {
  const handleChange = (selectedOptions) => {
    if (selectedOptions.length > 0) {
      setPhoneCountryCode(selectedOptions[0].code);
      setCountryCallingCode(getCountryCallingCode(selectedOptions[0].code));
    }
  };

  return (
    <EuiComboBox
      css={css`
        width: 220px;
      `}
      selectedOptions={[getPhoneCodeAndLabel(phoneCountryCode)]}
      onChange={handleChange}
      options={countryPhoneCodes}
      aria-label={"Prefisso telefono"}
      name={`prefisso-${name}`}
      singleSelection={{ asPlainText: true }}
      isClearable={false}
      isInvalid={isInvalid}
    />
  );
}

export default PhoneField;
