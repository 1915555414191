import { EuiButton, EuiHorizontalRule } from "@elastic/eui";
import ComuniTable from "../../../components/table/pageTables/Comuni.table";
import { useEffect, useState } from "react";
import useNavigation from "../../../lib/providers/navigationProvider";
import { useNavigate } from "react-router-dom";
import ImportCsvModal from "../../../components/ImportCsvModal";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useQueryClient } from "@tanstack/react-query";
import { COMUNI_KEY } from "../../../lib/hooks/api/comune";
import apiClient, { handleApiError } from "../../../lib/apiClient";
import ComuniToolbar from "../../../components/searchToolbar/pageToolbars/Comuni.toolbar";
import useApp from "../../../lib/providers/appProvider";
import TableError from "../../../components/errors/TableError";
import ErrorBoundaryInternal from "../../../lib/ErrorService/ErrorBoundaryInternal";

function ComuniPage() {
  const { setManualPageTitle } = useApp();
  const { setPageHeaderActions } = useNavigation();
  const { setLoading, setMessage } = useGlobalProvider();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [tableQuery, setTableQuery] = useState({});
  const [showImportCsvModal, setShowImportCsvModal] = useState(false);

  useEffect(() => {
    setManualPageTitle("Comuni");
  }, []);

  const newComuneButton = (
    <EuiButton size="s" fill onClick={() => navigate("/comuni/nuovo")}>
      Crea nuovo
    </EuiButton>
  );

  const importCsvButton = (
    <EuiButton size="s" onClick={() => setShowImportCsvModal(true)}>
      Importa CSV
    </EuiButton>
  );

  const handleCsvImportSuccess = async (csvFile, delimiter = ",") => {
    try {
      setLoading(true);
      const { data } = await apiClient.comune.importCsv(csvFile, { delimiter });
      queryClient.invalidateQueries({ queryKey: `${COMUNI_KEY}-list` });
      setMessage({
        title: `Comuni importati con successo`,
        iconType: "document",
        color: "success",
        text: `${data.entityCreated} comuni sono stati importati`,
        timeout: 8000,
      });
    } catch (err) {
      handleApiError(err, setMessage, "Errore durante l'import dei comuni");
    } finally {
      setLoading(false);
      setShowImportCsvModal(false);
    }
  };

  useEffect(() => {
    setPageHeaderActions([newComuneButton, importCsvButton]);
    return () => setPageHeaderActions([]);
  }, []);

  return (
    <>
      <ComuniToolbar tableQuery={tableQuery} setTableQuery={setTableQuery} />
      <EuiHorizontalRule margin="l" />
      <ErrorBoundaryInternal fallback={TableError}>
        <ComuniTable tableQuery={tableQuery} />
      </ErrorBoundaryInternal>
      <ImportCsvModal
        entitiesName={"Comuni"}
        showModal={showImportCsvModal}
        setShowModal={setShowImportCsvModal}
        handleSuccess={handleCsvImportSuccess}
      />
    </>
  );
}

export default ComuniPage;
