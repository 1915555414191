export const theme = {
  colors: {
    LIGHT: {
      primary: "#f4c900",
      warning: "#fe9514",
    },
  },
  focus: {
    color: "#f4c900",
  },
  selection: {
    color: "#07C",
  },
};
