import useGlobalProvider from "../../../../lib/providers/globalProvider";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
} from "@elastic/eui";
import { handleFieldChange } from "../../formUtils/reducer";
import moment from "moment/moment";
import DateField from "../../FormFields/DateField";
import TextField from "../../FormFields/TextField";
import TitolareSearchField from "../../entitiesSearchFields/Titolare.search-field";
import apiClient from "../../../../lib/apiClient";
import { getDocumentazioneFilename } from "../../../../lib/apiClient/loculo";
import InputWithControlFileField from "../../FormFields/InputWithControlFileField";
import { useEffect, useState } from "react";
import UtenteModalForm from "../../modalForms/UtenteModal.form";

function LoculoContrattoFormView({
  loculoData,
  formData,
  formDataDispatcher,
  documentazioneRef,
  isEdit,
  showDocumentazioneViewer,
  setShowDocumentazioneViewer,
}) {
  const { setMessage, currentComuneId, setLoading } = useGlobalProvider();

  const [showTitolareModal, setShowTitolareModal] = useState(false);

  const handleNewTitolareClick = () => {
    setShowTitolareModal(!showTitolareModal);
  };

  const handleTitolareModalSuccess = (utente) => {
    setShowTitolareModal(false);
    handleFieldChange(
      "contratto.titolare",
      [
        {
          code: utente._id,
          label: `${utente.cognome} ${utente.nome} - ${utente.codiceFiscale}`,
        },
      ],
      formDataDispatcher,
      setMessage
    );
    setLoading(false);
  };

  return (
    <>
      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <DateField
              field={formData["contratto.dataConcessione"]}
              fullWidth={true}
              handleOnChange={(val) =>
                handleFieldChange(
                  "contratto.dataConcessione",
                  val,
                  formDataDispatcher,
                  setMessage
                )
              }
              minDate={moment().subtract(50, "y")}
              maxDate={moment().add(10, "y")}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <DateField
              field={formData["contratto.dataDelibera"]}
              fullWidth={true}
              handleOnChange={(val) =>
                handleFieldChange(
                  "contratto.dataDelibera",
                  val,
                  formDataDispatcher,
                  setMessage
                )
              }
              minDate={moment().subtract(50, "y")}
              maxDate={moment().add(10, "y")}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <DateField
              field={formData["contratto.dataContratto"]}
              fullWidth={true}
              handleOnChange={(val) =>
                handleFieldChange(
                  "contratto.dataContratto",
                  val,
                  formDataDispatcher,
                  setMessage
                )
              }
              minDate={moment().subtract(50, "y")}
              maxDate={moment().add(10, "y")}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <DateField
              field={formData["contratto.dataScadenza"]}
              fullWidth={true}
              handleOnChange={(val) =>
                handleFieldChange(
                  "contratto.dataScadenza",
                  val,
                  formDataDispatcher,
                  setMessage
                )
              }
              minDate={moment().subtract(50, "y")}
              maxDate={moment().add(100, "y")}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <TextField
              field={formData["contratto.protocollo"]}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "contratto.protocollo",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData["contratto.repertorio"]}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "contratto.repertorio",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData["contratto.registro"]}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "contratto.registro",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData["contratto.pagina"]}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "contratto.pagina",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiHorizontalRule size="half" margin="l" />

      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <InputWithControlFileField
              field={formData["contratto.documentazione"]}
              fullWidth={true}
              handleOnChange={(file) =>
                handleFieldChange(
                  "contratto.documentazione",
                  file[0],
                  formDataDispatcher,
                  setMessage
                )
              }
              fieldRef={documentazioneRef}
              showPopulated={showDocumentazioneViewer}
              setShowPopulated={setShowDocumentazioneViewer}
              downloadApiCall={
                isEdit && loculoData?.contratto?.documentazione
                  ? () =>
                      apiClient.loculo.downloadDocumentazione(
                        currentComuneId,
                        loculoData._id
                      )
                  : () => {}
              }
              filename={
                isEdit && loculoData?.contratto?.documentazione
                  ? getDocumentazioneFilename(loculoData)
                  : ""
              }
              fileExistsLabel={"Gestisci fascicolo precedentemente caricato"}
              helpText={
                "Inserire in una cartella tutta la documentazione, comprimerla in file .zip e caricarla. Per aggiungere altri file, ripetere la procedura."
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TitolareSearchField
              field={formData["contratto.titolare"]}
              fullWidth={true}
              isSingleSelect={true}
              handleOnChange={(values) =>
                handleFieldChange(
                  "contratto.titolare",
                  values,
                  formDataDispatcher,
                  setMessage
                )
              }
              append={
                <EuiButton onClick={handleNewTitolareClick} color="text">
                  Crea nuovo
                </EuiButton>
              }
              helpText={
                "Seleziona l'utente del loculo, oppure creane uno nuovo."
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <UtenteModalForm
        entityName="Utente"
        isModalVisible={showTitolareModal}
        setIsModalVisible={setShowTitolareModal}
        afterSuccessAction={handleTitolareModalSuccess}
      />
    </>
  );
}

export default LoculoContrattoFormView;
