/** @jsxImportSource @emotion/react */
import {
  EuiImage,
  EuiText,
  useEuiTheme,
  useEuiShadow,
  EuiToolTip,
} from "@elastic/eui";
import { getStaticServerFilePath } from "../../lib/utils";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

function CimiteroImage({ cimiteroData }) {
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <EuiImage
        alt={cimiteroData.nome}
        src={getStaticServerFilePath(cimiteroData.immagine)}
      />
      {cimiteroData.aree
        .filter((area) => area.coordinate)
        .map((area, index) => (
          <AreaPoint
            key={index}
            coordinate={area.coordinate}
            label={area.label}
            cimiteroData={cimiteroData}
          />
        ))}
    </div>
  );
}

function AreaPoint({ coordinate, label, cimiteroData }) {
  const { euiTheme } = useEuiTheme();
  const navigate = useNavigate();

  const handleAreaClick = () => {
    navigate("/loculi", {
      state: {
        areaLabel: `${label} - ${cimiteroData.nome}`,
        cimiteroLabel: cimiteroData.nome,
        query: {
          area: { $in: [label] },
          cimitero: { $in: [cimiteroData._id] },
        },
      },
    });
  };

  return (
    <div
      css={css`
        position: absolute;
        top: ${coordinate.y}%;
        left: ${coordinate.x}%;
        cursor: pointer;
        background-color: ${euiTheme.colors.primary};
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        ${useEuiShadow("xl")}
        border: ${euiTheme.border.thin};
      `}
      onClick={handleAreaClick}
    >
      <EuiToolTip position="top" content={`Vai ai loculi dell'area ${label}`}>
        <EuiText color={"primary"}>
          <h4>{label}</h4>
        </EuiText>
      </EuiToolTip>
    </div>
  );
}

export default CimiteroImage;
