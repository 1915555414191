/** @jsxImportSource @emotion/react */
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { LAMPADE_KEY, useLampade } from "../../../lib/hooks/api/lampade";
import { getDocumentazioneFilename } from "../../../lib/apiClient/lampada";
import { EuiButtonIcon, EuiToolTip } from "@elastic/eui";
import apiClient, {
  downloadFile,
  handleApiError,
} from "../../../lib/apiClient";
import useAuth from "../../../lib/providers/authProvider";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useReducer } from "react";
import { tableReducer } from "../reducer";
import {
  queryKeyMappingUpdated,
  useRemoveApiCall,
} from "../../../lib/hooks/api";
import { isAnyAdmin } from "../../../lib/auth";
import Table from "../Table";
import { css } from "@emotion/react";
import { CimiteroTableLink, DataScadenza } from "../specialFields";

const tableInitialState = {
  currentPage: 0,
  pageSize: 15,
  sort: { createdAt: -1 },
  useSpecialQuery: false,
};

const defaultVisibleColumns = [
  { field: "_id", visible: true },
  { field: "tipologia", visible: true },
  { field: "numeroContratto", visible: true },
  { field: "statoFornitura", visible: false },
  { field: "dataAssegnazione", visible: false },
  { field: "dataDelibera", visible: false },
  { field: "dataScadenza", visible: true },
  { field: "titolare", visible: true },
  { field: "loculo", visible: true },
  { field: "cimitero", visible: true },
  { field: "documentazione", visible: false },
  { field: "note", visible: false },
  { field: "createdAt", visible: false },
  { field: "updatedAt", visible: false },
];

const columns = (user) => [
  {
    field: "_id",
    name: "Id",
    "data-test-subj": "codeCell",
    sortable: true,
    truncateText: true,
    enlarge: 1,
    align: "center",
  },
  {
    field: "tipologia",
    name: "Tipologia",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "numeroContratto",
    name: "Numero Contratto",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "statoFornitura",
    name: "Stato Fornitura",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "dataAssegnazione",
    name: "Data Assegnazione",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) =>
      date ? <span>{moment(date).format("DD-MM-YYYY")}</span> : "",
  },
  {
    field: "dataDelibera",
    name: "Data Delibera",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) =>
      date ? <span>{moment(date).format("DD-MM-YYYY")}</span> : "",
  },
  {
    field: "dataScadenza",
    name: "Data Scadenza",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date, lampada) =>
      date ? (
        <DataScadenza
          date={date}
          titolare={lampada?.titolare}
          entityName={"Lampada"}
        />
      ) : (
        ""
      ),
  },
  {
    field: "titolare",
    name: "Utente",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (titolare) =>
      titolare ? (
        <EuiToolTip position="top" content={"Vai al titolare"}>
          <Link to={`/utenti/${titolare._id}/modifica`}>
            {titolare.cognome} {titolare.nome}
          </Link>
        </EuiToolTip>
      ) : (
        ""
      ),
  },
  {
    field: "loculo",
    name: "Loculo",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (loculo) =>
      loculo ? (
        <EuiToolTip position="top" content={"Vai al loculo"}>
          <Link to={`/loculi/${loculo._id}/modifica`}>{loculo._id}</Link>
        </EuiToolTip>
      ) : (
        ""
      ),
  },
  {
    field: "cimitero",
    name: "Cimitero",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (cimitero) =>
      cimitero ? <CimiteroTableLink user={user} cimitero={cimitero} /> : "",
  },
  {
    field: "documentazione",
    name: "Fascicolo",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (documentazione, lampada) => {
      if (documentazione) {
        return (
          <EuiToolTip position="top" content={"Scarica documentazione lampada"}>
            <EuiButtonIcon
              iconType="download"
              aria-label="download documentazione"
              onClick={() =>
                downloadFile(
                  () =>
                    apiClient.lampada.downloadDocumentazione(
                      lampada.comune,
                      lampada._id
                    ),
                  getDocumentazioneFilename(lampada),
                  null,
                  ""
                )
              }
            />
          </EuiToolTip>
        );
      } else {
        return "";
      }
    },
  },
  {
    field: "note",
    name: "Note",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (note) =>
      note ? (
        <span
          css={css`
            width: 200px;
            text-overflow: ellipsis;
            overflow: hidden !important;
            white-space: nowrap;
            display: block;
          `}
        >
          {note}
        </span>
      ) : (
        ""
      ),
  },
  {
    field: "createdAt",
    name: "Creato il",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) => <span>{moment(date).format("DD-MM-YYYY HH:mm:SS")}</span>,
  },
  {
    field: "updatedAt",
    name: "Modificato il",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) => <span>{moment(date).format("DD-MM-YYYY HH:mm:SS")}</span>,
  },
];

function LampadeTable({ tableQuery }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setMessage, currentComuneId, setLoading } = useGlobalProvider();
  const [tableState, tableDispatcher] = useReducer(
    tableReducer,
    tableInitialState
  );
  const { mutateAsync: removeLampadaAsync } = useRemoveApiCall(
    apiClient.lampada.remove,
    `${LAMPADE_KEY}-${currentComuneId}`,
    true,
    queryKeyMappingUpdated(currentComuneId)
  );
  const { lampade, isFetching, isLoading } = useLampade(
    currentComuneId,
    {
      page: tableState.currentPage,
      pageSize: tableState.pageSize,
      query: tableQuery,
      sort: tableState.sort,
      populate: ["titolare", "loculo", "cimitero"],
      useSpecialQuery: tableState.useSpecialQuery,
    },
    false
  );

  const handleEditAction = (item) => {
    navigate(`/lampade/${item._id}/modifica`);
  };

  const handleRemoveAction = async (lampade) => {
    let title;
    if (lampade.length > 1) {
      title = `Le ${lampade.length} lampade sono state eliminate con successo`;
    } else {
      title = `La lampada è stata eliminata con successo`;
    }
    try {
      const promises = lampade.map((lampada) =>
        removeLampadaAsync([currentComuneId, lampada._id])
      );
      await Promise.all(promises);
      setMessage({
        title: title,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
    } catch (err) {
      handleApiError(
        err,
        setMessage,
        "Errore durante l'eliminazione delle lampade"
      );
    }
  };

  const handleDownload = async () => {
    setLoading(true);
    await downloadFile(
      () =>
        apiClient.lampada.exportCsv(currentComuneId, {
          query: tableQuery,
          sort: tableState.sort,
        }),
      "lampade.csv",
      setMessage,

      "Errore durante la preparazione del file"
    );
    setLoading(false);
  };

  if (!lampade) {
    return null;
  }

  return (
    <Table
      entities={lampade}
      entityName={"Lampade"}
      entitiesName={"Lampade"}
      isLoading={isLoading || isFetching}
      tableColumns={columns(user)}
      tableState={tableState}
      tableDispatcher={tableDispatcher}
      canEdit={true}
      canRemove={isAnyAdmin(user)}
      handleEditAction={handleEditAction}
      handleRemoveAction={handleRemoveAction}
      showDownloadButton={true}
      handleDownload={handleDownload}
      defaultVisibleColumns={defaultVisibleColumns}
      pageSizeOptions={[60, 30, 15]}
      tableLayout="auto"
      sortSpecialFields={{
        titolare: ["titolare.cognome", "titolare.nome"],
        cimitero: "cimitero.nome",
      }}
      sortSpecialFieldsMapping={{
        "titolare.cognome": "titolare",
        "titolare.nome": "titolare",
        "cimitero.nome": "cimitero",
      }}
    />
  );
}

export default LampadeTable;
