import moment from "moment/moment";
import { useReducer, useState } from "react";
import { formReducer, initFormState } from "../../forms/formUtils/reducer";
import Toolbar from "../Toolbar";
import { countriesCodeLabel } from "../../../lib/data/countries";
import { useDefunti } from "../../../lib/hooks/api/defunti";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import validator from "validator/es";

const searchFields = {
  groups: [
    {
      name: "Dati Anagrafici",
      fields: [
        {
          field: "_id",
          label: "Id",
          type: "select",
          isSingleSelect: false,
          entitySearch: "id",
        },
        { field: "epiteto", label: "Epitero", type: "string" },
        { field: "nome", label: "Nome", type: "string" },
        { field: "cognome", label: "Cognome", type: "string" },
        { field: "madre", label: "Madre", type: "string" },
        { field: "padre", label: "Padre", type: "string" },
        { field: "codiceFiscale", label: "Codice Fiscale", type: "string" },
        {
          field: "dataNascita",
          label: "Data Nascita",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(1000, "y"),
            maxDate: moment(),
          },
        },
        { field: "luogoNascita", label: "Luogo Nascita", type: "string" },
        {
          field: "statoNascita",
          label: "Stato Nascita",
          type: "select",
          options: countriesCodeLabel,
          isSingleSelect: false,
        },
        {
          field: "dataMorte",
          label: "Data Morte",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(1000, "y"),
            maxDate: moment(),
          },
        },
        { field: "luogoMorte", label: "Luogo Morte", type: "string" },
        {
          field: "statoMorte",
          label: "Stato Morte",
          type: "select",
          options: countriesCodeLabel,
          isSingleSelect: false,
        },
        {
          field: "titolare",
          label: "Utente",
          type: "select",
          isSingleSelect: false,
          entitySearch: "titolare",
        },
        {
          field: "loculo",
          label: "Loculo",
          type: "select",
          isSingleSelect: false,
          entitySearch: "loculo",
        },
        /* {
          field: "loculo.cimitero",
          label: "Cimitero",
          type: "select",
          isSingleSelect: false,
          entitySearch: "cimitero",
        },*/
      ],
    },
    {
      name: "Dati Tumulazione/Sepoltura/Cremazione",
      fields: [
        { field: "agenzia", label: "Agenzia", type: "string" },
        {
          field: "dataCerimonia",
          label: "Data Cerimonia",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(100, "y"),
            maxDate: moment(),
          },
        },
        { field: "luogoCerimonia", label: "Luogo Cerimonia", type: "string" },
        {
          field: "dataSepoltura",
          label: "Data Sepoltura",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(100, "y"),
            maxDate: moment(),
          },
        },
        {
          field: "dataCremazione",
          label: "Data Cremazione",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(100, "y"),
            maxDate: moment(),
          },
        },
        {
          field: "dataEsumazione",
          label: "Data Esumazione",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(100, "y"),
            maxDate: moment(),
          },
        },
        { field: "note", label: "Note", type: "string" },
        {
          field: "createdAt",
          label: "Creato il",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(10, "y"),
            maxDate: moment(),
          },
        },
        {
          field: "updatedAt",
          label: "Modificato il",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(10, "y"),
            maxDate: moment(),
          },
        },
      ],
    },
  ],
};

const DEFAULT_TYPES_VALUE = {
  string: "",
  select: [],
  dateRange: {},
};

const searchDefaultState = {};

searchFields.groups.forEach((group) =>
  group.fields.forEach((field) => {
    searchDefaultState[field.field] = {
      value: DEFAULT_TYPES_VALUE[field.type],
      label: field.label,
      name: field.field,
      is_required: false,
    };
  })
);

function DefuntiToolbar({ tableQuery, setTableQuery }) {
  const { currentComuneId } = useGlobalProvider();
  const [searchFormData, searchFormDataDispatcher] = useReducer(
    formReducer(searchDefaultState),
    initFormState({
      objData: null,
      isEdit: false,
      defaultState: searchDefaultState,
    })
  );
  const [searchIdQuery, setSearchIdQuery] = useState({});

  const { defunti, isFetching, isLoading } = useDefunti(
    currentComuneId,
    {
      page: 0,
      pageSize: 20,
      query: searchIdQuery,
      sort: {},
    },
    false
  );

  const handleIdSearchChange = (searchValue) => {
    setSearchIdQuery(
      validator.isMongoId(searchValue)
        ? {
            _id: searchValue,
          }
        : {}
    );
  };

  const handleSubmit = (processedQuery) => {
    setTableQuery(processedQuery);
  };

  const handleReset = () => {
    setTableQuery({});
  };

  return (
    <Toolbar
      fieldsColumns={4}
      searchFields={searchFields}
      entitiesName={"Defunti"}
      searchFormData={searchFormData}
      searchFormDataDispatcher={searchFormDataDispatcher}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      isIdLoading={isLoading || isFetching}
      handleIdSearchChange={handleIdSearchChange}
      iDOptions={defunti.data.map((defunto) => ({
        code: defunto._id,
        label: defunto._id,
      }))}
    />
  );
}

export default DefuntiToolbar;
