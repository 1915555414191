/** @jsxImportSource @emotion/react */
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { getDocumentazioneFilename } from "../../../lib/apiClient/loculo";
import {
  EuiButtonIcon,
  EuiImage,
  EuiTextColor,
  EuiToolTip,
} from "@elastic/eui";
import apiClient, {
  downloadFile,
  handleApiError,
} from "../../../lib/apiClient";
import useAuth from "../../../lib/providers/authProvider";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useReducer } from "react";
import { tableReducer } from "../reducer";
import {
  queryKeyMappingUpdated,
  useRemoveApiCall,
} from "../../../lib/hooks/api";
import { isAnyAdmin } from "../../../lib/auth";
import Table from "../Table";
import { css } from "@emotion/react";
import { LOCULI_KEY, useLoculi } from "../../../lib/hooks/api/loculi";
import { getStaticServerFilePath } from "../../../lib/utils";
import { CimiteroTableLink, DataScadenza } from "../specialFields";

const tableInitialState = {
  currentPage: 0,
  pageSize: 15,
  sort: { createdAt: -1 },
  useSpecialQuery: false,
};

const defaultVisibleColumns = [
  { field: "_id", visible: true },
  { field: "cimitero", visible: true },
  { field: "tipologia", visible: true },
  { field: "latitudine", visible: false },
  { field: "longitudine", visible: false },
  { field: "area", visible: true },
  { field: "settore", visible: true },
  { field: "lotto", visible: false },
  { field: "fila", visible: false },
  { field: "piano", visible: false },
  { field: "padiglione", visible: false },
  { field: "zona", visible: false },
  { field: "posto", visible: false },
  { field: "localita", visible: false },
  { field: "blocco", visible: false },
  { field: "defunti", visible: true },
  { field: "lampada", visible: true },
  { field: "contratto.titolare", visible: true },
  { field: "contratto.dataConcessione", visible: false },
  { field: "contratto.dataDelibera", visible: false },
  { field: "contratto.dataContratto", visible: false },
  { field: "contratto.dataScadenza", visible: true },
  { field: "contratto.protocollo", visible: false },
  { field: "contratto.repertorio", visible: false },
  { field: "contratto.registro", visible: false },
  { field: "contratto.pagina", visible: false },
  { field: "contratto.documentazione", visible: false },
  { field: "tour_link", visible: true },
  { field: "foto", visible: false },
  { field: "qrCode", visible: false },
  { field: "note", visible: false },
  { field: "createdAt", visible: false },
  { field: "updatedAt", visible: false },
];

const columns = (user) => [
  {
    field: "_id",
    name: "Id",
    "data-test-subj": "codeCell",
    sortable: true,
    truncateText: true,
    enlarge: 1,
    align: "center",
  },
  {
    field: "cimitero",
    name: "Cimitero",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (cimitero) => <CimiteroTableLink user={user} cimitero={cimitero} />,
  },
  {
    field: "tipologia",
    name: "Tipologia",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "latitudine",
    name: "Latitudine",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "number",
  },
  {
    field: "longitudine",
    name: "Longitudine",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "number",
  },
  {
    field: "area",
    name: "Area",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "settore",
    name: "Settore",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "lotto",
    name: "Lotto",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "fila",
    name: "Fila",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "piano",
    name: "Piano",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "padiglione",
    name: "Padiglione",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "zona",
    name: "Zona",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "posto",
    name: "Posto",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "localita",
    name: "Località",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "blocco",
    name: "Blocco",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "defunti",
    name: "Defunti",
    truncateText: true,
    sortable: true,
    align: "center",
    css: css`
      flex-direction: column;
    `,
    render: (defunti) =>
      defunti.length > 0
        ? defunti.map((defunto, index) => (
            <div key={index}>
              <EuiToolTip position="top" content={"Vai al defunto"}>
                <Link to={`/defunti/${defunto._id}/modifica`}>
                  {defunto.cognome} {defunto.nome}
                </Link>
              </EuiToolTip>
            </div>
          ))
        : "",
  },
  {
    field: "lampada",
    name: "Lampada",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (lampada) =>
      lampada ? (
        <EuiToolTip position="top" content={"Vai alla lampada"}>
          <Link to={`/lampade/${lampada._id}/modifica`}>{lampada._id}</Link>
        </EuiToolTip>
      ) : (
        ""
      ),
  },
  // Contratto columns
  {
    field: "contratto.titolare",
    name: "Utente",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (titolare) =>
      titolare ? (
        <EuiToolTip position={"top"} content={"Vai al titolare"}>
          <Link to={`/utenti/${titolare._id}/modifica`}>
            {titolare.cognome} {titolare.nome}
          </Link>
        </EuiToolTip>
      ) : (
        ""
      ),
  },
  {
    field: "contratto.dataConcessione",
    name: "Data Concessione",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) =>
      date ? <span>{moment(date).format("DD-MM-YYYY")}</span> : "",
  },
  {
    field: "contratto.dataDelibera",
    name: "Data Delibera",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) =>
      date ? <span>{moment(date).format("DD-MM-YYYY")}</span> : "",
  },
  {
    field: "contratto.dataContratto",
    name: "Data Contratto",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) =>
      date ? <span>{moment(date).format("DD-MM-YYYY")}</span> : "",
  },
  {
    field: "contratto.dataScadenza",
    name: "Data Scadenza",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date, loculo) => (
      <DataScadenza
        date={date}
        titolare={loculo?.contratto?.titolare}
        entityName={"Loculo"}
      />
    ),
  },
  {
    field: "contratto.protocollo",
    name: "Protocollo",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "contratto.repertorio",
    name: "Repertorio",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "contratto.registro",
    name: "Registro",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "contratto.pagina",
    name: "Pagina",
    truncateText: true,
    sortable: true,
    align: "center",
  },
  {
    field: "contratto.documentazione",
    name: "Fascicolo",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (documentazione, loculo) => {
      if (documentazione) {
        return (
          <EuiToolTip
            position="top"
            content={"Scarica documentazione contratto"}
          >
            <EuiButtonIcon
              iconType="download"
              aria-label="download documentazione"
              onClick={() =>
                downloadFile(
                  () =>
                    apiClient.loculo.downloadDocumentazione(
                      loculo.comune,
                      loculo._id
                    ),
                  getDocumentazioneFilename(loculo),
                  null,
                  ""
                )
              }
            />
          </EuiToolTip>
        );
      } else {
        return "";
      }
    },
  },
  {
    field: "tour_link",
    name: "Tour Link",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (tourLink) =>
      tourLink ? (
        <EuiToolTip position={"top"} content={"Vai a tour virtuale"}>
          <EuiButtonIcon
            iconType="visMapRegion"
            aria-label="tour-link"
            href={tourLink}
            target={"_blank"}
          />
        </EuiToolTip>
      ) : (
        ""
      ),
  },
  {
    field: "foto",
    name: "Foto",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (foto) =>
      foto ? (
        <EuiImage
          alt="Foto loculo"
          size={40}
          src={getStaticServerFilePath(foto)}
        />
      ) : (
        ""
      ),
  },
  {
    field: "note",
    name: "Note",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (note) => (
      <span
        css={css`
          width: 200px;
          text-overflow: ellipsis;
          overflow: hidden !important;
          white-space: nowrap;
          display: block;
        `}
      >
        {note}
      </span>
    ),
  },
  {
    field: "qrCode",
    name: "QrCode",
    truncateText: true,
    sortable: true,
    align: "center",
    render: (qrCode) =>
      qrCode ? (
        <EuiImage
          alt="QrCode loculo"
          size={40}
          src={getStaticServerFilePath(qrCode)}
        />
      ) : (
        ""
      ),
  },
  {
    field: "createdAt",
    name: "Creato il",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) => <span>{moment(date).format("DD-MM-YYYY HH:mm:SS")}</span>,
  },
  {
    field: "updatedAt",
    name: "Modificato il",
    truncateText: true,
    sortable: true,
    align: "center",
    dataType: "date",
    render: (date) => <span>{moment(date).format("DD-MM-YYYY HH:mm:SS")}</span>,
  },
];

function LoculiTable({ tableQuery }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setMessage, currentComuneId, setLoading } = useGlobalProvider();
  const [tableState, tableDispatcher] = useReducer(
    tableReducer,
    tableInitialState
  );
  const { mutateAsync: removeLoculoAsync } = useRemoveApiCall(
    apiClient.loculo.remove,
    `${LOCULI_KEY}-${currentComuneId}`,
    true,
    queryKeyMappingUpdated(currentComuneId)
  );
  const { loculi, isFetching, isLoading } = useLoculi(
    currentComuneId,
    {
      page: tableState.currentPage,
      pageSize: tableState.pageSize,
      query: tableQuery,
      sort: tableState.sort,
      populate: ["contratto.titolare", "lampada", "cimitero", "defunti"],
      useSpecialQuery: tableState.useSpecialQuery,
    },
    false
  );

  const handleEditAction = (item) => {
    navigate(`/loculi/${item._id}/modifica`);
  };

  const handleRemoveAction = async (loculi) => {
    let title;
    if (loculi.length > 1) {
      title = `I ${loculi.length} loculi sono stati eliminati con successo`;
    } else {
      title = `Il loculo è stato eliminato con successo`;
    }
    try {
      const promises = loculi.map((loculo) =>
        removeLoculoAsync([currentComuneId, loculo._id])
      );
      await Promise.all(promises);
      setMessage({
        title: title,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
    } catch (err) {
      handleApiError(
        err,
        setMessage,
        "Errore durante l'eliminazione dei loculi"
      );
    }
  };

  const handleDownload = async () => {
    setLoading(true);
    await downloadFile(
      () =>
        apiClient.loculo.exportCsv(currentComuneId, {
          query: tableQuery,
          sort: tableState.sort,
        }),
      "loculi.csv",
      setMessage,

      "Errore durante la preparazione del file"
    );
    setLoading(false);
  };

  if (!loculi) {
    return null;
  }

  return (
    <Table
      entities={loculi}
      entityName={"Loculo"}
      entitiesName={"Loculi"}
      isLoading={isLoading || isFetching}
      tableColumns={columns(user)}
      tableState={tableState}
      tableDispatcher={tableDispatcher}
      canEdit={true}
      canRemove={isAnyAdmin(user)}
      handleEditAction={handleEditAction}
      handleRemoveAction={handleRemoveAction}
      showDownloadButton={true}
      handleDownload={handleDownload}
      defaultVisibleColumns={defaultVisibleColumns}
      pageSizeOptions={[60, 30, 15]}
      tableLayout="auto"
      sortSpecialFields={{
        "contratto.titolare": [
          "contratto.titolare.cognome",
          "contratto.titolare.nome",
        ],
        defunti: ["defunti.cognome", "defunti.nome"],
        cimitero: "cimitero.nome",
      }}
      sortSpecialFieldsMapping={{
        "contratto.titolare.cognome": "contratto.titolare",
        "contratto.titolare.nome": "contratto.titolare",
        "defunti.cognome": "defunti",
        "defunti.nome": "defunti",
        "cimitero.nome": "cimitero",
      }}
    />
  );
}

export default LoculiTable;
