import { useEffect, useState } from "react";
import useApp from "../../../lib/providers/appProvider";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import WarningBanner from "../../../components/WarningBanner";
import { useNavigate } from "react-router-dom";
import { UTENTI_KEY } from "../../../lib/hooks/api/utenti";
import { useQueryClient } from "@tanstack/react-query";
import { EuiButton, EuiHorizontalRule } from "@elastic/eui";
import apiClient, { handleApiError } from "../../../lib/apiClient";
import useNavigation from "../../../lib/providers/navigationProvider";
import ImportCsvModal from "../../../components/ImportCsvModal";
import UtentiTable from "../../../components/table/pageTables/Utenti.table";
import UtentiToolbar from "../../../components/searchToolbar/pageToolbars/Utenti.toolbar";
import TableError from "../../../components/errors/TableError";
import ErrorBoundaryInternal from "../../../lib/ErrorService/ErrorBoundaryInternal";

function UtentiPage() {
  const navigate = useNavigate();
  const { setPageHeaderActions } = useNavigation();
  const { setManualPageTitle } = useApp();
  const { currentComuneId, globalWarning, setLoading, setMessage, loading } =
    useGlobalProvider();
  const queryClient = useQueryClient();
  const [tableQuery, setTableQuery] = useState({});
  const [showImportCsvModal, setShowImportCsvModal] = useState(false);

  useEffect(() => {
    setManualPageTitle("Utenti");
  }, []);

  const newButton = (
    <EuiButton size="s" fill onClick={() => navigate("/utenti/nuovo")}>
      Crea nuovo
    </EuiButton>
  );

  const importCsvButton = (
    <EuiButton size="s" onClick={() => setShowImportCsvModal(true)}>
      Importa CSV
    </EuiButton>
  );

  const handleCsvImportSuccess = async (csvFile, delimiter = ",") => {
    try {
      setLoading(true);
      const { data } = await apiClient.utente.importCsv(
        currentComuneId,
        csvFile,
        { delimiter }
      );
      queryClient.invalidateQueries({
        queryKey: `${UTENTI_KEY}-${currentComuneId}-list`,
      });
      setMessage({
        title: `Utenti importati con successo`,
        iconType: "document",
        color: "success",
        text: `${data.entityCreated} utenti sono stati importati`,
        timeout: 8000,
      });
    } catch (err) {
      handleApiError(err, setMessage, "Errore durante l'import degli utenti");
    } finally {
      setLoading(false);
      setShowImportCsvModal(false);
    }
  };

  useEffect(() => {
    if (globalWarning) {
      setPageHeaderActions([]);
    } else {
      setPageHeaderActions([newButton, importCsvButton]);
    }
    return () => setPageHeaderActions([]);
  }, [globalWarning]);

  if (globalWarning) {
    return (
      <WarningBanner
        title={globalWarning.title}
        body={globalWarning.body}
        actions={globalWarning.actions}
      />
    );
  }

  return (
    <>
      <UtentiToolbar tableQuery={tableQuery} setTableQuery={setTableQuery} />
      <EuiHorizontalRule margin="l" />
      <ErrorBoundaryInternal fallback={TableError}>
        <UtentiTable tableQuery={tableQuery} />
      </ErrorBoundaryInternal>
      <ImportCsvModal
        entitiesName={"Utenti"}
        showModal={showImportCsvModal}
        setShowModal={setShowImportCsvModal}
        handleSuccess={handleCsvImportSuccess}
      />
    </>
  );
}

export default UtentiPage;
