/** @jsxImportSource @emotion/react */
import { EuiCallOut, EuiSpacer, useEuiTheme, EuiIcon } from "@elastic/eui";
import useGlobalProvider from "../lib/providers/globalProvider";
import React from "react";
import { css } from "@emotion/react";

function AppMessage() {
  const { euiTheme } = useEuiTheme();
  const { message, setMessage } = useGlobalProvider();

  if (message === null) {
    return null;
  }

  const title = (
    <span>
      {message.title}
      <EuiIcon
        css={css`
          position: absolute;
          top: 20px;
          right: ${euiTheme.base}px;
          cursor: pointer;
        `}
        aria-label="Chiudi messaggio"
        type="cross"
        color={message.color}
        onClick={() => setMessage(null)}
      />
    </span>
  );

  return (
    <>
      <EuiCallOut
        css={css`
          position: relative;
        `}
        title={title}
        color={message.color}
        iconType={message.iconType}
      >
        {message.text ? message.text : null}
      </EuiCallOut>
      <EuiSpacer size="l" />
    </>
  );
}

export default AppMessage;
