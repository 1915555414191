/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer,
  EuiHorizontalRule,
} from "@elastic/eui";

function FormActions({
  handleSubmit,
  handleReset,
  showRequiredText,
  showReset,
  submitLabel = "Salva",
  resetLabel = "Azzera",
  addSpacer = true,
  spacerSize = "xl",
  addHorizontalRule = true,
  horizontalRuleMargin = "s",
  buttonSize = "s",
  formId = null,
}) {
  return (
    <>
      {addSpacer ? <EuiSpacer size={spacerSize} /> : null}
      {addHorizontalRule ? (
        <EuiHorizontalRule margin={horizontalRuleMargin} />
      ) : null}
      <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
        {showRequiredText ? (
          <EuiFlexItem
            css={css`
              text-align: left;
            `}
          >
            <EuiText grow={false} size="s">
              <p>* Campi obbligatori.</p>
            </EuiText>
          </EuiFlexItem>
        ) : null}
        <EuiFlexItem>
          <div
            css={css`
              text-align: right;
            `}
          >
            {showReset ? (
              <EuiButton
                size={buttonSize}
                css={css`
                  margin-right: 8px;
                `}
                type="reset"
                onClick={handleReset}
              >
                {resetLabel}
              </EuiButton>
            ) : null}
            <EuiButton
              type="submit"
              fill
              onClick={handleSubmit}
              size={buttonSize}
              form={formId}
            >
              {submitLabel}
            </EuiButton>
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export default FormActions;
