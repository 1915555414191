import { Link, useNavigate } from "react-router-dom";
import { EuiButtonIcon, EuiToolTip } from "@elastic/eui";
import moment from "moment/moment";
import useAuth from "../../../lib/providers/authProvider";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { tableReducer } from "../reducer";
import { useRemoveApiCall } from "../../../lib/hooks/api";
import apiClient, { handleApiError } from "../../../lib/apiClient";
import { useMemo, useReducer, useState } from "react";
import { USERS_KEY, useUsers } from "../../../lib/hooks/api/users";
import { isAdmin, isAnyAdmin, isSuperAdmin } from "../../../lib/auth";
import Table from "../Table";
import ResetPasswordModal from "../../ResetPasswordModal";

const tableInitialState = {
  currentPage: 0,
  pageSize: 15,
  sort: { createdAt: -1 },
  useSpecialQuery: false,
};

function buildColumnsAndVisibleColumns(
  superAdmin,
  userId,
  handleResetPasswordClick,
  adminUser
) {
  const defaultVisibleColumns = [{ field: "_id", visible: true }];

  const columns = [
    {
      field: "_id",
      name: "Id",
      "data-test-subj": "codeCell",
      sortable: true,
      truncateText: true,
      enlarge: 1,
      align: "center",
    },
  ];

  if (superAdmin) {
    defaultVisibleColumns.push({ field: "comune", visible: true });
    columns.push({
      field: "comune",
      name: "Comune",
      truncateText: true,
      sortable: true,
      align: "center",
      render: (comune) =>
        comune ? (
          <EuiToolTip position="top" content={"Vai al comune"}>
            <Link to={`/comuni/${comune._id}/modifica`}>{comune.nome}</Link>
          </EuiToolTip>
        ) : (
          ""
        ),
    });
  }

  return {
    defaultVisibleColumns: [
      ...defaultVisibleColumns,
      ...[
        { field: "username", visible: true },
        { field: "first_name", visible: true },
        { field: "last_name", visible: true },
        { field: "roles", visible: true },
        { field: "password", visible: true },
        { field: "createdAt", visible: true },
        { field: "updatedAt", visible: true },
      ],
    ],
    columns: [
      ...columns,
      ...[
        {
          field: "username",
          name: "Username",
          truncateText: true,
          sortable: true,
          align: "center",
        },
        {
          field: "first_name",
          name: "Nome",
          truncateText: true,
          sortable: true,
          align: "center",
        },
        {
          field: "last_name",
          name: "Cognome",
          truncateText: true,
          sortable: true,
          align: "center",
        },
        {
          field: "roles",
          name: "Ruolo",
          truncateText: true,
          sortable: true,
          align: "center",
          render: (roles) => roles.map((role) => role),
        },
        {
          field: "password",
          name: "Reset password",
          truncateText: true,
          sortable: true,
          align: "center",
          render: (password, userData) => {
            if (
              userData.roles.includes("standard") ||
              ((superAdmin || adminUser) && userData._id !== userId)
            ) {
              return (
                <EuiToolTip position="top" content={"Reset password gestore"}>
                  <EuiButtonIcon
                    iconType={"tokenKey"}
                    aria-label={"reset-password"}
                    onClick={() => handleResetPasswordClick(userData)}
                  />
                </EuiToolTip>
              );
            } else {
              return null;
            }
          },
        },
        {
          field: "createdAt",
          name: "Creato il",
          truncateText: true,
          sortable: true,
          align: "center",
          dataType: "date",
          render: (date) => (
            <span>{moment(date).format("DD-MM-YYYY HH:mm:SS")}</span>
          ),
        },
        {
          field: "updatedAt",
          name: "Modificato il",
          truncateText: true,
          sortable: true,
          align: "center",
          dataType: "date",
          render: (date) => (
            <span>{moment(date).format("DD-MM-YYYY HH:mm:SS")}</span>
          ),
        },
      ],
    ],
  };
}

function UsersTable({ tableQuery }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setMessage, currentComuneId } = useGlobalProvider();
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [currentResetPasswordUser, setCurrentResetPasswordUser] =
    useState(null);
  const [tableState, tableDispatcher] = useReducer(
    tableReducer,
    tableInitialState
  );

  const handleResetPasswordClick = (userData) => {
    setShowResetPasswordModal(true);
    setCurrentResetPasswordUser(userData);
  };

  const { defaultVisibleColumns, columns } = useMemo(
    () =>
      buildColumnsAndVisibleColumns(
        isSuperAdmin(user),
        user._id,
        handleResetPasswordClick,
        isAdmin(user, true)
      ),
    []
  );

  const { mutateAsync: removeUsersAsync } = useRemoveApiCall(
    apiClient.users.remove,
    `${USERS_KEY}`,
    true
  );
  const { users, isFetching, isLoading } = useUsers(
    {
      page: tableState.currentPage,
      pageSize: tableState.pageSize,
      query: tableQuery,
      sort: tableState.sort,
      populate: ["comune"],
      useSpecialQuery: tableState.useSpecialQuery,
    },
    false
  );

  const handleEditAction = (item) => {
    navigate(`/users/${item._id}/modifica`);
  };

  const handleRemoveAction = async (users) => {
    let title;
    if (users.length > 1) {
      title = `I ${users.length} gestori sono stati eliminati con successo`;
    } else {
      title = `Il gestore è stato eliminato con successo`;
    }
    try {
      const promises = users.map((entity) => removeUsersAsync([entity._id]));
      await Promise.all(promises);
      setMessage({
        title: title,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
    } catch (err) {
      handleApiError(
        err,
        setMessage,
        "Errore durante l'eliminazione dei gestori"
      );
    }
  };

  const canRemoveRowSpecific = (userData) => {
    return userData._id !== user._id && isAnyAdmin(user);
  };

  const canSelectRowSpecific = (userData) => {
    return userData._id !== user._id && isAnyAdmin(user);
  };

  if (!users) {
    return null;
  }

  return (
    <>
      <Table
        entities={users}
        entityName={"Gestore"}
        entitiesName={"Gestori"}
        isLoading={isLoading || isFetching}
        tableColumns={columns}
        tableState={tableState}
        tableDispatcher={tableDispatcher}
        canEdit={true}
        canRemove={isAnyAdmin(user)}
        canRemoveRowSpecific={canRemoveRowSpecific}
        canSelectRowSpecific={canSelectRowSpecific}
        handleEditAction={handleEditAction}
        handleRemoveAction={handleRemoveAction}
        showDownloadButton={false}
        defaultVisibleColumns={defaultVisibleColumns}
        pageSizeOptions={[60, 30, 15]}
        tableLayout="auto"
        sortSpecialFields={{
          comune: "comune.nome",
        }}
        sortSpecialFieldsMapping={{
          "comune.nome": "comune",
        }}
      />
      <ResetPasswordModal
        showModal={showResetPasswordModal}
        setShowModal={setShowResetPasswordModal}
        userData={currentResetPasswordUser}
      />
    </>
  );
}

export default UsersTable;
