import { useParams } from "react-router-dom";
import useApp from "../../../lib/providers/appProvider";
import { useEffect } from "react";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useUtente } from "../../../lib/hooks/api/utenti";
import UtenteForm from "../../../components/forms/pageForms/Utente.form";

function UtentePage() {
  const params = useParams();
  const { setManualPageTitle } = useApp();
  const { currentComuneId, setMessage } = useGlobalProvider();

  const utenteId = params?.id || null;
  const isEdit = !!utenteId;

  const {
    utente: utenteData,
    isLoading,
    isFetching,
  } = useUtente(currentComuneId, utenteId, true);

  useEffect(() => {
    setManualPageTitle(utenteId ? `Modifica utente` : "Crea utente");
  }, []);

  if (isLoading || (isEdit && !utenteData)) {
    return null;
  }

  return (
    <UtenteForm
      isEdit={isEdit}
      utenteData={utenteData}
      setMessage={setMessage}
    />
  );
}

export default UtentePage;
