import { Routes, Route, Outlet, useLocation, Navigate } from "react-router-dom";
import PageLayout from "../layout/PageLayout";
import LoginPage from "./Login.page";
import CimiteriPage from "./navbar/Cimitero/Cimiteri.page";
import LogoutPage from "./Logout.page";
import NotFoundPage from "./NotFound.page";
import ProfilePage from "./Profile.page";
import useAuth from "../lib/providers/authProvider";
import FullPageLoader from "../components/FullPageLoader";
import { useEffect, useState } from "react";
import tokenStorage from "../lib/tokenStorage";
import { getMainNavbarPages, getPageFromUrl } from "../lib/data/pages";
import { userCanVisitPage } from "../lib/auth";
import ForbiddenPage from "./Forbidden.page";
import CimiteroPage from "./navbar/Cimitero/Cimitero.page";

function PrivateRoute({ user, children }) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const token = tokenStorage.get();

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  if (loading && token) {
    return <FullPageLoader visible={loading} />;
  }

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  const canVisit = userCanVisitPage(user, getPageFromUrl(location.pathname));
  if (!canVisit) {
    return <Navigate to="/forbidden" />;
  }
  return children ? children : <Outlet />;
}

function AppRouter() {
  const { user } = useAuth();
  const navBarRoutes = getMainNavbarPages().map((page, index) =>
    page.subComponent ? (
      <Route key={index} path={page.code}>
        <Route index element={page.component} />
        <Route path=":id/modifica" element={page.subComponent} />
        <Route path="nuovo" element={page.subComponent} />
      </Route>
    ) : (
      <Route key={index} path={page.code} element={page.component} />
    )
  );
  return (
    <Routes>
      <Route element={<PageLayout />}>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<PrivateRoute user={user} />}>
          <Route index element={<CimiteriPage />} />
          {navBarRoutes}
          <Route path="cimitero">
            <Route path=":id/modifica" element={<CimiteroPage />} />
            <Route path="nuovo" element={<CimiteroPage />} />
          </Route>
          <Route path="profile" element={<ProfilePage />} />
          <Route path="logout" element={<LogoutPage />} />
          <Route path="forbidden" element={<ForbiddenPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRouter;
