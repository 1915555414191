import { useState } from "react";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useCimiteriTipologiaLampade } from "../../../lib/hooks/api/cimiteri";
import SelectField from "../FormFields/SelectField";

function CimiteroTipologiaLampade({
  field,
  handleOnChange,
  isSingleSelect,
  isClearable = true,
  compressed = false,
  fullWidth = false,
  append = null,
  helpText = null,
  showPlaceholder = true,
}) {
  const { currentComuneId } = useGlobalProvider();
  const [searchQuery, setSearchQuery] = useState({});
  const { tipologieLampada, isFetching, isLoading } =
    useCimiteriTipologiaLampade(currentComuneId, searchQuery, false);

  return (
    <SelectField
      field={field}
      isSingleSelect={isSingleSelect}
      handleOnChange={handleOnChange}
      options={tipologieLampada.map((tipologia) => ({
        code: tipologia,
        label: tipologia,
      }))}
      isClearable={isClearable}
      compressed={compressed}
      fullWidth={fullWidth}
      isLoading={isFetching || isLoading}
      append={append}
      helpText={helpText}
      showPlaceholder={showPlaceholder}
    />
  );
}

export default CimiteroTipologiaLampade;
