import { EuiFilePicker, EuiFormRow } from "@elastic/eui";
import Label from "./Label";

function InputFileField({
  field,
  handleOnChange,
  fieldRef,
  accept,
  helpText,
  compressed = false,
  fullWidth = false,
  append = null,
}) {
  return (
    <EuiFormRow
      label={<Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      helpText={helpText}
      isDisabled={field.disabled}
      fullWidth={fullWidth}
      display={compressed ? "rowCompressed" : "row"}
    >
      <EuiFilePicker
        compressed={compressed}
        display={compressed ? "default" : "large"}
        fullWidth={fullWidth}
        initialPromptText={field.label}
        accept={accept}
        ref={fieldRef}
        onChange={(e) => handleOnChange(e)}
        aria-label={field.label.toString()}
        name={field.name}
        isInvalid={field.isInvalid}
        append={append}
      />
    </EuiFormRow>
  );
}

export default InputFileField;
