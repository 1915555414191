import moment from "moment";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { formReducer, initFormState } from "../../forms/formUtils/reducer";
import validator from "validator/es";
import Toolbar from "../Toolbar";
import { useLampade } from "../../../lib/hooks/api/lampade";
import { useReducer, useState } from "react";

export const statoFornituraOptions = [
  { code: "Non Concesso", label: "Non Concesso" },
  { code: "Concesso", label: "Concesso" },
  { code: "In Lavorazione", label: "In Lavorazione" },
];

const searchFields = {
  groups: [
    {
      name: "",
      fields: [
        {
          field: "_id",
          label: "Id",
          type: "select",
          isSingleSelect: false,
          entitySearch: "id",
        },
        {
          field: "tipologia",
          label: "Tipologia",
          type: "select",
          isSingleSelect: false,
          entitySearch: "tipologiaLampade",
        },
        { field: "numeroContratto", label: "Numero Contratto", type: "string" },
        {
          field: "statoFornitura",
          label: "Stato Fornitura",
          type: "select",
          options: statoFornituraOptions,
          isSingleSelect: false,
        },
        {
          field: "dataAssegnazione",
          label: "Data Assegnazione",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(100, "y"),
            maxDate: moment(),
          },
        },
        {
          field: "dataDelibera",
          label: "Data Delibera",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(100, "y"),
            maxDate: moment(),
          },
        },
        {
          field: "dataScadenza",
          label: "Data Scadenza",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(100, "y"),
            maxDate: moment().add(100, "y"),
          },
        },
        {
          field: "cimitero",
          label: "Cimitero",
          type: "select",
          isSingleSelect: false,
          entitySearch: "cimitero",
        },
        {
          field: "titolare",
          label: "Utente",
          type: "select",
          isSingleSelect: false,
          entitySearch: "titolare",
        },
        {
          field: "loculo",
          label: "Loculo",
          type: "select",
          isSingleSelect: false,
          entitySearch: "loculo",
        },
        { field: "note", label: "Note", type: "string" },
        {
          field: "createdAt",
          label: "Creato il",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(10, "y"),
            maxDate: moment(),
          },
        },
        {
          field: "updatedAt",
          label: "Modificato il",
          type: "dateRange",
          props: {
            showTimeSelect: false,
            minDate: moment().subtract(10, "y"),
            maxDate: moment(),
          },
        },
      ],
    },
  ],
};

const DEFAULT_TYPES_VALUE = {
  string: "",
  select: [],
  dateRange: {},
  int: "",
  float: "",
};

const searchDefaultState = {};

searchFields.groups.forEach((group) =>
  group.fields.forEach((field) => {
    searchDefaultState[field.field] = {
      value: DEFAULT_TYPES_VALUE[field.type],
      label: field.label,
      name: field.field,
      is_required: false,
    };
  })
);

function LampadeToolbar({ tableQuery, setTableQuery }) {
  const { currentComuneId } = useGlobalProvider();
  const [searchFormData, searchFormDataDispatcher] = useReducer(
    formReducer(searchDefaultState),
    initFormState({
      objData: null,
      isEdit: false,
      defaultState: searchDefaultState,
    })
  );
  const [searchIdQuery, setSearchIdQuery] = useState({});

  const { lampade, isFetching, isLoading } = useLampade(
    currentComuneId,
    {
      page: 0,
      pageSize: 20,
      query: searchIdQuery,
      sort: {},
    },
    false
  );

  const handleIdSearchChange = (searchValue) => {
    setSearchIdQuery(
      validator.isMongoId(searchValue)
        ? {
            _id: searchValue,
          }
        : {}
    );
  };

  const handleSubmit = (processedQuery) => {
    setTableQuery(processedQuery);
  };

  const handleReset = () => {
    setTableQuery({});
  };

  return (
    <Toolbar
      fieldsColumns={4}
      searchFields={searchFields}
      entitiesName={"Lampade"}
      searchFormData={searchFormData}
      searchFormDataDispatcher={searchFormDataDispatcher}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      isIdLoading={isLoading || isFetching}
      handleIdSearchChange={handleIdSearchChange}
      iDOptions={lampade.data.map((lampada) => ({
        code: lampada._id,
        label: lampada._id,
      }))}
    />
  );
}

export default LampadeToolbar;
