import useGlobalProvider from "../../../lib/providers/globalProvider";
import TextField from "../../forms/FormFields/TextField";
import { handleFieldChange } from "../../forms/formUtils/reducer";
import NumberField from "../../forms/FormFields/NumberField";
import SelectField from "../../forms/FormFields/SelectField";
import DateRangeField from "../../forms/FormFields/DateRangeField";
import EntitySearchField from "./EntitySearchField";

function ComponentFromField({
  field,
  inputField,
  props,
  fullWidth,
  formDataDispatcher,
  handleSearchChange,
  isLoading,
  options,
  showPlaceholder = true,
}) {
  const { setMessage } = useGlobalProvider();
  switch (field.type) {
    case "string":
      return (
        <TextField
          field={inputField}
          fullWidth={fullWidth}
          showPlaceholder={showPlaceholder}
          handleOnChange={(e) =>
            handleFieldChange(
              field.field,
              e.target.value,
              formDataDispatcher,
              setMessage
            )
          }
        />
      );
    case "int":
      return (
        <NumberField
          field={inputField}
          fullWidth={fullWidth}
          showPlaceholder={showPlaceholder}
          handleOnChange={(e) =>
            handleFieldChange(
              field.field,
              e.target.value,
              formDataDispatcher,
              setMessage
            )
          }
        />
      );
    case "float":
      return (
        <NumberField
          field={inputField}
          fullWidth={fullWidth}
          showPlaceholder={showPlaceholder}
          handleOnChange={(e) =>
            handleFieldChange(
              field.field,
              e.target.value,
              formDataDispatcher,
              setMessage
            )
          }
        />
      );
    case "select":
      if (field.entitySearch) {
        return (
          <EntitySearchField
            field={field}
            fullWidth={fullWidth}
            inputField={inputField}
            formDataDispatcher={formDataDispatcher}
            setMessage={setMessage}
            handleSearchChange={handleSearchChange}
            isLoading={isLoading}
            options={options}
            showPlaceholder={showPlaceholder}
          />
        );
      } else {
        return (
          <SelectField
            field={inputField}
            fullWidth={fullWidth}
            options={field.options}
            showPlaceholder={showPlaceholder}
            isSingleSelect={field.isSingleSelect}
            handleOnChange={(values) =>
              handleFieldChange(
                field.field,
                values,
                formDataDispatcher,
                setMessage
              )
            }
          />
        );
      }
    case "dateRange":
      return (
        <DateRangeField
          field={inputField}
          fullWidth={fullWidth}
          handleChange={(val) =>
            handleFieldChange(field.field, val, formDataDispatcher, setMessage)
          }
          {...props}
        />
      );
    default:
      return null;
  }
}

export default ComponentFromField;
