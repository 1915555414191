import { useNavigate, useParams } from "react-router-dom";
import useApp from "../../../lib/providers/appProvider";
import { useEffect } from "react";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useDefunto } from "../../../lib/hooks/api/defunti";
import DefuntiForm from "../../../components/forms/pageForms/Defunti.form";

function UtentePage() {
  const params = useParams();
  const navigate = useNavigate();
  const { setManualPageTitle } = useApp();
  const { currentComuneId } = useGlobalProvider();

  const defuntoId = params?.id || null;
  const isEdit = !!defuntoId;

  const {
    defunto: defuntoData,
    isLoading,
    isFetching,
  } = useDefunto(currentComuneId, defuntoId, true, ["titolare", "loculo"]);

  useEffect(() => {
    setManualPageTitle(defuntoId ? `Modifica defunto` : "Crea defunto");
  }, []);

  if (isLoading || (isEdit && !defuntoData)) {
    return null;
  }

  return (
    <DefuntiForm
      isEdit={isEdit}
      defuntoData={defuntoData}
      afterNew={() => navigate("/defunti")}
      afterEdit={() => navigate("/defunti")}
    />
  );
}

export default UtentePage;
