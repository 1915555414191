import useGlobalProvider from "../../../../lib/providers/globalProvider";
import useUpdateEffect from "../../../../lib/hooks/customHooks";
import { handleFieldChange } from "../../formUtils/reducer";
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFormRow } from "@elastic/eui";
import LampadaSearchField from "../../entitiesSearchFields/Lampada.search-field";
import DefuntoSearchField from "../../entitiesSearchFields/Defunto.search-field";

function LoculoLampadaAndDefuntiFormView({
  loculoData,
  formData,
  formDataDispatcher,
  onSelectedTabChanged,
  isEdit,
}) {
  const { setMessage } = useGlobalProvider();

  useUpdateEffect(() => {
    let reset = true;
    if (isEdit) {
      if (formData.cimitero.value.length > 0) {
        if (formData.cimitero.value[0].code === loculoData?.cimitero?._id) {
          reset = false;
        }
      }
    }
    if (reset) {
      handleFieldChange("lampada", [], formDataDispatcher, setMessage);
    }
  }, [formData.cimitero.value]);

  return (
    <EuiFormRow fullWidth>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem>
          <LampadaSearchField
            field={formData.lampada}
            fullWidth={true}
            isSingleSelect={true}
            handleOnChange={(values) =>
              handleFieldChange(
                "lampada",
                values,
                formDataDispatcher,
                setMessage
              )
            }
            additionalQuery={
              formData.cimitero.value.length > 0
                ? {
                    cimitero: formData.cimitero.value[0].code,
                    loculo: null,
                  }
                : { loculo: null }
            }
            helpText="Seleziona la lampada associata al loculo, oppure creane una nuova utilizzando l'apposita tab."
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <DefuntoSearchField
            field={formData.defunti}
            fullWidth={true}
            isSingleSelect={false}
            handleOnChange={(values) =>
              handleFieldChange(
                "defunti",
                values,
                formDataDispatcher,
                setMessage
              )
            }
            append={
              <EuiButton
                onClick={() => onSelectedTabChanged("defunti")}
                color="text"
              >
                Crea nuovo
              </EuiButton>
            }
            helpText="Seleziona i defunti associati al loculo, oppure creane di nuovi utilizzando l'apposita tab."
            additionalQuery={{ loculo: null }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFormRow>
  );
}

export default LoculoLampadaAndDefuntiFormView;
