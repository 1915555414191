import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useNavigate } from "react-router-dom";
import {
  formReducer,
  handleFieldChange,
  handleFormReset,
  initFormState,
  setFormDataErrors,
} from "../formUtils/reducer";
import { useReducer } from "react";
import { validateFormData } from "../../../lib/fieldsValidator";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPanel,
} from "@elastic/eui";
import TextField from "../FormFields/TextField";
import FormActions from "../formUtils/FormActions";
import { useCreateApiCall, useUpdateApiCall } from "../../../lib/hooks/api";
import apiClient, { handleApiError } from "../../../lib/apiClient";
import { COMUNI_KEY } from "../../../lib/hooks/api/comune";

const formDefaultState = {
  nome: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Nome",
    name: "nome",
    is_required: true,
    validators: [],
    transforms: ["trim"],
  },
  iban: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "IBAN",
    name: "iban",
    is_required: true,
    validators: ["isIBAN"],
    transforms: ["trim", "upperCase"],
  },
  intestatario: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Intestatario",
    name: "intestatario",
    is_required: true,
    validators: [],
    transforms: ["trim"],
  },
};

function ComuneForm({ isEdit, comuneData }) {
  const { setMessage } = useGlobalProvider();
  const navigate = useNavigate();
  const [formData, formDataDispatcher] = useReducer(
    formReducer(formDefaultState),
    initFormState({
      objData: comuneData,
      isEdit: isEdit,
      defaultState: formDefaultState,
    })
  );
  const { mutateAsync: createComune } = useCreateApiCall(
    apiClient.comune.create,
    COMUNI_KEY,
    true
  );
  const { mutateAsync: updateComune } = useUpdateApiCall(
    apiClient.comune.update,
    COMUNI_KEY,
    true
  );

  const handleReset = () => {
    handleFormReset(comuneData, isEdit, formDataDispatcher);
    setMessage(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors, dataDto } = validateFormData(
      formData,
      isEdit,
      comuneData
    );
    if (!isValid) {
      setFormDataErrors(errors, formDataDispatcher);
      setMessage({
        title: "Errore! Alcuni campi non sono validi",
        color: "danger",
        iconType: "alert",
        text: "Correggi i valori inseriti e riprova",
      });
    } else {
      if (isEdit) {
        handleEditSubmit(dataDto);
      } else {
        handleNewSubmit(dataDto);
      }
    }
  };

  const handleNewSubmit = async (dataDto) => {
    try {
      await createComune([dataDto]);
      setMessage({
        title: `Comune ${dataDto.nome} creato con successo`,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
      navigate("/comuni");
    } catch (err) {
      handleApiError(err, setMessage, "Errore durante la creazione del comune");
    }
  };

  const handleEditSubmit = async (dataDto) => {
    try {
      await updateComune([comuneData._id, dataDto]);
      setMessage({
        title: `Comune ${dataDto.nome} modificato con successo`,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
      navigate("/comuni");
    } catch (err) {
      handleApiError(err, setMessage, "Errore durante la modifica del comune");
    }
  };

  return (
    <EuiPanel>
      <EuiForm component="form">
        <TextField
          field={formData.nome}
          fullWidth={true}
          handleOnChange={(e) =>
            handleFieldChange(
              "nome",
              e.target.value,
              formDataDispatcher,
              setMessage
            )
          }
        />
        <EuiFormRow fullWidth>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem>
              <TextField
                field={formData.iban}
                fullWidth={true}
                handleOnChange={(e) =>
                  handleFieldChange(
                    "iban",
                    e.target.value,
                    formDataDispatcher,
                    setMessage
                  )
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <TextField
                field={formData.intestatario}
                fullWidth={true}
                handleOnChange={(e) =>
                  handleFieldChange(
                    "intestatario",
                    e.target.value,
                    formDataDispatcher,
                    setMessage
                  )
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        <FormActions
          handleSubmit={(e) => handleSubmit(e)}
          handleReset={handleReset}
          showReset={true}
          showRequiredText={true}
        />
      </EuiForm>
    </EuiPanel>
  );
}

export default ComuneForm;
