import {
  EuiPanel,
  EuiPopover,
  EuiContextMenu,
  useGeneratedHtmlId,
  EuiButtonIcon,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiHorizontalRule,
} from "@elastic/eui";
import { useState } from "react";
import useAuth from "../../lib/providers/authProvider";
import { isAnyAdmin, isSuperAdmin } from "../../lib/auth";
import CimiteroImage from "./CimiteroImage";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import { queryKeyMappingUpdated, useRemoveApiCall } from "../../lib/hooks/api";
import apiClient, { handleApiError } from "../../lib/apiClient";
import { CIMITERI_KEY } from "../../lib/hooks/api/cimiteri";
import useGlobalProvider from "../../lib/providers/globalProvider";
import { useNavigate } from "react-router-dom";

function CimiteroCard({ cimiteroData, comuneId }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setMessage, currentComuneId } = useGlobalProvider();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { mutateAsync: removeCimiteroMutateAsync } = useRemoveApiCall(
    apiClient.cimitero.remove,
    `${CIMITERI_KEY}-${comuneId}`,
    true,
    queryKeyMappingUpdated(currentComuneId)
  );
  const menuPopOverId = useGeneratedHtmlId({
    prefix: `menuPopOver-${cimiteroData._id}`,
  });

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleEditClick = () => {
    closeMenu();
    navigate(`/cimitero/${cimiteroData._id}/modifica`);
    //setCimiteroFormStatus({ isOpen: true, isEdit: true, cimiteroData });
  };

  const handleDeleteClick = () => {
    closeMenu();
    setShowConfirmModal(true);
  };

  const handleRemoveAction = async () => {
    try {
      await removeCimiteroMutateAsync([comuneId, cimiteroData._id]);
      setMessage({
        title: `Cimitero ${cimiteroData.nome} eliminato con successo`,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
    } catch (err) {
      handleApiError(
        err,
        setMessage,
        "Errore durante l'eliminazione del cimitero " + cimiteroData.nome
      );
    }
  };

  const panels = [
    {
      id: 0,
      title: "Gestisci Cimitero",
      items: [],
    },
  ];
  if (isAnyAdmin(user)) {
    panels[0].items.push({
      name: "Modifica cimitero",
      icon: "documentEdit",
      onClick: handleEditClick,
    });
    panels[0].items.push({
      name: "Elimina cimitero",
      icon: <EuiIcon type="trash" size="m" color="danger" />,
      onClick: handleDeleteClick,
    });
  }

  const menuButton = (
    <EuiButtonIcon
      iconType="boxesVertical"
      color="text"
      aria-label="Cimitero card menu"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    />
  );

  return (
    <>
      <EuiPanel
        hasBorder={true}
        hasShadow={true}
        color={"plain"}
        paddingSize="l"
      >
        <EuiFlexGroup
          alignItems="center"
          justifyContent="spaceBetween"
          responsive={false}
        >
          <EuiFlexItem grow={true}>
            <EuiText>
              <h3>{cimiteroData.nome}</h3>
            </EuiText>
          </EuiFlexItem>
          {isSuperAdmin(user) ? (
            <EuiFlexItem grow={false}>
              <EuiPopover
                id={menuPopOverId}
                button={menuButton}
                isOpen={isMenuOpen}
                closePopover={closeMenu}
                panelPaddingSize="none"
                anchorPosition="leftUp"
              >
                <EuiContextMenu initialPanelId={0} panels={panels} />
              </EuiPopover>
            </EuiFlexItem>
          ) : null}
        </EuiFlexGroup>
        <EuiHorizontalRule margin="m" />
        <CimiteroImage cimiteroData={cimiteroData} />
      </EuiPanel>
      {isSuperAdmin(user) ? (
        <ConfirmDeleteModal
          entities={[cimiteroData]}
          entityName={"Cimitero"}
          entitiesName={"Cimiteri"}
          showConfirmModal={showConfirmModal}
          setShowConfirmModal={setShowConfirmModal}
          handleSuccess={handleRemoveAction}
          customTitle={`Vuoi davvere eliminare il cimitero ${cimiteroData.nome}`}
          customBody={`Procedendo eliminerai il cimitero ${cimiteroData.nome} insieme alle sue lampade e i suoi loculi.`}
        />
      ) : null}
    </>
  );
}

export default CimiteroCard;
