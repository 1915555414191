import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useReducer, useRef, useState } from "react";
import {
  formReducer,
  handleFieldChange,
  handleFormReset,
  initFormState,
  setFormDataErrors,
} from "../formUtils/reducer";
import { useCreateApiCall, useUpdateApiCall } from "../../../lib/hooks/api";
import apiClient, { handleApiError } from "../../../lib/apiClient";
import { validateFormData } from "../../../lib/fieldsValidator";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiPanel,
} from "@elastic/eui";
import TextField from "../FormFields/TextField";
import FormActions from "../formUtils/FormActions";
import SelectField from "../FormFields/SelectField";
import DateField from "../FormFields/DateField";
import moment from "moment";
import { statoFornituraOptions } from "../../searchToolbar/pageToolbars/Lampade.toolbar";
import { LAMPADE_KEY } from "../../../lib/hooks/api/lampade";
import NumberField from "../FormFields/NumberField";
import InputWithControlFileField from "../FormFields/InputWithControlFileField";
import { getDocumentazioneFilename } from "../../../lib/apiClient/lampada";
import TextAreaField from "../FormFields/TextAreaField";
import CimiteroSearchField from "../entitiesSearchFields/Cimitero.search-field";
import { useCimiteriTipologiaLampade } from "../../../lib/hooks/api/cimiteri";
import LoculoSearchField from "../entitiesSearchFields/Loculo.search-field";
import TitolareSearchField from "../entitiesSearchFields/Titolare.search-field";
import UtenteModalForm from "../modalForms/UtenteModal.form";
import { useQueryClient } from "@tanstack/react-query";
import { LOCULI_KEY } from "../../../lib/hooks/api/loculi";
import useUpdateEffect from "../../../lib/hooks/customHooks";

const formDefaultState = {
  tipologia: {
    value: [],
    isInvalid: false,
    errors: [],
    label: "Tipologia",
    name: "tipologia",
    is_required: true,
    validators: [],
    transforms: [],
  },
  numeroContratto: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Numero Contratto",
    name: "numeroContratto",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
  statoFornitura: {
    value: [statoFornituraOptions[0]],
    isInvalid: false,
    errors: [],
    label: "Stato Fornitura",
    name: "statoFornitura",
    is_required: false,
    validators: [],
    transforms: [],
  },
  dataAssegnazione: {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Data Assegnazione",
    name: "dataAssegnazione",
    is_required: false,
    validators: [],
    transforms: [],
  },
  dataDelibera: {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Data Delibera",
    name: "dataDelibera",
    is_required: false,
    validators: [],
    transforms: [],
  },
  dataScadenza: {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Data Scadenza",
    name: "dataScadenza",
    is_required: false,
    validators: [],
    transforms: [],
  },
  cimitero: {
    value: [],
    isInvalid: false,
    errors: [],
    label: "Cimitero",
    name: "cimitero",
    is_required: true,
    validators: [],
    transforms: [],
  },
  titolare: {
    value: [],
    isInvalid: false,
    errors: [],
    label: "Utente",
    name: "titolare",
    is_required: false,
    validators: [],
    transforms: [],
  },
  loculo: {
    value: [],
    isInvalid: false,
    errors: [],
    label: "Loculo",
    name: "loculo",
    is_required: false,
    validators: [],
    transforms: [],
  },
  documentazione: {
    value: null,
    isInvalid: false,
    errors: [],
    label: "Fascicolo",
    name: "documentazione",
    is_required: false,
    validators: [],
    transforms: [],
  },
  note: {
    value: "",
    isInvalid: false,
    errors: [],
    label: "Note",
    name: "note",
    is_required: false,
    validators: [],
    transforms: ["trim"],
  },
};

function dataToFormData(lampadaData) {
  if (lampadaData) {
    return {
      ...lampadaData,
      tipologia: [
        { code: lampadaData.tipologia, label: lampadaData.tipologia },
      ],
      statoFornitura: [
        { code: lampadaData.statoFornitura, label: lampadaData.statoFornitura },
      ],
      cimitero: lampadaData.cimitero
        ? [
            {
              code: lampadaData.cimitero._id,
              label: `${lampadaData.cimitero.nome}`,
            },
          ]
        : [],
      titolare: lampadaData.titolare
        ? [
            {
              code: lampadaData.titolare._id,
              label: `${lampadaData.titolare.cognome} ${lampadaData.titolare.nome} - ${lampadaData.titolare.codiceFiscale}`,
            },
          ]
        : [],
      loculo: lampadaData.loculo
        ? [{ code: lampadaData.loculo._id, label: lampadaData.loculo._id }]
        : [],
    };
  } else {
    return lampadaData;
  }
}

function formDataToData(formData) {
  return {
    ...formData,
    tipologia: {
      ...formData.tipologia,
      value:
        formData.tipologia.value.length > 0
          ? formData.tipologia.value[0].code
          : "",
    },
    statoFornitura: {
      ...formData.statoFornitura,
      value:
        formData.statoFornitura.value.length > 0
          ? formData.statoFornitura.value[0].code
          : "",
    },
    cimitero: {
      ...formData.cimitero,
      value:
        formData.cimitero.value.length > 0
          ? formData.cimitero.value[0].code
          : "",
    },
    titolare: {
      ...formData.titolare,
      value:
        formData.titolare.value.length > 0
          ? formData.titolare.value[0].code
          : "",
    },
    loculo: {
      ...formData.loculo,
      value:
        formData.loculo.value.length > 0 ? formData.loculo.value[0].code : "",
    },
  };
}

function LampadaForm({
  isEdit,
  lampadaData,
  afterNew,
  afterEdit,
  wireLoculo = true,
}) {
  const { currentComuneId, setMessage, setLoading } = useGlobalProvider();
  const [formData, formDataDispatcher] = useReducer(
    formReducer(formDefaultState),
    initFormState({
      objData: dataToFormData(lampadaData),
      isEdit: isEdit,
      defaultState: formDefaultState,
    })
  );
  const [showTitolareModal, setShowTitolareModal] = useState(false);
  const [showDocumentazioneViewer, setShowDocumentazioneViewer] = useState(
    isEdit && lampadaData.documentazione
  );
  const documentazioneRef = useRef(null);
  const queryClient = useQueryClient();
  const { mutateAsync: createLampada } = useCreateApiCall(
    apiClient.lampada.create,
    `${LAMPADE_KEY}-${currentComuneId}`,
    false
  );
  const { mutateAsync: updateLampada } = useUpdateApiCall(
    apiClient.lampada.update,
    `${LAMPADE_KEY}-${currentComuneId}`,
    false
  );
  const { mutateAsync: updateLampadaLoculoMutate } = useUpdateApiCall(
    apiClient.lampada.updateLoculo,
    `${LAMPADE_KEY}-${currentComuneId}`,
    false
  );
  const {
    tipologieLampada,
    isLoading: isTipologiaLampadaLoading,
    isFetching: isTipologiaLampadaFetching,
  } = useCimiteriTipologiaLampade(
    currentComuneId,
    formData.cimitero.value.length > 0
      ? { _id: formData.cimitero.value[0].code }
      : {},
    false
  );

  const handleReset = () => {
    handleFormReset(dataToFormData(lampadaData), isEdit, formDataDispatcher);
    if (documentazioneRef.current) {
      documentazioneRef.current.removeFiles();
    }
    setShowDocumentazioneViewer(isEdit && lampadaData.documentazione);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { isValid, errors, dataDto } = validateFormData(
      formDataToData(formData),
      isEdit,
      lampadaData
    );
    if (!isValid) {
      setFormDataErrors(errors, formDataDispatcher);
      setMessage({
        title: "Errore! Alcuni campi non sono validi",
        color: "danger",
        iconType: "alert",
        text: "Correggi i valori inseriti e riprova",
      });
    } else {
      if (dataDto.loculo === "") {
        delete dataDto.loculo;
      }
      setLoading(true);
      let lampada;
      if (isEdit) {
        lampada = await handleEditSubmit(dataDto);
      } else {
        lampada = await handleNewSubmit(dataDto);
      }
      try {
        if (wireLoculo) {
          await updateLampadaLoculo(lampada, dataDto);
        }
      } catch (err) {
        handleApiError(
          err,
          setMessage,
          "Errore durante la propagazione della lampada sul loculo"
        );
      } finally {
        if (isEdit) {
          afterEdit(lampada);
        } else {
          afterNew(lampada);
        }
        setLoading(false);
      }
    }
  };

  const handleNewSubmit = async (dataDto) => {
    try {
      const { data: lampada } = await createLampada([currentComuneId, dataDto]);
      setMessage({
        title: `Lampada creata con successo`,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
      return lampada;
    } catch (err) {
      handleApiError(
        err,
        setMessage,
        "Errore durante la creazione della lampada"
      );
    }
  };

  const handleEditSubmit = async (dataDto) => {
    try {
      const { data: lampada } = await updateLampada([
        currentComuneId,
        lampadaData._id,
        dataDto,
        { populate: ["titolare", "loculo", "cimitero"] },
      ]);
      setMessage({
        title: `Lampada modificata con successo`,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
      return lampada;
    } catch (err) {
      handleApiError(
        err,
        setMessage,
        "Errore durante la modifica della lampada"
      );
    }
  };

  const updateLampadaLoculo = async (lampada, dataDto) => {
    const payload = {};
    let doUpdate = false;
    // check if it had not the loculo, and now it has it or if it is a new lampada with loculo set
    if (
      (!lampadaData?.loculo && lampada.loculo) ||
      (!isEdit && lampada.loculo)
    ) {
      payload["unset"] = false;
      payload["loculo"] = dataDto.loculo;
      doUpdate = true;
    }
    // lampada had loculo and now we removed it
    if (isEdit && lampadaData?.loculo && !dataDto.loculo) {
      payload["unset"] = true;
      payload["loculo"] = lampadaData.loculo._id;
      doUpdate = true;
    }
    // loculo changed
    if (
      isEdit &&
      lampadaData.loculo &&
      dataDto.loculo &&
      lampadaData?.loculo !== dataDto.loculo
    ) {
      payload["unset"] = false;
      payload["loculo"] = dataDto.loculo;
      doUpdate = true;
      await updateLampadaLoculoMutate([
        currentComuneId,
        lampada._id,
        { unset: true, loculo: lampadaData.loculo._id },
      ]);
    }
    if (doUpdate) {
      const { data: updatedData } = await updateLampadaLoculoMutate([
        currentComuneId,
        lampada._id,
        payload,
        { populate: ["titolare", "loculo", "cimitero"] },
      ]);
      const lampadaKey = `${LAMPADE_KEY}-${currentComuneId}`;
      const loculoKey = `${LOCULI_KEY}-${currentComuneId}`;
      queryClient.setQueryData(
        [`${lampadaKey}-item`, { id: lampada._id }],
        updatedData
      );
      queryClient.invalidateQueries({ queryKey: `${lampadaKey}-list` });
      queryClient.invalidateQueries({ queryKey: `${loculoKey}-list` });
      queryClient.removeQueries({
        queryKey: [
          `${loculoKey}-item`,
          {
            id: payload.loculo,
          },
        ],
      });
    }
  };

  const handleNewTitolareClick = () => {
    setShowTitolareModal(!showTitolareModal);
  };

  const handleTitolareModalSuccess = (utente) => {
    setShowTitolareModal(false);
    handleFieldChange(
      "titolare",
      [
        {
          code: utente._id,
          label: `${utente.cognome} ${utente.nome} - ${utente.codiceFiscale}`,
        },
      ],
      formDataDispatcher,
      setMessage
    );
    setLoading(false);
  };

  useUpdateEffect(() => {
    let reset = true;
    if (isEdit) {
      if (formData.cimitero.value.length > 0) {
        if (formData.cimitero.value[0].code === lampadaData?.cimitero?._id) {
          reset = false;
        }
      }
    }
    if (reset) {
      handleFieldChange("tipologia", [], formDataDispatcher, setMessage);
      handleFieldChange("loculo", [], formDataDispatcher, setMessage);
    }
  }, [formData.cimitero.value]);

  const form = (
    <EuiForm component="form">
      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <CimiteroSearchField
              field={formData.cimitero}
              fullWidth={true}
              isSingleSelect={true}
              handleOnChange={(values) =>
                handleFieldChange(
                  "cimitero",
                  values,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <SelectField
              field={formData.tipologia}
              fullWidth={true}
              options={tipologieLampada.map((tipologia) => ({
                code: tipologia,
                label: tipologia,
              }))}
              isSingleSelect={true}
              handleOnChange={(values) =>
                handleFieldChange(
                  "tipologia",
                  values,
                  formDataDispatcher,
                  setMessage
                )
              }
              isLoading={
                isTipologiaLampadaLoading || isTipologiaLampadaFetching
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField
              field={formData.numeroContratto}
              fullWidth={true}
              handleOnChange={(e) =>
                handleFieldChange(
                  "numeroContratto",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <SelectField
              field={formData.statoFornitura}
              fullWidth={true}
              options={statoFornituraOptions}
              isSingleSelect={true}
              handleOnChange={(values) =>
                handleFieldChange(
                  "statoFornitura",
                  values,
                  formDataDispatcher,
                  setMessage
                )
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <DateField
              field={formData.dataAssegnazione}
              fullWidth={true}
              handleOnChange={(val) =>
                handleFieldChange(
                  "dataAssegnazione",
                  val,
                  formDataDispatcher,
                  setMessage
                )
              }
              minDate={moment().subtract(100, "y")}
              maxDate={moment()}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <DateField
              field={formData.dataDelibera}
              fullWidth={true}
              handleOnChange={(val) =>
                handleFieldChange(
                  "dataDelibera",
                  val,
                  formDataDispatcher,
                  setMessage
                )
              }
              minDate={moment().subtract(100, "y")}
              maxDate={moment()}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <DateField
              field={formData.dataScadenza}
              fullWidth={true}
              handleOnChange={(val) =>
                handleFieldChange(
                  "dataScadenza",
                  val,
                  formDataDispatcher,
                  setMessage
                )
              }
              minDate={moment().subtract(100, "y")}
              maxDate={moment().add(100, "y")}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiHorizontalRule margin="l" size="half" />

      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          {wireLoculo ? (
            <EuiFlexItem>
              <LoculoSearchField
                field={formData.loculo}
                fullWidth={true}
                isSingleSelect={true}
                handleOnChange={(values) =>
                  handleFieldChange(
                    "loculo",
                    values,
                    formDataDispatcher,
                    setMessage
                  )
                }
                helpText={
                  "Il loculo può essere creato in un secondo momento e collegato alla lampada."
                }
                additionalQuery={
                  formData.cimitero.value.length > 0
                    ? {
                        cimitero: formData.cimitero.value[0].code,
                        lampada: null,
                      }
                    : { lampada: null }
                }
              />
            </EuiFlexItem>
          ) : null}
          <EuiFlexItem grow={true}>
            <TitolareSearchField
              field={formData.titolare}
              fullWidth={true}
              isSingleSelect={true}
              handleOnChange={(values) =>
                handleFieldChange(
                  "titolare",
                  values,
                  formDataDispatcher,
                  setMessage
                )
              }
              append={
                <EuiButton onClick={handleNewTitolareClick} color="text">
                  Crea nuovo
                </EuiButton>
              }
              helpText={
                "Seleziona l'utente della lampada, oppure creane uno nuovo."
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiHorizontalRule margin="l" size="half" />

      <EuiFormRow fullWidth>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <InputWithControlFileField
              field={formData.documentazione}
              fullWidth={true}
              handleOnChange={(file) =>
                handleFieldChange(
                  "documentazione",
                  file[0],
                  formDataDispatcher,
                  setMessage
                )
              }
              fieldRef={documentazioneRef}
              showPopulated={showDocumentazioneViewer}
              setShowPopulated={setShowDocumentazioneViewer}
              downloadApiCall={
                isEdit && lampadaData.documentazione
                  ? () =>
                      apiClient.lampada.downloadDocumentazione(
                        currentComuneId,
                        lampadaData._id
                      )
                  : () => {}
              }
              filename={
                isEdit && lampadaData.documentazione
                  ? getDocumentazioneFilename(lampadaData)
                  : ""
              }
              fileExistsLabel={"Gestisci fascicolo precedentemente caricato"}
              helpText={
                "Inserire in una cartella tutta la documentazione, comprimerla in file .zip e caricarla. Per aggiungere altri file, ripetere la procedura."
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextAreaField
              field={formData.note}
              fullWidth={true}
              rows={5}
              handleOnChange={(e) =>
                handleFieldChange(
                  "note",
                  e.target.value,
                  formDataDispatcher,
                  setMessage
                )
              }
              helpText={"Autorizzazioni, sopralluoghi, verbali, etc."}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <FormActions
        handleSubmit={(e) => handleSubmit(e)}
        handleReset={handleReset}
        showReset={true}
        showRequiredText={true}
      />
    </EuiForm>
  );

  return (
    <>
      <EuiPanel>{form}</EuiPanel>
      <UtenteModalForm
        entityName="Utente"
        isModalVisible={showTitolareModal}
        setIsModalVisible={setShowTitolareModal}
        afterSuccessAction={handleTitolareModalSuccess}
      />
    </>
  );
}

export default LampadaForm;
