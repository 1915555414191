import { useParams } from "react-router-dom";
import { useComune } from "../../../lib/hooks/api/comune";
import useApp from "../../../lib/providers/appProvider";
import { useEffect } from "react";
import ComuneForm from "../../../components/forms/pageForms/Comune.form";

function ComunePage() {
  const { setManualPageTitle } = useApp();
  const params = useParams();
  const comuneId = params?.id || null;
  const isEdit = !!comuneId;

  const {
    comune: comuneData,
    isLoading,
    isFetching,
  } = useComune(comuneId, true);

  useEffect(() => {
    setManualPageTitle(comuneId ? `Modifica comune` : "Crea comune");
  }, []);

  if (isLoading || (isEdit && !comuneData)) {
    return null;
  }

  return <ComuneForm comuneData={comuneData} isEdit={isEdit} />;
}

export default ComunePage;
