export const mappings = {
  it: {
    "euiFilePicker.removeSelected": "Rimuovi",
    "euiComboBoxOptionsList.loadingOptions": "Caricando le opzioni",
    "euiComboBoxOptionsList.delimiterMessage":
      "Aggiungi ogni elemento seperata da {delimiter}",
    "euiComboBoxOptionsList.alreadyAdded": "{label} è stato già aggiunto",
    "euiComboBoxOptionsList.createCustomOption":
      "Aggiungi {searchValue} come opzione custom",
    "euiComboBoxOptionsList.noMatchingOptions":
      "{searchValue} non corrisponde a nessuna opzione",
    "euiComboBoxOptionsList.noAvailableOptions":
      "Non ci sono opzioni disponibili",
    "euiComboBoxOptionsList.allOptionsSelected":
      "Hai selezionato tutte le opzioni disponibili",
  },
};
