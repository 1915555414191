import Table from "../Table";
import { COMUNI_KEY, useComuni } from "../../../lib/hooks/api/comune";
import { useReducer } from "react";
import { tableReducer } from "../reducer";
import moment from "moment";
import { isSuperAdmin } from "../../../lib/auth";
import useAuth from "../../../lib/providers/authProvider";
import { useNavigate } from "react-router-dom";
import apiClient, {
  downloadFile,
  handleApiError,
} from "../../../lib/apiClient";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import {
  queryKeyMappingUpdated,
  useRemoveApiCall,
} from "../../../lib/hooks/api";

const tableInitialState = {
  currentPage: 0,
  pageSize: 10,
  sort: { createdAt: -1 },
  useSpecialQuery: false,
};

const defaultVisibleColumns = [
  { field: "_id", visible: true },
  { field: "nome", visible: true },
  { field: "iban", visible: true },
  { field: "intestatario", visible: true },
  { field: "createdAt", visible: true },
  { field: "updatedAt", visible: false },
];

function ComuniTable({ tableQuery }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    setMessage,
    setLoading,
    currentComuneId,
    setCurrentComuneId,
    setResetComune,
  } = useGlobalProvider();
  const [tableState, tableDispatcher] = useReducer(
    tableReducer,
    tableInitialState
  );
  const { mutateAsync: removeComuneMutateAsync } = useRemoveApiCall(
    apiClient.comune.remove,
    COMUNI_KEY,
    true,
    queryKeyMappingUpdated()
  );
  const { comuni, isFetching, isLoading } = useComuni(
    {
      page: tableState.currentPage,
      pageSize: tableState.pageSize,
      query: tableQuery,
      sort: tableState.sort,
    },
    false
  );

  const columns = [
    {
      field: "_id",
      name: "Id",
      "data-test-subj": "codeCell",
      sortable: true,
      truncateText: false,
      enlarge: 1,
      align: "center",
    },
    {
      field: "nome",
      name: "Nome",
      truncateText: false,
      sortable: true,
      align: "center",
    },
    {
      field: "iban",
      name: "IBAN",
      truncateText: true,
      sortable: true,
      align: "center",
      render: (iban) => iban.toUpperCase(),
    },
    {
      field: "intestatario",
      name: "Intestatario",
      truncateText: true,
      sortable: true,
      align: "center",
    },
    {
      field: "createdAt",
      name: "Creato il",
      truncateText: false,
      sortable: true,
      align: "center",
      dataType: "date",
      render: (date) => (
        <span>{moment(date).format("DD-MM-YYYY HH:mm:SS")}</span>
      ),
    },
    {
      field: "updatedAt",
      name: "Modificato il",
      truncateText: false,
      sortable: true,
      align: "center",
      dataType: "date",
      render: (date) => (
        <span>{moment(date).format("DD-MM-YYYY HH:mm:SS")}</span>
      ),
    },
  ];

  const handleEditAction = (item) => {
    navigate(`/comuni/${item._id}/modifica`);
  };

  const handleRemoveAction = async (comuni) => {
    let title;
    if (comuni.length > 1) {
      title = `I ${comuni.length} comuni sono stati eliminati con successo`;
    } else {
      title = `Il comune è stato eliminato con successo`;
    }
    try {
      const promises = comuni.map((comune) =>
        removeComuneMutateAsync([comune._id])
      );
      await Promise.all(promises);
      const filtered = comuni.filter(
        (comune) => comune._id === currentComuneId
      );
      if (filtered.length > 0) {
        setCurrentComuneId(null);
        setResetComune(true);
      }
      setMessage({
        title: title,
        iconType: "document",
        color: "success",
        timeout: 8000,
      });
    } catch (err) {
      handleApiError(
        err,
        setMessage,
        "Errore durante l'eliminazione dei comuni"
      );
    }
  };

  const handleDownload = async () => {
    setLoading(true);
    await downloadFile(
      () =>
        apiClient.comune.exportCsv({
          query: tableQuery,
          sort: tableState.sort,
        }),
      "comuni.csv",
      setMessage,
      "Errore durante la preparazione del file"
    );
    setLoading(false);
  };

  if (!comuni) {
    return null;
  }

  return (
    <Table
      entities={comuni}
      entityName={"Comune"}
      entitiesName={"Comuni"}
      isLoading={isLoading || isFetching}
      tableColumns={columns}
      tableState={tableState}
      tableDispatcher={tableDispatcher}
      canEdit={isSuperAdmin(user)}
      canRemove={isSuperAdmin(user)}
      handleEditAction={handleEditAction}
      handleRemoveAction={handleRemoveAction}
      showDownloadButton={true}
      handleDownload={handleDownload}
      defaultVisibleColumns={defaultVisibleColumns}
      pageSizeOptions={[50, 25, 10]}
      tableLayout="auto"
      extraDeleteMessage={
        "Insieme a tutti i cimiteri, loculi, lampade, defunti, utenti e gestori associati."
      }
    />
  );
}

export default ComuniTable;
