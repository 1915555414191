import {
  isRequired,
  errorMessagesMapping,
  customTransforms,
  customValidators,
} from "./string";
import validator from "validator/es";

const validatorHasLocale = ["isPostalCode"];

export function validateField(fieldInfo, field) {
  let isValid = true;
  const errors = [];
  if (!isRequired(fieldInfo)) {
    isValid = false;
    errors.push(errorMessagesMapping.is_required(field));
  } else {
    if (fieldInfo.value !== "") {
      fieldInfo.validators.forEach((validatorFunc) => {
        let validateFn;
        if (Object.hasOwn(customValidators, validatorFunc)) {
          validateFn = customValidators[validatorFunc];
        } else {
          validateFn = validator[validatorFunc];
        }
        let isValidatorValid;
        if (validatorHasLocale.includes(validatorFunc)) {
          isValidatorValid = validateFn(fieldInfo.value, "any");
        } else {
          isValidatorValid = validateFn(fieldInfo.value);
        }

        if (!isValidatorValid) {
          isValid = false;
          errors.push(
            errorMessagesMapping[validatorFunc](field, fieldInfo.value)
          );
        }
      });
    }
  }
  return { isValid, errors };
}

export function transformField(fieldInfo, value) {
  let newValue = value;
  fieldInfo.transforms.forEach((transformFunc) => {
    if (Object.hasOwn(customTransforms, transformFunc)) {
      newValue = customTransforms[transformFunc](newValue);
    } else {
      newValue = validator[transformFunc](newValue);
    }
  });
  return newValue;
}

export function validateFormData(formData, isEdit = false, savedData = null) {
  let isValid = true;
  const errors = {};
  const dataDto = {};
  for (const [field, fieldInfo] of Object.entries(formData)) {
    const { isValid: fieldValid, errors: fieldErrors } = validateField(
      fieldInfo,
      field
    );
    if (!fieldValid) {
      isValid = false;
      errors[field] = fieldErrors;
    } else {
      const fieldValue = fieldInfo.value;
      switch (typeof fieldValue) {
        case "string":
          if (fieldValue.length > 0) {
            dataDto[field] = transformField(fieldInfo, fieldValue);
          } else {
            if (isEdit && savedData) {
              const savedValue = savedData[field];
              // this allows to remove (set empty) a not required value that was previously saved
              if (savedValue) {
                dataDto[field] = "";
              }
            }
          }
          break;
        case "object":
          if (fieldValue !== null) {
            dataDto[field] = transformField(fieldInfo, fieldValue);
          }
          break;
        case "undefined":
          break;
        default:
          dataDto[field] = transformField(fieldInfo, fieldValue);
      }
    }
  }
  return { isValid, errors, dataDto };
}
