import useApp from "../../../lib/providers/appProvider";
import React, { useEffect, useState } from "react";
import WarningBanner from "../../../components/WarningBanner";
import useGlobalProvider from "../../../lib/providers/globalProvider";
import { useNavigate } from "react-router-dom";
import useNavigation from "../../../lib/providers/navigationProvider";
import { EuiButton, EuiHorizontalRule, EuiSpacer, EuiText } from "@elastic/eui";
import LampadeTable from "../../../components/table/pageTables/Lampade.table";
import LampadeToolbar from "../../../components/searchToolbar/pageToolbars/Lampade.toolbar";
import { useCimiteri } from "../../../lib/hooks/api/cimiteri";
import { isSuperAdmin } from "../../../lib/auth";
import useAuth from "../../../lib/providers/authProvider";
import TableError from "../../../components/errors/TableError";
import ErrorBoundaryInternal from "../../../lib/ErrorService/ErrorBoundaryInternal";

function LampadePage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setPageHeaderActions } = useNavigation();
  const { setManualPageTitle } = useApp();
  const { currentComuneId, globalWarning } = useGlobalProvider();
  const [tableQuery, setTableQuery] = useState({});
  const [noCimiteriWarning, setNoCimiteriWarning] = useState(null);
  const {
    cimiteri,
    isLoading: isCimiteriLoading,
    isFetching: isCimiteriFetching,
  } = useCimiteri(
    currentComuneId,
    {
      query: {},
      sort: {},
      populate: [],
    },
    true
  );

  useEffect(() => {
    setManualPageTitle("Lampade");
  }, []);

  const newButton = (
    <EuiButton size="s" fill onClick={() => navigate("/lampade/nuovo")}>
      Crea nuovo
    </EuiButton>
  );

  useEffect(() => {
    if (globalWarning || noCimiteriWarning) {
      setPageHeaderActions([]);
    } else {
      setPageHeaderActions([newButton]);
    }
    return () => setPageHeaderActions([]);
  }, [globalWarning, noCimiteriWarning]);

  useEffect(() => {
    if (
      cimiteri.data.length === 0 &&
      !isCimiteriFetching &&
      !isCimiteriLoading
    ) {
      let body;
      let actions;
      if (isSuperAdmin(user)) {
        body = (
          <EuiText>
            <p>Prima di procedere è necessario creare un cimitero.</p>
          </EuiText>
        );
        actions = (
          <EuiButton
            color={"warning"}
            fill
            size="s"
            onClick={() => navigate("/cimitero/nuovo")}
          >
            Inserisci un cimitero
          </EuiButton>
        );
      } else {
        body = (
          <EuiText>
            <div>Prima di procedere è necessario creare un cimitero.</div>
            <EuiSpacer size="s" />
            <div>Chiedi all'admin del gestionale di inserirne</div>
          </EuiText>
        );
      }
      setNoCimiteriWarning({
        title: "Nessun cimitero presente",
        body,
        actions,
      });
    } else {
      setNoCimiteriWarning(null);
    }
  }, [cimiteri, isCimiteriFetching, isCimiteriLoading]);

  if (globalWarning) {
    return (
      <WarningBanner
        title={globalWarning.title}
        body={globalWarning.body}
        actions={globalWarning.actions}
      />
    );
  }

  if (noCimiteriWarning) {
    return (
      <WarningBanner
        title={noCimiteriWarning.title}
        body={noCimiteriWarning.body}
        actions={noCimiteriWarning.actions}
      />
    );
  }

  return (
    <>
      <LampadeToolbar tableQuery={tableQuery} setTableQuery={setTableQuery} />
      <EuiHorizontalRule margin="l" />
      <ErrorBoundaryInternal fallback={TableError}>
        <LampadeTable tableQuery={tableQuery} />
      </ErrorBoundaryInternal>
    </>
  );
}

export default LampadePage;
