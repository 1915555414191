/** @jsxImportSource @emotion/react */
import { EuiBasicTable, EuiSpacer } from "@elastic/eui";
import { useMemo, useState } from "react";
import {
  handleAllChange,
  tableSortToDbSort,
  dbSortToTableSort,
} from "../reducer";
import TableFooterActions from "./TableFooterActions";
import ConfirmDeleteModal from "../../ConfirmDeleteModal";
import TableToolbar from "./TableToolbar";
import { css } from "@emotion/react";

export const ACTIONS_LABEL = "";

const buildColumns = (
  tableColumns,
  canEdit,
  canRemove,
  handleEditAction,
  handleRemoveAction,
  canRemoveRowSpecific = null
) => {
  let cols = [...tableColumns];
  const actions = [];
  if (canEdit) {
    actions.push({
      name: "Modifica",
      description: "Modifica",
      type: "icon",
      icon: "documentEdit",
      isPrimary: true,
      onClick: (item) => handleEditAction(item),
      enabled: (item) => canEdit,
    });
  }
  if (canRemove) {
    actions.push({
      name: "Elimina",
      description: "Elimina",
      type: "icon",
      icon: "trash",
      color: "danger",
      onClick: (item) => handleRemoveAction(item),
      enabled: (item) => {
        if (canRemoveRowSpecific) {
          return canRemove && canRemoveRowSpecific(item);
        } else {
          return canRemove;
        }
      },
    });
  }
  if (actions.length > 0) {
    cols = [
      ...cols,
      {
        name: ACTIONS_LABEL,
        actions,
      },
    ];
  }
  return cols;
};

function Table({
  tableColumns,
  entities,
  entityName,
  entitiesName,
  isLoading,
  tableState,
  tableDispatcher,
  canEdit,
  canRemove,
  handleRemoveAction,
  handleEditAction,
  showDownloadButton,
  handleDownload,
  defaultVisibleColumns,
  canRemoveRowSpecific = null,
  canSelectRowSpecific = null,
  tableLayout = "auto",
  pageSizeOptions = [100, 50, 25],
  extraDeleteMessage = null,
  sortSpecialFields = {},
  sortSpecialFieldsMapping = {},
}) {
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [entitiesToRemove, setEntitiesToRemove] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [compressTable, setCompressedTable] = useState(false);
  const handleRemoveActionInternal = (item) => {
    setEntitiesToRemove([item]);
    setShowConfirmModal(true);
  };

  const handleRemoveSelectedClick = () => {
    setShowConfirmModal(true);
  };

  const columns = useMemo(
    () =>
      buildColumns(
        tableColumns,
        canEdit,
        canRemove,
        handleEditAction,
        handleRemoveActionInternal,
        canRemoveRowSpecific
      ),
    [tableColumns]
  );

  const [visibleColumns, setVisibleColumns] = useState(columns);

  const onSelectionChange = (selectedItems) => {
    setSelectedEntities(selectedItems);
    setEntitiesToRemove(selectedItems);
  };

  const onTableChange = ({ page = {}, sort = {} }) => {
    const { index: pageIndex, size: pageSize } = page;
    const { sortDb, useSpecialQuery } = tableSortToDbSort(
      sort,
      sortSpecialFields
    );
    handleAllChange(
      pageIndex,
      pageSize,
      sortDb,
      tableDispatcher,
      useSpecialQuery
    );
  };

  let selection = null;
  if (canRemove) {
    selection = {
      selectable: (entity) =>
        canSelectRowSpecific
          ? canSelectRowSpecific(entity)
          : canRemoveRowSpecific
          ? canRemoveRowSpecific(entity)
          : true,
      onSelectionChange,
    };
  }

  return (
    <>
      <TableToolbar
        totalEntities={entities.meta.totalItems}
        originalColumns={columns}
        setColumns={setVisibleColumns}
        entitiesName={entitiesName}
        selectedEntities={selectedEntities}
        showDownloadButton={showDownloadButton}
        handleDownload={handleDownload}
        defaultVisibleColumns={defaultVisibleColumns}
        compressTable={compressTable}
        setCompressedTable={setCompressedTable}
      />
      <EuiBasicTable
        css={css`
          div {
            overflow-x: auto;
          }
        `}
        tableCaption={`${entitiesName} tabella`}
        items={entities.data}
        loading={isLoading}
        itemId="_id"
        rowHeader="_id"
        columns={visibleColumns}
        isSelectable={true}
        selection={selection}
        pagination={{
          pageIndex: tableState.currentPage,
          pageSize: tableState.pageSize,
          totalItemCount: entities.meta.totalItems,
          pageSizeOptions: pageSizeOptions,
          showPerPageOptions: true,
        }}
        sorting={{
          sort: dbSortToTableSort(tableState.sort, sortSpecialFieldsMapping),
          enableAllColumns: false,
          readOnly: false,
        }}
        onChange={onTableChange}
        tableLayout={tableLayout}
        hasActions={true}
        responsive={true}
        compressed={compressTable}
        noItemsMessage={`Nessun ${entityName.toLowerCase()} trovato`}
      />
      <EuiSpacer size="l" />
      <TableFooterActions
        handleRemoveAction={handleRemoveSelectedClick}
        selectedEntities={selectedEntities}
      />
      <ConfirmDeleteModal
        entities={entitiesToRemove}
        entityName={entityName}
        entitiesName={entitiesName}
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
        handleSuccess={handleRemoveAction}
        extraMessage={extraDeleteMessage}
      />
    </>
  );
}

export default Table;
